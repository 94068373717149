import React from 'react' // eslint-disable-line no-unused-vars
import HeaderName from './HeaderName' // eslint-disable-line no-unused-vars
import MenuDrawer from './MenuDrawer' // eslint-disable-line no-unused-vars

export default class HeaderRow extends React.Component {
  render() {
    return (
      <div className="container">
        <div className="header-row">
          <HeaderName />
          <MenuDrawer />
        </div>
        <hr />
      </div>
    )
  }
}
