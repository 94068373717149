import React from 'react' // eslint-disable-line no-unused-vars
import Drawer from 'react-motion-drawer' // eslint-disable-line no-unused-vars
import ConfigButton from './ConfigButton' // eslint-disable-line no-unused-vars
import MenuRouter from './MenuRouter' // eslint-disable-line no-unused-vars

export default class MenuDrawer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      menuOpen: false,
    }
    this.toggleOpenMenu = this.toggleOpenMenu.bind(this)
  }

  toggleOpenMenu() {
    this.setState({ menuOpen: !this.state.menuOpen })
  }

  render() {
    return (
      <React.Fragment>
        <Drawer
          right
          fadeout
          open={this.state.menuOpen}
          drawerStyle={{ background: 'var(--background)' }}
          overlayColor={'rgba(0, 0, 0, 0.75)'}
          onChange={(open) => this.setState({ menuOpen: open })}
        >
          <div className="menu-column">
            <MenuRouter menuCallback={this.toggleOpenMenu} />
            <ConfigButton />
          </div>
        </Drawer>
        <button className="menu-button" onClick={this.toggleOpenMenu}>
          <i
            className="fas fa-bars"
            style={{
              fontSize: '56px',
              color: this.state.menuOpen
                ? 'var(--background)'
                : 'var(--button-color)',
              zIndex: '-1',
            }}
          />
        </button>
      </React.Fragment>
    )
  }
}
