// eslint-disable-next-line no-unused-vars
import React from 'react'
// eslint-disable-next-line no-unused-vars
import ReactMarkdown from 'react-markdown'
import { setTitle } from '../PageUtils'

const rebase = require('../../../../images/rebase.png')

/**
Git tips & tricks
*/
export default function GitTricks() {
  setTitle('Nathan Gilbert | Some handy git commands')

  const markdown = `
## Helpful Git Commands to Slice & Dice Commits

This [Conway's Game of
Life](https://github.com/nathan-gilbert/conways-game-of-life) repo is the basis
for the examples here, specifically, the \`activate-demo\` and \`main\`
branches.

### git cherry-pick

Say you have a bunch of commits in a branch that you want to break up into
multiple commits (for smaller Pull or Merge Requests). If you have confidence
that your commits are atomic, use \`git cherry-pick\` to copy them to a new
branch. Use \`git log\` to find the commit you want to copy in your current
working branch, grab its _SHA_. Checkout the main branch and create a new branch
off of it. Then change to the new branch and cherry pick and run \`git
cherry-pick <SHA-of-COMMIT>\`

_This is useful when you want to grab a single commit from one branch and place
it in another one._

### git rebase -i

But what if you have multiple commits you want to rearrange or move?

Rebase them. This is a very powerful tool. You can rewrite large chunks of
history in one go with this command. You can reorder commits. You can delete
commits.

When using this command, you specify the number of previous commits you'd like
to rebase with \`git rebase -i HEAD~5\` which would mean the previous 5 commits.
The image below demonstrates the look of the rebase menu with 5 commits. One
thing to note is that the commits are listed _oldest first_.

![rebasing](${rebase})

Rebase commands:
- \`p, pick <commit>\` Actually use the commit in the result
- \`r, reword <commit>\` edit the commit message and use it
- \`e, edit <commit>\` amend this commit and use it
- \`s, squash <commit>\` use it but meld into previous commit
- \`f, fixup <commit>\` like "squash", but discard this commit's log message
- \`x, exec <command>\` run command (the rest of the line) using shell
- \`b, break\` stop here (continues rebase with \`git rebase --continue\`)
- \`d, drop <commit>\` remove commit
- \`l, label <label>\` label current HEAD with a name
- \`t, reset <label>\` reset HEAD to a label
- \`m, merge\` create a merge commit

As you can see there is a lot you can do with this command. The most useful
stuff for me is typically squashing or reword multiple commit messages in one
step.

_Interactive Rebase is useful for edit multiple commits in a branch at the same._

### git reflog

This command is useful is you make mistakes by using the previous commands. The
\`reflog\` contains all the changes made to the tips of branches.

It's useful to find a safe place to reset your current working branch to in case
you've made a mistake using \`cherry-pick\` or \`rebase\`. For instance you can
see the current HEAD (the tips of the branches) on both your remote and local
branches. If you haven't pushed your incorrect local changes to a remote branch
yet, you can recover the old HEAD from the remote branch locally -- thus
  reverting your mistake.

_Using reflog gives you map back to safety if you've made mistakes in your
branch._
  `

  return (
    <div className="main-text">
      <ReactMarkdown linkTarget={'_blank'}>{markdown}</ReactMarkdown>
    </div>
  )
}
