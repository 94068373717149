import React from 'react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { setTitle } from '../PageUtils'

/**
 * Different information regarding XJ Axles
 */
export default function JeepXJAxleInfo() {
  const markdown = `
## Jeep XJ Axle Info

### Dana 44 Axles for MJs and XJs

The Trak-Lok options means it is a Limited-Slip differential.

| Serial #   | Host Model    |   Year      |   Gear Ratio |   Options  |
| :--------- | :-----------: | ----------: | :----------: | :--------: |
| 605287-1   | MJ            | 86          |   3.54:1     |            |
| 605287-2   | MJ            | 86          |   4.09:1     |            |
| 605287-3   | MJ            | 86          |   3.54:1     | Trak-Loc   |
| 605287-4   | MJ            | 86          |   4.09:1     | Trak-Loc   |
| 605342-1   | XJ            | 87.5        |   3.07:1     |            |
| 605342-2   | XJ            | 87.5        |   3.07:1     | Trac-Lok   |
| 605342-3   | XJ            | 87.5        |   3.54:1     |            |
| 605342-4   | XJ            | 87.5        |   3.54:1     | Trac-Lok   |
| 605342-5   | XJ            | 87.5        |   3.55:1     |            |
| 605342-6   | XJ            | 87.5        |   3.55:1     | Trac-Lok   |
| 605325-1   | MJ/XJ         | 87.5-93.5   |   3.54:1     |            |
| 605325-2   | MJ/XJ         | 87.5-93.5   |   4.09:1     |            |
| 605325-3   | MJ/XJ         | 87.5-93.5   |   3.07:1     |            |
| 605325-4   | MJ/XJ         | 87.5-93.5   |   3.07:1     | Trac-Lok   |
| 605325-5   | MJ/XJ         | 87.5-93.5   |   3.54:1     | Trac-Lok   |
| 605325-6   | MJ/XJ         | 87.5-93.5   |   3.54:1     |            |
| 605325-7   | MJ/XJ         | 87.5-93.5   |   3.54:1     | Trac-Lok   |
| 605325-8   | MJ/XJ         | 87.5-93.5   |   3.07:1     | Trac-Lok   |
| 605325-9   | MJ/XJ         | 87.5-93.5   |   3.54:1     | Trac-Lok   |
| 605325-10  | MJ/XJ         | 87.5-93.5   |   3.54:1     |            |
| 605325-11  | MJ/XJ         | 87.5-93.5   |   4.09:1     |            |
| 605325-12  | MJ/XJ         | 87.5-93.5   |   3.07:1     |            |
| 605325-13  | MJ/XJ         | 87.5-93.5   |   3.07:1     | Trac-Lok   |
| 605325-14  | MJ/XJ         | 87.5-93.5   |   3.54:1     | Trac-Lok   |
| 605325-15  | MJ/XJ         | 87.5-93.5   |   4.09:1     | Trac-Lok   |
`

  setTitle('Nathan Gilbert | Jeep XJ Axle Information')

  return (
    <div className="main-text">
      <ReactMarkdown linkTarget={'_blank'} remarkPlugins={[remarkGfm]}>
        {markdown}
      </ReactMarkdown>
    </div>
  )
}
