import React from 'react'
import ContentRow from '../ui/ContentRow'
import { setTitle } from './PageUtils'

/**
 * HomePage.
 *
 * The toplevel component of the app
 */
export default function HomePage() {
  setTitle('Nathan Gilbert | Software Engineer | Salt Lake City, UT')

  return (
    <div className="container-fluid">
      <ContentRow />
      <br />
    </div>
  )
}
