export const quote = `1. Communication usually fails, except by accident.
  - If communication can fail, it will.
  - If communication cannot fail, it still most usually fails.
  - If communication seems to succeed in the intended way, there's a misunderstanding.
  - If you are content with your message, communication certainly fails.
2. If a message can be interpreted in several ways, it will be interpreted in a manner that maximizes the damage.
3. There is always someone who knows better than you what you meant with your message.
4. The more we communicate, the worse communication succeeds.
  - The more we communicate, the faster misunderstandings propagate.
5. In mass communication, the important thing is not how things are but how they seem to be.
6. The importance of a news item is inversely proportional to the square of the distance.
7. The more important the situation is, the more probable you had forgotten an essential thing that you remembered a moment ago.
`

export const title = "Wiio's Laws"
export const author = 'Osmo Antero Wiio'
export const link = `https://en.wikipedia.org/wiki/Wiio%27s_laws`
