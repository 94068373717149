import React from 'react'

/*
  image
  text
  hr
*/
const contentBlock = (props) => (
  <div className="content-block">
    {Boolean(props.image) && <img src={props.image} alt="Content" />}
    <div className="content-block-text">
      {props.title}
      <div className="summary"> {props.summary} </div>
    </div>
    <hr />
  </div>
)

export default class ContentBlock extends React.Component {
  render() {
    return contentBlock(this.props)
  }
}

/*
ContentBlock.propTypes = {
  image: PropTypes.string,
}
*/

/* sample text
Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed nunc et dolor
ullamcorper accumsan. Nullam a justo orci. Quisque convallis enim porttitor arcu
porttitor, eget mollis justo pellentesque. Proin et est tincidunt eros vulputate
bibendum at eget neque. Nullam pulvinar viverra nisi, et congue sem tempus in.
Sed convallis odio et nunc posuere, ac volutpat felis blandit. Sed venenatis sed
felis eu vestibulum. Duis ipsum lectus, fringilla nec felis quis, bibendum
viverra eros. Phasellus viverra, quam ut rhoncus volutpat, ligula lorem lobortis
dui, at vehicula purus augue suscipit est. Maecenas in lorem dictum, vehicula
mauris vel, adipiscing lorem. Praesent sed iaculis mi, ut suscipit dolor. Cras
eu justo mauris. Aliquam at bibendum sapien. Pellentesque eget auctor lacus, non
ultricies elit. Duis vel nisl eget diam sagittis pharetra sed vitae diam.
*/
