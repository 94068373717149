import React from 'react'
import ReactMarkdown from 'react-markdown'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { atomOneLight } from 'react-syntax-highlighter/dist/cjs/styles/hljs'
import { setTitle } from '../PageUtils'

/**
Logical fallacies blog post
 */
export default function DevLifeHacks() {
  setTitle('Nathan Gilbert | Some tricks I use to make devlife easier')
  const renderer = {
    code: ({ language, value }) => (
      <SyntaxHighlighter
        style={atomOneLight}
        language={language}
        customStyle={{
          background: 'var(--background)',
          color: 'var(--text-color-normal)',
        }}
        showLineNumbers
        wrapLines
      >
        {value}
      </SyntaxHighlighter>
    ),
  }

  const markdown = `
## Subjective Dev Life Hacks

### Aliases

Aliases are available in most modern shells. They save time by reducing the amount of characters needed to invoke common comands.

~~~fish
alias ga='git add . and git add -u .'
alias gb='git branch'
alias gs='git status'
alias gt='cd (git rev-parse --show-cdup)'
alias gbd='git branch --delete'
alias gca='git commit --amend'
alias glg="git log --pretty=format:'%Cred%h%Creset -%Creset %s %Cgreen(%cr) %C(bold blue)<%an>%Creset' --abbrev-commit"
alias gpr='git pull --rebase'
alias nus='nvm use stable'
alias gcam='git commit -a -m'
alias gcan='git commit --amend --no-edit'
alias gcma='git checkout main'
~~~

### Git Decorations in your shell

You spend a lot of time in your shell, make it nice to look at and work for you.

- Bash
  - <https://github.com/magicmonty/bash-git-prompt>
- Zsh
  - <https://github.com/ohmyzsh/ohmyzsh>
  - <https://github.com/Flower7C3/oh-flowers-zsh-theme>
  - <https://github.com/unixorn/awesome-zsh-plugins>
- Fish
  - <https://github.com/jorgebucaran/awsm.fish>
  - <https://github.com/jorgebucaran/fisher>

### Command History and Searching

- bash
  - <https://www.linuxcommands.site/linux-text-processing-commands/6-methods-of-executing-historical-commands-in-linux-bash/>
- zsh
  - <https://github.com/zsh-users/zsh-completions>
  - <https://github.com/Zsh-Packages/fzf>
- fish
  - history is built in!
  - <https://github.com/PatrickF1/fzf.fish>

### Directory Jumping

Life's too short to type long directory names

- bash
  - <https://github.com/rupa/z/>
- zsh
  - <https://github.com/agkozak/zsh-z>
- fish
  - <https://github.com/jethrokuan/z>

### IDE Plugins

You probably spend even more time in your IDE, spruce it up a bit.

- Shortcuts: <https://dev.to/lampewebdev/the-guide-to-visual-studio-code-shortcuts-higher-productivity-and-30-of-my-favourite-shortcuts-you-need-to-learn-mb3>
- ESLint: <https://github.com/Microsoft/vscode-eslint>
- Code Spell Checker: <https://marketplace.visualstudio.com/items?itemName=streetsidesoftware.code-spell-checker>
- Rainbow Brackets: <https://marketplace.visualstudio.com/items?itemName=2gua.rainbow-brackets>
- Jest: <https://github.com/jest-community/vscode-jest>
- Prettier: <https://github.com/prettier/prettier-vscode>, run it on save!
- Vim 😁 😈

`

  return (
    <div className="main-text">
      <ReactMarkdown renderers={renderer} linkTarget={'_blank'}>
        {markdown}
      </ReactMarkdown>
    </div>
  )
}
