/* eslint-disable no-magic-numbers */
import React from 'react'
import { caesarCipher } from '../utils/CaesarCipher'

const rotValue = 13

const textElement = (
  <div className="container">
    <div className="main-text">
      <h1>Backstory</h1>
      <p>
        I am married to my best-friend,{' '}
        <a href="https://www.linkedin.com/in/gilbertsonya">
          {' '}
          Sonya Gilbert (n&eacute;e Lange)
        </a>
        .
        <br />
        We have a cat named Duke.
        <br />
        We love exploring Utah, and food, and staying home too.
        <br />
        We live in <a href="http://cityofholladay.com">Holladay, UT</a>.
        <br />
        <br />I am from{' '}
        <a href="https://en.wikipedia.org/wiki/Vanceburg,_Kentucky">
          Vanceburg, KY
        </a>{' '}
        and lived there for a long time, especially at the beginning.
        <br />I attended{' '}
        <a href="https://www.moreheadstate.edu">
          Morehead State University
        </a>{' '}
        and received a Bachelors of Science in Computer Science.
      </p>
      <p>
        I am a Principal Staff Engineer at{' '}
        <a href="https://www.testeract.com">Testeract</a>. Before that I was a
        Senior Software Engineer at{' '}
        <a href="http://www.workfront.com/">Workfront</a>, an Adobe Company. And
        before that, I was at{' '}
        <a href="https://www.instructure.com">Instructure</a> and even before
        that <a href="https://www.autodesk.com">Autodesk</a>.
        <br />
        When at MSU, I worked at the{' '}
        <a href="https://www.moreheadstate.edu/ssc">Space Science Center.</a>
      </p>
      <p>
        I moved to Utah to study Artificial Intelligence at the University of
        Utah.
        <br />
        While at the &quot;U&quot;, I was a member of the{' '}
        <a href="http://www.cs.utah.edu/nlp/">
          Natural Language Processing
        </a>{' '}
        research group.
        <br />
        My research focus was on automatic coreference resolution -- i.e.
        detecting and tracking entity mentions in free text.
        <br />I helped with the design and development of{' '}
        <a href="http://www.cs.utah.edu/nlp/reconcile">Reconcile</a>, an open
        source, extensible system for automatically resolving noun phrases to
        their antecedents.
        <br />I completed a Masters of Science in Computer Science while at the
        U.
      </p>
      <h2> Contact </h2>
      <p>
        Reach out to me on{' '}
        <a href="https://www.linkedin.com/in/nathanalangilbert/">LinkedIn</a> or
        via my email:{' '}
        <a
          href={'mailto:' + caesarCipher('anguna.tvyoreg@tznvy.pbz', rotValue)}
        >
          {caesarCipher('anguna.tvyoreg@tznvy.pbz', rotValue)}
        </a>
        <br />I have a HAM Radio General License, my callsign is KI4PXQ.
      </p>
    </div>
    <br />
    <br />
  </div>
)

export default class Me extends React.Component {
  componentDidMount() {
    document.title = 'Nathan Gilbert | About Me'
  }

  render() {
    return textElement
  }
}
