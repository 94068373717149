import { useEffect } from 'react'

/**
  Useful for setting the page title upon page render
 */
export function setTitle(title) {
  useEffect(() => {
    document.title = title
  })
}
