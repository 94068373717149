import React from 'react' // eslint-disable-line no-unused-vars

export default class MenuSocialMedia extends React.Component {
  render() {
    return (
      <div className="menu-social-media">
        <a
          href="https://github.com/nathan-gilbert"
          target="_blank"
          rel="noreferrer"
        >
          <i
            className="fab fa-github"
            style={{ fontSize: '32px', color: 'var(--button-color)' }}
          />
        </a>
        <a
          href="https://www.linkedin.com/in/nathanalangilbert"
          target="_blank"
          rel="noreferrer"
        >
          <i
            className="fab fa-linkedin-in"
            style={{ fontSize: '32px', color: 'var(--button-color)' }}
          />
        </a>
      </div>
    )
  }
}
