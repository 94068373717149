import React from 'react'

const canyonlands_jeep = require('../../../images/canyonlands-jeep.jpg')

const textElement = (
  <div className="container">
    <div className="main-text">
      <br />
      <h1>Outdoor Adventuring</h1>
      <center>
        <div className="outside-banner">
          <img
            id="optional"
            src={canyonlands_jeep}
            alt="Canyonlands National Park Panorama"
          />
        </div>
      </center>
      <br />
      <h3> The Land </h3>
      <p>
        I love the outdoors, especially the Southwest of the United States.{' '}
        <br />I strongly support{' '}
        <a
          href="https://www.doi.gov/blog/americas-public-lands-explained"
          target="_blank"
          rel="noreferrer"
        >
          public lands system
        </a>{' '}
        in the United States. <br />I am a member of the{' '}
        <a
          href="https://wasatchmountainclub.org"
          target="_blank"
          rel="noreferrer"
        >
          Wasatch Mountain Club
        </a>{' '}
        and have served as the Evening Hike Coordinator in the past.
        <br />
      </p>
      <h3> Trip Reports </h3>
      <p>
        I plan on creating some trip reports for past and future backpacking and
        camping excursions.
      </p>
      <h3> Jeep Projects </h3>
      <p>
        {' '}
        I love tinkering with 1984-2001 Jeep Cherokees (XJ). <br />
        They help you get way out there--to more secluded trailheads. <br />
        And they allow you to bring enough stuff to be comfortable.
        <br />I am on my 3rd XJ, a black 1998 4-Door Sport with the Upcountry
        Suspension Package.
        <br />
        Previously, I owned a 1994 blue 2-Door and a 1998 green 4-Door Sport.
      </p>
      <h3> The Beers </h3>
      <p>
        Check me out on{' '}
        <a href="https://untappd.com/user/nathangilbert">Untappd</a>.
      </p>
    </div>
  </div>
)

export default class Outside extends React.Component {
  componentDidMount() {
    document.title = "Nathan Gilbert | Stuff I'm doing outside."
  }

  render() {
    return textElement
  }
}
