/**
 * Returns a random quote from the supplied list
 * @returns {object} The quote/author pair
 */
export default function randomQuote() {
  const quotes = [
    { text: `Don't ever have heroes`, author: 'Me' },
    {
      text: `The factors predicting success in our "meritocracy" are a "combination
        of greed, cynicism, obsequiousness and subordination, lack of curiosity
        and independence of mind, [and] self-serving disregard for others."`,
      author: 'Noam Chomsky',
    },
    {
      text: `I've got a head full of drought
Down here
So far off from losing out
Round here
Overground
Watch this space
I'm open...to falling from grace`,
      author: 'Sneaker Pimps',
    },
    {
      text: '"*Always* get a contract when working with a dark, omnipotent power."',
      author: '#110',
    },
    {
      text: '"*Fear* killed Tolliver." "I thought it was those big guys in the costumes."',
      author: 'Tom Gregory/Tom, #108',
    },
    {
      text: '"*Happiness*? / You were raised Catholic."',
      author: 'Appolonia/Crow, #822',
    },
    {
      text: '"*I\'ll* decide if it\'s a good morning or not!"',
      author: 'Tom Servo (as God). #210',
    },
    { text: '"*I\'m* your mother now, Frank."', author: 'Dr. Forrester. #513' },
    { text: '"\'*Read* you\'?? I *love* you!"', author: 'Joel, #401' },
    { text: '"...An entire day spent grooming."', author: 'Joel, #510' },
    { text: '"...And starring Technicolor as himself."', author: 'Crow. #504' },
    { text: '"...And the Lord said DIE CHILDREN DIE!!"', author: 'Joel, #402' },
    { text: '"...Clara Barton\'s getaway car."', author: 'Crow. #822' },
    {
      text: "\"'...don't want to be pleasured by the slave girl'... What was I *thinking*?!?\"",
      author: 'Crow, #519',
    },
    {
      text: '"...Well, at least there are some cocktails in this dimension..."',
      author: 'Tom, #513',
    },
    {
      text: '"...yeah the Devil made this movie for you..."',
      author: 'Mike. #515',
    },
    {
      text: '"...You\'re just a Christmas present. / I wanted a Super Soaker!"',
      author: 'Mitchell/Crow, #512',
    },
    {
      text: '"[about a trapeze artist] Yes, our Betty swings both ways!" "Oh stop it." "She does! Look!"',
      author: 'Tom/Joel/Tom, #422',
    },
    {
      text: '"[Ben Murphy] Received Juris Doctor Degree Harvard Law School 1983. / Under Operation Admit The Dumb."',
      author: 'Computer/Mike, #814',
    },
    {
      text: '"[credit] Based on a novel by H. G. Wells / [scoffs] Not The H. G. Wells: Hud Gomer Wells."',
      author: 'Tom, #814',
    },
    {
      text: '"[credit] Best Boy Fuzzy Skinner." "Uh, so the best boy they could come up with was \'Fuzzy Skinner\'?"',
      author: 'Crow, #820',
    },
    {
      text: '"[credit] Joe Don Baker / Ooo, I wish I was illiterate so I didn\'t have to read that."',
      author: 'Crow, #1008',
    },
    {
      text: '"[credit] Universal / Universal, except for you, Ron."',
      author: 'Mike, #814',
    },
    {
      text: "\"[imitating Richard Burton] Not for the proud man apart from the raging moon I write, 'Gamera! Gamera! What dark, despised dreams dwell in the solid, waky bones 'neath your impenetrable shell?'\"",
      author: 'Crow, #312',
    },
    {
      text: '"[shouts in foreign language]" "Translated: Can\'t run from ugly!"',
      author: 'African Natives/Crow, #802',
    },
    {
      text: '"[sign] Danger Hairpin Curves / Extreme mellowness advised."',
      author: 'Mike, #814',
    },
    {
      text: '"[singing] GIRL! KEEP YOUR SILVER BRA FILLED WITH BREAST!"',
      author: 'Crow, #1002',
    },
    {
      text: '"[singing] It\'s the eighties; do a lot of coke and vote for Ronald Reagan!"',
      author: 'Mike, #907',
    },
    {
      text: '"[singing] Lookin\' for love, hopin\' for evil; alls I got was Chicken Cordon Bleu!"',
      author: 'Frank, #610',
    },
    {
      text: "\"[singing] My my my my Mitchell, what would your momma think?\" \"She'd say, 'He's not mine! You can't prove it!'\"",
      author: 'Hoyt Axton/Crow, #512',
    },
    {
      text: '"[singing] There\'s a black, greasy smudge on my pillow!"',
      author: 'Mike, #805',
    },
    {
      text: '"[singing] There\'s a dark, oily film on my headband!"',
      author: 'Crow, #805',
    },
    {
      text: '"[singing] There\'s a thick, yellow stain on my back brace!"',
      author: 'Crow, #805',
    },
    {
      text: '"[singing] When Debbie comes marching home again, hoorah, hoorah, we\'ll give her a panty girdle then, hoorah! Hoorah!"',
      author: 'Mike, #819',
    },
    { text: '"[singing] Workin\' on a sex farm!"', author: 'Mike, #705' },
    {
      text: '"[singing] Yes, the devil made this movie for you!"',
      author: 'Mike, #515',
    },
    {
      text: '"[sobbing] No more nights on musty blankets ... I\'ll really miss your open shirts! We\'ll play REO Speedwagon at your funeral, Billy!"',
      author: 'Mike, #706',
    },
    {
      text: '"[waves detection device over Paul] Not a trace, not a single trace." "Must be a Talent Meter."',
      author: 'Doc/Joel, #106',
    },
    {
      text: '"A fellow can get used to anything, I suppose." "Yeah. I once ate a woodchuck!"',
      author: 'Tex/Crow. #201',
    },
    {
      text: '"A few minutes ago in front of the power house, I saw the incredible, the unbelievable."  "A gibbon making flan!"',
      author: 'Dr. Wahrman/Crow, #906',
    },
    {
      text: '"A horticulturist told me you\'re the original hybridizer." "Nobody calls me that and lives!"',
      author: 'Pat/Tom, #105',
    },
    {
      text: '"A life preserver? That might be kind of ineffectual against killer shrews." "Well it is a life preserver ..."',
      author: 'Crow/Joel, #407',
    },
    {
      text: '"A lot of guys named \'Bo\' were killed that day."',
      author: 'Crow, #911',
    },
    {
      text: '"A lot of villains aren\'t bold enough to wear clamdiggers."',
      author: 'Mike. #515',
    },
    { text: '"A marine who says \'Gee whiz\'??..."', author: 'Joel. #108' },
    {
      text: '"A metaphysician doesn\'t believe you\'re dead when you die."  "So he\'s not much help at an accident scene."',
      author: 'Krasker/Crow, #603',
    },
    {
      text: '"A new city ordinance: all buildings to be painted beige."',
      author: 'Crow, #508',
    },
    {
      text: '"A pentagram, and reindeer laughing. You figure it out."',
      author: 'Tom, #521',
    },
    { text: '"A pop star afraid of a needle?"', author: 'Tom, #905' },
    {
      text: '"A rogue elephant snaps its tether and kills a coolie!" "Now who\'s getting dark?"',
      author: 'Joel/Tom, #422',
    },
    { text: '"A showered person; thank god!"', author: 'Crow, #810' },
    {
      text: '"A stranger comes to town, touches nobody\'s life, then leaves."',
      author: 'Crow. #609',
    },
    {
      text: '"A superweapon?" "Well, a really good one anyway."',
      author: 'Joe/Crow, #310',
    },
    {
      text: "\"A sweater that says, 'I've read the works of Alan Alda.'\"",
      author: 'Mike, #903',
    },
    {
      text: '"A whole new way to love the same old crap!"',
      author: 'Joel. #319',
    },
    {
      text: '"Abandon all buildings! / The Bronx has hit an iceberg and is sinking!"',
      author: 'Megaphone Guy/Tom, #705',
    },
    {
      text: '"Abandon all buildings! They are about to be demolished! / And I\'m mainly talking to you, Steve!"',
      author: 'Megaphone Guy/Mike, #705',
    },
    {
      text: '"Abstract expressionism: it\'s what\'s hot! All the kids are dying for it!"',
      author: 'Tom, #419',
    },
    { text: '"Accident?" "No thanks."', author: 'Tom/Joel #402' },
    {
      text: '"Actually filmed inside the thumbhole of a bowling ball."',
      author: 'Tom, #810',
    },
    {
      text: '"Actually, my name is Winchester P. Thorindueler, but you can call me Winky." "Yeah, we\'d call you that even if you didn\'t want us to."',
      author: 'Winky/Crow, #413',
    },
    {
      text: '"Actually, that countdown had more inherent drama that the rest of the film: I mean, would he stop, would he remember the next number ..."',
      author: 'Mike, #819',
    },
    {
      text: '"Actually, this is about as titillating as a Montgomery Ward Catalog."',
      author: 'Crow, #905',
    },
    { text: '"Aerial eroticism."', author: 'Crow, #614' },
    {
      text: '"After all this work, how could we really disarm, you know?"',
      author: 'Crow, #804',
    },
    {
      text: '"After years of waiting...you\'re gonna see the remaining 10% of my butt!"',
      author: 'Mike, #818',
    },
    {
      text: '"Again, the movie states unflinchingly that there is a city!"',
      author: 'Crow, #705',
    },
    {
      text: '"Ah crap, I got bean dip all over my new silk organza tulle strapless dotted Swiss frock!"',
      author: 'Tom, #903',
    },
    {
      text: '"Ah just don\'t want you to be disappointed if he doesn\'t come." "Mother, that\'s private!"',
      author: 'Momma/Crow, #1011',
    },
    {
      text: '"Ah yes, the ancient Japanese tradition of blowing up models at the end of the movie."',
      author: 'Tom, #816',
    },
    {
      text: '"Ah yes: that gooey LA sky." "Birds? Nah: all dead."',
      author: 'Crow/Mike, #814',
    },
    {
      text: '"AH! My neck got broken in that jump cut!"',
      author: 'Mike, #619',
    },
    {
      text: '"Ah, cram it, you meat puppet! I don\'t have time for your constant whiny crap!"',
      author: 'Gypsy, #1001',
    },
    {
      text: '"Ah, damn: there\'s only six rolls of toilet paper left! / All right, watch out, thunderbucket: you\'re about to meet your greatest challenge."',
      author: 'Crow/Tom, #706',
    },
    {
      text: '"Ah, finally the ultimate showdown between vaguely evil and somewhat ambiguous."',
      author: 'Tom, #705',
    },
    { text: '"Ah, good old fashioned nightmare fuel."', author: 'Mike, #812' },
    {
      text: '"Ah, good, good, back to the rusting septic system of this FUTURISTIC SPACE SHIP!"',
      author: 'Tom, #820',
    },
    {
      text: '"Ah, he wandered into the Ladies Room." "Man, that\'s why they stay in there so long. Those ladies, they get everything."',
      author: 'Mike/Crow, #822',
    },
    {
      text: '"Ah, here comes a duke of Hazzard." "It\'s everyone I ever went to high school with."',
      author: 'Tom/Mike, #810',
    },
    {
      text: '"Ah, here comes Nurse Feratu." "You know, I think everybody admitted to this hospital commits suicide."',
      author: 'Crow/Tom, #413',
    },
    {
      text: '"Ah, here it is: Little Babies\' Sun Hats Monthly."',
      author: 'Crow, #816',
    },
    {
      text: "\"Ah, he's dyin' out there. I'll go and flash a cheek.\"",
      author: 'Crow, #818',
    },
    {
      text: '"Ah, it always makes for good cinema when the caretaker shows an apartment."',
      author: 'Tom, #705',
    },
    {
      text: '"Ah, let\'s see if there\'s any Larry Csonka movies on tonight."',
      author: 'Tom, #910',
    },
    {
      text: '"Ah, Mister Handbag, once again I see you are not on the carousel yet."',
      author: 'Mike, #608',
    },
    {
      text: '"Ah, Mister Jesus, you have a nasty habit of surviving!"',
      author: 'Crow, #608',
    },
    { text: '"Ah, sector H8, I love it here!"', author: 'Crow, #705' },
    {
      text: '"Ah, sell soul, rent it back, get a big tax break."',
      author: 'Crow, #806',
    },
    {
      text: '"Ah, so this lousy movie ruins a perfectly good Jaguar."',
      author: 'Crow, #903',
    },
    { text: '"Ah, so you get the new Map Channel."', author: 'Mike, #815' },
    {
      text: '"Ah, the bright promise of a disgusting new character."',
      author: 'Mike, #810',
    },
    {
      text: '"Ah, the clean smell of kids who know they rule the world..."',
      author: 'Crow. #204',
    },
    {
      text: '"Ah, the director just points the camera at the lamp and trusts the lamp to carry the scene."',
      author: 'Tom, #811',
    },
    {
      text: '"Ah, the fertile breeding grounds of Calumet Chicken Farms."',
      author: 'Crow. #616',
    },
    {
      text: "\"Ah, the gripping 'luggage sequence'.\" \"I'm not gonna tell you what happens. I'm just gonna say, someone's luggage gets lost.\"",
      author: 'Tom/Crow. #608',
    },
    {
      text: '"Ah, the illegal smuggling of mimes! Nobody talks about it!"',
      author: 'Crow, #403',
    },
    {
      text: '"Ah, the lobster man was played by Guy Pringle." "Wow! Wouldn\'t wanna slam a stack of that guy."',
      author: 'Crow/Mike, #820',
    },
    { text: '"Ah, the Nebraska state forest."', author: 'Crow. #609' },
    {
      text: '"Ah, the rake thing again; so the movie does make sense."',
      author: 'Tom, #907',
    },
    {
      text: "\"Ah, they're in the classic 'let's pretend we didn't boink last night' pose.\"",
      author: 'Tom, #805',
    },
    {
      text: '"Ah, yeah, this footage. You know, this is great; I have such great memories of this footage." "From before?" "Yeah."',
      author: 'Mike/Crow/Mike, #819',
    },
    {
      text: '"Ah, yes, England, the land of jughead, chinless stomach eaters." "Still, they\'re not French. They have *that* going for them."',
      author: 'Tom/Crow. #818',
    },
    {
      text: '"Ah, you\'re right: this data proves I\'m a woman."',
      author: 'Crow, #706',
    },
    {
      text: '"Aha! And what brisk witticism will this chappy have to offer?"',
      author: 'Crow, #907',
    },
    {
      text: '"Ahhh, \'two scoops of raisins\' my ass..."',
      author: 'Joel, #405',
    },
    { text: '"Air is pretty."', author: 'Mike, #903' },
    { text: '"Air Krankor!"', author: 'Crow, #816' },
    {
      text: '"Air Swiss. / To order Toblerone press one."',
      author: 'Operator/Mike, #814',
    },
    {
      text: '"All characters depicted are really stupid and disgusting. / Any similarity to actual persons would be really sad."',
      author: 'Mike/Tom, #814',
    },
    {
      text: '"All his shirt needs is a chianti, candle, and a menu."',
      author: 'Tom, #1011',
    },
    {
      text: '"All I know is that in this outfit I feel like a total femme. And you know what? I don\'t have a problem with it!"',
      author: 'Tom, #508',
    },
    {
      text: '"All right, there should be a delay of movie penalty right about now."',
      author: 'Mike, #1004',
    },
    {
      text: '"All right, you know what? I\'m just giving in and looking at the breasts."',
      author: 'Crow, #622',
    },
    {
      text: '"All riiight! Beautiful maidens in the hands of hideous beasts. This is my kind of movie!"',
      author: 'Joel. #107',
    },
    { text: '"All the fats are numbing my emotions."', author: 'Tom, #610' },
    { text: '"All these monster fights are rigged."', author: 'Crow. #213' },
    {
      text: '"All these names are Russian for Alan Smithee."',
      author: 'Mike, #813',
    },
    { text: '"Allow me to knee you right in the groin."', author: 'Tom, #416' },
    {
      text: '"Almost all of the monsters had their haircuts done that way back then."',
      author: 'Joel. #312',
    },
    {
      text: '"Alternate vibrators inserted..." "Hey!! You can\'t say that!"',
      author: 'Technician/Crow. #508',
    },
    {
      text: '"Although we\'ve got a lot more air pollution than we used to. / Since we ate Johnny\'s stew."',
      author: 'Paul Carlsen/Mike, #1007',
    },
    {
      text: '"Always dive head first into an unfamiliar lake."',
      author: 'Tom, #807',
    },
    {
      text: "\"Am I *out of* 'Reba'? Do I *need* more 'Reba'?\" \"Or *any* 'Wynona'?\"",
      author: 'Tom Servo/Crow. #610',
    },
    { text: '"America is leaning on cheese!"', author: 'Crow. #421' },
    { text: '"America not available in some areas."', author: 'Mike. #811' },
    {
      text: '"\'Amount embezzled: ten point seven million.\'"',
      author: 'Crow, #814',
    },
    {
      text: '"An American democracy. / Would be really great."',
      author: 'Narrator/Tom, #621',
    },
    {
      text: '"An ape is still an ape! / An ape by any other name would smell as bad!"',
      author: 'Caroline/Joel, #306',
    },
    {
      text: '"An executive story consultant?" "Yes, I advise you to hire the most annoying cracker you can find and slam together two incomprehensible stories."',
      author: 'Crow/Tom, #814',
    },
    {
      text: '"And ... why do you want to be a scale model here at ConGlom Co.?"',
      author: 'Mike, #705',
    },
    {
      text: '"And a proud, noble civilization dies out ... sometimes. This loser civilization, however, will not be missed!"',
      author: 'Crow, #816',
    },
    {
      text: '"And all your back hair problems are gone." "It\'s about time, you big, ugly yak!"',
      author: 'Mike/Tom, #515',
    },
    {
      text: '"And as the seasons change the refueling continues!"',
      author: 'Mike, #612',
    },
    {
      text: '"And as the world is enchanted with our whimsy, we\'ll slowly take over their minds!"',
      author: 'Dr. Forrester, #610',
    },
    {
      text: '"And Bobby\'s hopelessly inbred synapses slowly begin to fire."',
      author: 'Mike, #606',
    },
    { text: '"And don\'t dub with your mouth full!"', author: 'Crow, #306' },
    { text: '"And don\'t let your mom dress the band."', author: 'Crow. #905' },
    {
      text: '"And he forced Gramps to drive here? He *is* insane!"',
      author: 'Joel, #404',
    },
    {
      text: '"And here come the freaks now, look at \'em..."',
      author: 'Crow. #422',
    },
    {
      text: "\"And I don't want *anyone* strapping *anyone's* hands across *anyone's* engines!!\"",
      author: 'Joel. #322',
    },
    { text: '"And I get to lick the axe!"', author: 'Crow, #806' },
    {
      text: '"And I shall be there... bringing you my golden fish." "Oh, wowwww..."',
      author: 'Morgiana/Joel. #505',
    },
    {
      text: '"And I will be the one to hold Larry Csonka down. Kiss him so hard. I\'ll take Larry Csonka\'s breath away."',
      author: 'Tom, #910',
    },
    {
      text: '"And in case you\'re interested, mucilage tastes just like sweet honey! Mmmm..."',
      author: 'Crow. #308',
    },
    { text: '"And Madonna thinks *she\'s* innovative!"', author: 'Crow. #422' },
    {
      text: '"And make sure that Dr. Takebana doesn\'t commit suicide." "Phht. Yeah, he\'s always pulling that."',
      author: '#314',
    },
    {
      text: '"And my girls, all of them, are dedicated to finding your device." "Excuse me?"',
      author: 'Batwoman/Mike, #515',
    },
    {
      text: '"And no one on Earth will ever know that Santa was kidnapped by Martians!" "Do you realize what you just said?"',
      author: 'Voldar/Joel. #321',
    },
    {
      text: '"And now the thick, meaty conclusion of Final Justice!"',
      author: 'Crow, #1008',
    },
    {
      text: '"And now we\'ll wait for 21 days for things to happen." "Oh, you can\'t *wait* for things to happen, young man.  You\'ve got to MAKE them happen!"',
      author: 'Narrator/Crow, #702',
    },
    {
      text: '"And now you\'re suggesting baloney has no insulating properties?" "Well, I don\'t know..." "It\'s really cold, you\'re nude, and all you have is some baloney, and you refuse to wear it because it\'s absurd.  That\'s really sad, Mike."',
      author: 'Tom/Mike, #807',
    },
    {
      text: '"And presto! / Well, andante maybe."',
      author: 'Bell Phones Woman/Mike, #906',
    },
    {
      text: '"And remember, if you are bitten by a bat, you will convulse and turn into one, it\'s a scientific fact."',
      author: 'Mike, #1010',
    },
    {
      text: '"And the movie ramps up the repulsion. / This movie hates us, doesn\'t it."',
      author: 'Mike/Crow, #810',
    },
    {
      text: '"And the subject of today\'s political debate: politics. Gentlemen?"',
      author: 'Mike, #614',
    },
    {
      text: '"And then the moon came out, and it was like Jerry willed it!"',
      author: 'Gypsy, #603',
    },
    {
      text: '"And there is no need to despair as yet. / Ok, now despair."',
      author: 'Japanese PM/Crow, #819',
    },
    {
      text: '"And thus did mighty Samson overcome the Phillistine." "By hitting him over the head with a sitting stick while wearing a priest costume??"',
      author: 'Tucker/ Crow. #419',
    },
    {
      text: '"And when you cheat, you make an eat out of C and H."',
      author: 'Crow, #515',
    },
    {
      text: '"And when you pledge to the Soviet Union, you get this nifty tote bag!"',
      author: 'Tom, #602',
    },
    {
      text: '"Angels work about as hard as road crews."',
      author: 'Crow, #701',
    },
    {
      text: '"Annikki! Sister of mine! / You shaved your beard!"',
      author: 'Ilmarinen/Crow, #422',
    },
    {
      text: '"Another Buddhist protesting the helmet laws..."',
      author: 'Joel. #501',
    },
    { text: '"Another right turn, those fiends!"', author: 'Tom, #610' },
    {
      text: '"Antigravity! It\'s not just for breakfast anymore!"',
      author: 'Joel. #111',
    },
    {
      text: '"Any fruit to declare?... ANY *FRUIT* TO DECLARE?!?!"',
      author: 'J&tB, #501',
    },
    {
      text: '"Any luck, Crow?" "Uh, yes. All of it bad."',
      author: 'Mike/Crow, #809',
    },
    {
      text: '"Any moment now, unspeakable horror! Trust me!"',
      author: 'Tom, #421',
    },
    {
      text: '"Any similarity to actual persons would be really sad."',
      author: 'Tom, #814',
    },
    {
      text: '"Apes of the oooold west... That\'s John Wesley Ape, once flung crap at a man just for snorin\' too loud."',
      author: 'Joel. #306',
    },
    {
      text: '"Apparently, some of you do not want to leave the Bronx. As a first step, we urge you to try thinking outside the Bronx."',
      author: 'Mike, #705',
    },
    { text: '"Apply panties to *me*, Mike."', author: 'Crow. #819' },
    {
      text: '"Are the other armed services as easy listening as the Air Force?"',
      author: 'Crow, #612',
    },
    {
      text: '"Are there any other of you that wish to confuse freedom with treason?" "I\'d like to confuse bok choy with cabbage, sir..."',
      author: 'Evil Engineer/Mike, #820',
    },
    {
      text: '"Are you always so picayunish?" "No, I\'m Lithuanian."',
      author: 'Gary/Mike, #1010',
    },
    {
      text: '"Are you coming, or am I going to have to swim alone?"  "YES and YES."',
      author: 'Mara/Crow, #816',
    },
    {
      text: '"Are you ok, Gyps?" "Of course I am, Mitchell Nelson."',
      author: 'Mike/Gypsy, #810',
    },
    { text: '"Are you with the bride or the failure?"', author: 'Crow, #422' },
    {
      text: '"As a scientist, I\'m constantly working with materials that threaten life on a global scale, and, sometimes, they spill..."',
      author: 'Dr. F, #411',
    },
    { text: '"As dull as a beige room."', author: 'Tom, #421' },
    { text: '"Ask me about my extra chromosome!"', author: 'Joel, #112' },
    {
      text: '"At all times he has the look of a man who\'s been hit with a fish."',
      author: 'Crow, #903',
    },
    {
      text: '"At least its poor editing covers how badly it was shot." "Yeah, it\'s a movie that constantly reassures you, \'You don\'t have to concern yourself with this.\'"',
      author: 'Crow/Tom, #911',
    },
    {
      text: '"At least we killed \'em before we stuffed \'em in there."',
      author: 'Crow. #415',
    },
    { text: '"At this point, what\'s inappropriate?"', author: 'Crow. #523' },
    { text: '"At what point do they free Willy?"', author: 'Crow, #515' },
    {
      text: '"Attention people of Earth, I am Ambassador Phantom from the planet Krankor." "We are Ken."',
      author: 'Krankor/M&tB, #816',
    },
    { text: '"Avalanche footage! Run!"', author: 'Mike. #803' },
    {
      text: '"Aw, thank you, little lady." "You\'re welcome, huge man."',
      author: 'Joe Don Baker/Crow, #1008',
    },
    {
      text: '"Aw, the director wrote that so he could get laid."',
      author: 'Crow, #622',
    },
    {
      text: '"Baby oil?!" "NOOOOOOOO!!!!!!" "My my my my GOD!!!!!!"',
      author: '#512',
    },
    {
      text: '"Bacchus, King of Wine!" "Bud, King of Beer!"',
      author: 'The annoying guy/Crow. #206',
    },
    {
      text: '"Back when ballet dancers moonlighted as chauffeurs ..."',
      author: 'Crow, #705',
    },
    { text: '"Bad movie? You\'re soaking in it!"', author: 'Crow. #402' },
    {
      text: '"Bad movies have taught us that sheriffs hang on to their belts a lot."',
      author: 'Crow, #706',
    },
    { text: '"Barkeep, could you freshen my date?"', author: 'Joel. #421' },
    {
      text: '"Batwoman\'s medallion is just, like, ... right there."',
      author: 'Mike, #515',
    },
    {
      text: '"BEEP plot point BEEP plot point BEEP plot point..."',
      author: 'All. #414',
    },
    {
      text: '"Benjamin Franklin\'s kite is up there. George Washington is trying to chop the tree down and make wooden teeth. Thomas Jefferson is down there making time with a slave..."',
      author: 'Crow, #821',
    },
    {
      text: '"Big, should I have not put my head in the driver\'s lap? Was that wrong?"',
      author: 'Crow, #509',
    },
    {
      text: '"Bilingual schools! / Bisexual students!"',
      author: 'Narrator/Mike, #621',
    },
    {
      text: '"Black and white\'s gritty, it helps get across the harsh realism that is everyday life." "Oh, gee, sounds like fun. The way I see it, Robinson, the Jodes had a depressing enough life as it was, and then, they filmed it in black and white."',
      author: 'Joel/Crow, #413',
    },
    { text: '"Bob Dornan, wild at heart."', author: 'Mike, #612' },
    {
      text: '"Bobo, if you harm a single one of God\'s precious creatures, so help me I\'ll kill you."',
      author: 'Pearl, #902',
    },
    { text: '"Book of Me, Chapter 4, Verse 12..."', author: 'Joel, #510' },
    {
      text: '"Boom! You throw a carton of milk right at him!" "It contained bovine growth hormone, and he turned into a giant cow!"',
      author: 'Officer Donavon/Mike, #809',
    },
    {
      text: '"Booze and I are buying a house together, we\'re going to move in."',
      author: 'Joel, #512',
    },
    { text: '"Booze really heals!"', author: 'Dr. Forrester. #414' },
    { text: '"Bottom line, CAN WE KILL THE IMP!?"', author: 'Crow, #806' },
    { text: '"Boy! God sure has a crummy office!"', author: 'Tom, #210' },
    { text: '"Boy! He sure is smooth, boss!"', author: 'Crow, #504' },
    {
      text: '"Boy! Walking on the walls must be a big problem!"',
      author: 'Joel. #109',
    },
    {
      text: '"Boy, how come you only get tired when you have to stay awake to avoid being replaced by an evil pod creature?"',
      author: 'Mike, #810',
    },
    {
      text: '"Boy, how come you only get tired when you have to stay awake to avoid being replaced by an evil pod creature?"',
      author: 'Mike, #810',
    },
    {
      text: '"Boy, I bet that\'d be scary if we could make out what it was."',
      author: 'Crow. #108',
    },
    {
      text: '"Boy, I sure hope I don\'t get Legionnaire\'s Disease."',
      author: 'Crow, #814',
    },
    {
      text: '"Boy, I sure hope it never stops being the seventies or we\'ll all be in trouble."',
      author: 'Mike, #814',
    },
    {
      text: '"Boy, if you\'re into creeping around corners, this is your movie."',
      author: 'Mike, #705',
    },
    {
      text: '"Boy, it was windy last night: the cows stuck right to the barn!"',
      author: 'Tom, #403',
    },
    {
      text: '"Boy, it\'s *blue* in Egypt. Bluer than I remember."',
      author: 'Tom, #516',
    },
    { text: '"Boy, it\'s really *blue* out today..."', author: 'Crow. #511' },
    {
      text: '"Boy, nothing more depressing than being locked in a capsule watching a movie about people dying in a capsule."',
      author: 'Crow, #201',
    },
    { text: '"Boy, the sun\'s strong tonight."', author: 'Crow. #906' },
    {
      text: '"Boy, you know, a retarded jellyfish could make a better movie than this!"',
      author: 'Tom, #820',
    },
    {
      text: "\"Boys and girls, today's film is called 'Virginity: Your Precious Gift.'\"",
      author: 'Crow, #811',
    },
    {
      text: '"Brain has performed an illegal operation and will be shut down."',
      author: 'Mike, #1007',
    },
    {
      text: '"Breakfast is the most boring meal of the day."',
      author: 'Crow. #416',
    },
    {
      text: '"Brush, and brush, at least 100 strokes." "Just keep brushing and brushing and saying the name of our Lord and Savior!"',
      author: 'Narrator/Mike, #613',
    },
    {
      text: "\"But as far as you space monkeys are concerned I'm the new marshal in town. That's right, I'm the God, I'M THE GOD! ... May I take your order please?\"",
      author: 'Frank, #201',
    },
    {
      text: '"But I\'ve come up with something even cooler: Wiffle Cheese." "Hey, wait a minute, this is just Swiss Cheese." "That\'s right! It\'s nature\'s own wiffle!"',
      author: 'Crow/Joel/Crow, #511',
    },
    {
      text: '"But Paris was destroyed in the apocalypse, Winky..."',
      author: 'Tom, #413',
    },
    {
      text: '"But she\'s upper middle class and we\'re slightly lower middle!"',
      author: 'Crow, #1001',
    },
    {
      text: '"But there was another factor: extremely low temperature, which caused the time scale to warp, and, therefore, the deep freeze capsule acted as a time converter." "Ohhh, this movie\'s even stupider than I thought it was."',
      author: 'Dr. Lee/Tom, #306',
    },
    {
      text: '"But you don\'t understand! I\'m a PRINCE!!"',
      author: 'Semya. #317',
    },
    {
      text: '"But you get to watch the walnuts grow up wild and free."',
      author: 'Crow, #908',
    },
    { text: '"But, Santa, why, *why* are you taking my gun?"', author: '#503' },
    {
      text: '"Buy me anything in there with Larry Csonka on it. I\'ll pay for it."',
      author: 'Mike, #910',
    },
    {
      text: '"By the time this decade is out, we will put a man in a pickup truck, and bring him safely to Mandaseno County."',
      author: 'Crow, #701',
    },
    { text: '"By the way, Carrie, you\'re so very."', author: 'Joel, #509' },
    {
      text: '"By this time my lungs were aching for air."',
      author: 'Crow. #109',
    },
    {
      text: '"By this time my lungs were aching for booze ..."',
      author: 'Crow, #622',
    },
    {
      text: '"Can God Himself make a plot so complicated that even He can\'t comprehend it?"',
      author: 'Tom, #412',
    },
    { text: '"Can I borrow a cup of BALD?"', author: 'Tom, #903' },
    { text: '"Can I borrow a cup of sex?"', author: 'Tom, #820' },
    { text: '"Can I eat you when you die?"', author: 'Crow, #524' },
    {
      text: '"Can I show you something in a size *me*?"',
      author: 'Crow, #507',
    },
    {
      text: '"Can souls get toe fungus? Can I still have butter if I\'m a soul?"',
      author: 'Tom, #1001',
    },
    {
      text: '"Can we all just stop and look at our scripts? ... Oh, I guess it does say that Boy George drives in lobbing Molotov cocktails."',
      author: 'Tom, #403',
    },
    {
      text: '"Can we smoke?" "I don\'t care if you burst into flames."',
      author: 'Sergeant/Joel, #309',
    },
    {
      text: '"Can we stop for a snack? Dave would have wanted us to."',
      author: 'Joel, #406',
    },
    {
      text: '"Can you catch a venereal disease from a movie?"',
      author: 'Tom, #907',
    },
    {
      text: '"Can you have a platonic relationship before Plato?"',
      author: 'Tom, #410',
    },
    { text: '"Canada is for lovers!"', author: 'Tom, #910' },
    { text: '"Canadian villain: Garth Vader."', author: 'Mike, #910' },
    {
      text: '"Can\'t lose you now. We never even had a dance together." "You never even bought a muffler together; what\'s the point?!"',
      author: 'Appolonia/Crow, #822',
    },
    {
      text: '"Captain, is Europe a big country, over?" "Uh, Captain, I don\'t speak Europe..."',
      author: 'Tom/Mike, #612',
    },
    {
      text: '"Captain, it\'s time for dinner. / We\'re having tobacco salad with a light gin dressing."',
      author: 'Ann Craigis/Tom, #407',
    },
    {
      text: "\"Captain's log: I've lost my toupee and girdle, and I can't leave my room.\"",
      author: 'Tom, MST3K:TM',
    },
    {
      text: '"Carver! / Raymond Carver! If we don\'t save him minimalism is doomed!"',
      author: 'Kim Cattrall/Tom, #403',
    },
    {
      text: '"Catch the game last night?" "You bet, I lost fifty bucks!" "But you had the point spread!" "Ah, Aikman slips out of the pocket and he\'s a cupcake!" "Oh, Aikman can go to blazes..."',
      author: 'Tom/Crow (as Nuns), #601',
    },
    {
      text: '"Cavorting really makes you lose your electrolytes."',
      author: 'Joel. #505',
    },
    {
      text: '"Central Moving and Storage: mention Riding With Death and get three free packing blankets."',
      author: 'Mike, #814',
    },
    {
      text: '"Cheating is bad. Richard Basehart is good."',
      author: 'Gypsy. #515',
    },
    {
      text: '"Check it out: her hair acts like a smokeless ash tray."',
      author: 'Tom, #818',
    },
    {
      text: '"Check rate of radioactive decay. / Increase the Flash Gordon noise and put more science stuff around!"',
      author: 'Cal/Crow, MST3K:TM',
    },
    { text: '"Chicks love big ducks, let\'s face it."', author: 'Crow. #523' },
    {
      text: '"Children of the Damned Day at the Brain Institute, here?"',
      author: 'Tom, #822',
    },
    {
      text: '"Ciao. / That\'s my favorite breed of Chinese dog."',
      author: 'Leo/Crow, #1002',
    },
    {
      text: '"Citrusville, City of Progress! Where everyone is juiced!"',
      author: 'Crow. #207',
    },
    { text: '"C\'mon, I\'m huge, humor me."', author: 'Crow, #420' },
    {
      text: "\"'Come back tonight after dark. The back door will be unlocked. I have something important to tell you.' / 'Wear something sheer.'\"",
      author: 'Steve/Tom, #106',
    },
    {
      text: '"Come here you little tramp! When I think of the Blood Beast\'s hands all over you ..."',
      author: 'Tom, #701',
    },
    {
      text: '"Come on, *this* is your mother now. Try her out!"',
      author: 'Tom, #318',
    },
    {
      text: '"Come on, kid, get your act together! Just read Catcher in the Rye and deal with it!"',
      author: 'Crow, #614',
    },
    {
      text: '"Come on, let\'s put out our flaming crotches, and we\'ll find your nose."',
      author: 'Mike, #811',
    },
    {
      text: '"Come on, run! Try and move faster than the plot!"',
      author: 'Crow, #306',
    },
    {
      text: '"Come on, you guys. It\'s not a real wedding. Don\'t cry." "We know. It\'s the *script* that\'s upsetting us!"',
      author: 'Joel/Crow. #105',
    },
    {
      text: '"Commander! The plant is completely destroyed!" "I told you to *water* the plant!!"',
      author: 'Japanese Solder/Crow, #302',
    },
    { text: '"Conform... conform..."', author: 'All. #319' },
    {
      text: '"Congratulations, you are now officially white trash."',
      author: 'Crow, #209',
    },
    {
      text: '"Conspicuous consumption makes our love stronger."',
      author: 'Tom, #524',
    },
    { text: '"Cool! Rock it, you turkey!"', author: 'Mike, #814' },
    {
      text: '"Corman\'s theory of directing: light and get away."',
      author: 'Crow, #806',
    },
    {
      text: '"Costarring Bruce Springstein\'s little brother, Wayne."',
      author: 'Mike, #821',
    },
    {
      text: '"Could I regurgitate up anything to make it up for you?" "Oh, I\'ll take some lettuce, if you have any lettuce." "Ah! Coming up!"',
      author: 'Bobo/Brain Guy/Bobo, #820',
    },
    { text: '"Could something please just happen!!"', author: 'Crow. #504' },
    {
      text: '"Could we get more volume on that jacket, please?"',
      author: 'Joel, #512',
    },
    {
      text: '"Could we just forget about the game and pump up Servo\'s head until it pops?"',
      author: 'Gypsy, #511',
    },
    {
      text: '"Could you and I ever be more than spinster and lycanthrope?"',
      author: 'Tom, #809',
    },
    { text: '"Could you be a little more vague?"', author: 'Tom, #208' },
    {
      text: '"Could you please state that in the form of a desperate cry to God to save you from an unholy death?"',
      author: 'Joel. #205',
    },
    { text: '"Could you say that a little more woodenly?"', author: '#205' },
    { text: '"\'Course, we are dumb cheeseheads..."', author: 'Crow. #810' },
    {
      text: '"Creeper, creeper, creeper...YOU GIVE *ME* THE CREEPS!"',
      author: 'Old Shopkeeper, #702',
    },
    {
      text: '"Crew? They had a crew?! I do not believe they had a crew!" "Ok, whoa, everybody pick out someone you wanna punch." "Oh ... where to start ..."',
      author: 'Crow/Joel/Tom, #424',
    },
    {
      text: '"Crimes like this never happen anymore thanks to McGruff!"',
      author: 'Crow, #1002',
    },
    {
      text: '"Crooks are always dropping obvious clues like that. It\'s really a desperate cry for help."',
      author: 'Joel. #108',
    },
    { text: '"Crotch Fires And You."', author: 'Mike, #811' },
    {
      text: '"Crow, how could you make a deal with the devil?" "How could I not, Mike, with prices like these?"',
      author: 'Mike/Crow, #818',
    },
    {
      text: '"Crow, I still don\'t see how dressing in diaphanous robes and slapping each other around is gonna make Joel happy." "Well, don\'t hurt yourself thinking about it! Now, shut up and wrestle, spinach chin!"',
      author: 'Tom/Crow, #424',
    },
    {
      text: '"Crow, that was word for word the same report that Gypsy just gave!" "Wow! What are the odds of that happening?"',
      author: 'Mike/Gypsy, #515',
    },
    {
      text: '"Cupcake! Get Twinkie the Kid and Fruit Pie the Magician!"',
      author: 'Mike, #814',
    },
    { text: '"Cyanide goes to my hips."', author: 'Mike. #606' },
    {
      text: '"Dad played by H. L. Menkin." "Jabba the Husband."',
      author: 'Mike/Crow, #809',
    },
    {
      text: '"Daddy needs a new pair of hydraulic talons!"',
      author: 'Crow, #403',
    },
    { text: '"Daddy, there\'s a boy outside..."', author: "TV's Frank. #301" },
    {
      text: '"Dames like her always keep beer around."',
      author: 'Paramedic. #106',
    },
    {
      text: '"Damn it! Keep showing underwear, movie. I\'ll be right back!"',
      author: 'Crow, #1001',
    },
    {
      text: '"Damn! I just injected him with blowfish poison!"',
      author: '#324',
    },
    { text: '"Damn! You are me, I guess I am a fraud!"', author: 'Tom, #810' },
    {
      text: '"Darling, you look as if you were ready to kill me. / Bingo!"',
      author: 'Vi/Crow, #414',
    },
    {
      text: '"Data Corp.! Providing personnel decisions and management consulting for exploiting your core competencies with an eye toward productivity and connectivity!"',
      author: 'Mike, #814',
    },
    {
      text: '"Davey, let me introduce you to these federal agents. They are what you young people call \'bad asses.\'"',
      author: 'Crow, #614',
    },
    {
      text: '"David Hartman is just one big neck with face and eyes."',
      author: 'Crow. #614',
    },
    {
      text: '"David Hartman: your guaranteed TV movie seal."',
      author: 'Mike, #614',
    },
    { text: '"Day 12: The expedition turns whiny."', author: 'Tom, #803' },
    {
      text: "\"'Dear Abby: I'm an elderly woman who doesn't enjoy sex...'\"",
      author: 'Tom (as Rocky Jones). #417',
    },
    {
      text: "\"Dear Counselor Troi, I waited at Denny's but you didn't meet me. / Dear Agent Scully, did not appreciate your lawyer's tone.\"",
      author: 'Mike/Tom, #910',
    },
    {
      text: '"Dear Japan, maybe it\'s just me and the rest of the world, but huge, naked fat men hitting stomachs is not a sport."',
      author: '#819',
    },
    {
      text: '"Dear Penthouse Forum, I am an average stuffed animal at a small midwestern college..."',
      author: 'Tom. #615',
    },
    {
      text: '"\'Dear Santa Claus: Please bring me a little brother.\' Oh ho ho ho! Can do! Can do!"',
      author: 'Crow, #521',
    },
    {
      text: "\"'Dear Skipper: Why haven't you sent help? Signed, The Castaways.'\"",
      author: 'Crow. #106',
    },
    {
      text: '"Death rides comfortably with Central Moving and Storage!"',
      author: 'Crow, #814',
    },
    { text: '"Degas\'s Reclining Loser."', author: 'Tom, #821' },
    {
      text: '"Delicious fruit flavor burst right through his skull."',
      author: 'Crow, #504',
    },
    {
      text: '"Demo button on little keyboard hit by..."',
      author: 'Mike (as credits).#821',
    },
    {
      text: '"Dennis! Dennis!" "I want to play tennis!"',
      author: 'McReany/Mike, #907',
    },
    {
      text: '"Devil\'s office... / ...no, he went down to Georgia, lookin\' for a soul to steal..."',
      author: 'Crow/Mike, #906',
    },
    {
      text: '"Did he say \'Whopper\'? What is that, a pet name?"',
      author: 'Joel. #310',
    },
    {
      text: '"Did his head just turn into a big sucker?"',
      author: 'Tom, #509',
    },
    {
      text: '"Did I imagine it, Mike, or did a chubby angel with gin blossoms just visit us?"',
      author: 'Tom, #822',
    },
    { text: '"Did I mention my saliva is poisonous?"', author: 'Crow. #407' },
    {
      text: '"Did I mention that I cried? Is it wrong not to always be glad?"',
      author: 'Morrissey, #403',
    },
    {
      text: '"Did someone tape a dinner roll to his chin?"',
      author: 'Crow. #821',
    },
    {
      text: '"Did you hear anything else like a door closing or somebody running? / Or the sound of one hand clapping?"',
      author: 'Alan Hale/Crow, #106',
    },
    {
      text: '"Did you know flying a plane is like making love." "Uh, you have to pay?"',
      author: 'Colonel Hunt/Crow, #612',
    },
    {
      text: '"Didn\'t they consider taking over one of the Earth\'s good nations?"',
      author: 'Crow, #816',
    },
    {
      text: '"Dill is a good spice; I will NOT have it maligned!"',
      author: 'Crow, #614',
    },
    { text: "\"'Dish of Ice Cream'?? Don't tempt me!\"", author: 'Joel. #415' },
    {
      text: '"Disappointing turnout for REO Speedwagon at Alpine Valley."',
      author: 'Tom. #621',
    },
    {
      text: '"Divide by ten in minutes. Add up to three columns in an hour!"',
      author: 'Crow. #810',
    },
    {
      text: '"Do a good job, and I\'ll show my appreciation by not killing you."',
      author: 'Dr. Forrester, #607',
    },
    {
      text: '"Do any of these kids have names?" "Well, they\'re so distinct; I don\'t think there\'s any reason to give them names."',
      author: 'Tom/Mike, #819',
    },
    {
      text: '"Do I have enough time to beat up the band?"',
      author: 'Crow, #508',
    },
    {
      text: '"Do I please you? Do you find me pleasing?"',
      author: 'Crow, #313',
    },
    {
      text: '"Do not be afraid, I do not hurt innocent people. I have assistants for that."',
      author: 'Tom, #306',
    },
    {
      text: '"Do not induce vomiting." "The movie will do that for you."',
      author: 'Tom/Crow. #207',
    },
    { text: '"DO SOMETHING!!! GOD!!!"', author: 'Joel, #424' },
    {
      text: '"Do something, Joel! This aggressive niceness is making me feel really uncomfortable!"',
      author: 'Crow. #417',
    },
    {
      text: '"Do the apes know they\'re filming in the Forbidden Zone?!"',
      author: 'Crow, #706',
    },
    {
      text: '"Do you have cartoon music playing in your head, too?"',
      author: 'Mike, #807',
    },
    {
      text: '"Do you have enough gas?" "I\'ve got plenty!" "I already blasted a hole in the seat cover!"',
      author: 'Ann/Brewster/Crow, #906',
    },
    {
      text: '"Do you have to bring everything you find in space into the living room?"',
      author: 'Crow. #104',
    },
    {
      text: '"Do you realize a robot just sang a love song to a turtle?"',
      author: 'Crow. #302',
    },
    {
      text: '"Do you think that this is just a trumpet? NO! / It\'s a bong!"',
      author: 'Mr. B/Joel, #319',
    },
    {
      text: '"Do you think we\'ll freeze to death before the jackals get us?"',
      author: 'Tom, #506',
    },
    {
      text: '"Do you want a slice of my beer while we wait, Mike?"',
      author: 'Crow, #818',
    },
    {
      text: '"Do you want to go *faster*? Raise your hand if you want to go *faster*!"',
      author: 'Joel. #308',
    },
    { text: '"Doctor Who...the hell cares?"', author: 'Crow. #822' },
    {
      text: '"Does anyone know who this is?" "Nope."',
      author: 'Mike/Crow, #812',
    },
    {
      text: '"Does anything really star Joe Estevez? Isn\'t that an oxymoron?"',
      author: 'Crow, #1001',
    },
    {
      text: '"Does he have to die splayed all over that other guy?"',
      author: 'Tom, #815',
    },
    {
      text: '"Does it matter that the spider ate him with his butt? / I think it seems suitable enough for this guy."',
      author: 'Tom/Mike, #810',
    },
    {
      text: '"Does just walking through it make you want to kill yourself? Well, then it\'s a high school!"',
      author: 'Crow, #809',
    },
    {
      text: '"Does that guy inflate his pants to attract mates?"',
      author: 'Mike, #820',
    },
    {
      text: '"Does this *really* merit the tender music?"',
      author: 'Crow, #904',
    },
    {
      text: '"Does this bug you? I\'m not touching you..."',
      author: 'Crow, #311',
    },
    {
      text: '"D\'oh! The one day they\'re not wearing bikinis is the day I stop by!"',
      author: 'Mike, #515',
    },
    {
      text: '"D\'oh! Was today the day we were supposed to leave the Bronx?"',
      author: 'Tom, #705',
    },
    {
      text: '"D\'oh!...I filled my pants, sir...In fact, I think I filled yours too."',
      author: 'Mike, #902',
    },
    {
      text: '"Dolphins: one of the smartest mammals on Earth. Do they wear pants? No! But they *wish* they did. That\'s how smart they are!"',
      author: 'Tom, #410',
    },
    {
      text: '"Don\'t *ever* mention Emerson Lake & Palmer around Gypsy!"',
      author: 'Joel. #313',
    },
    {
      text: '"Don\'t change the subject. / It alters the meaning of the sentence."',
      author: 'Greedy Woman/Crow, #703',
    },
    {
      text: '"Don\'t examine your soul, JUST GET SPEED DIALING!"',
      author: 'Mike, #906',
    },
    {
      text: "\"Don't give me that 'Woof!' crap! I want an answer!\"",
      author: 'Crow, #523',
    },
    {
      text: '"Don\'t go into that area. There\'s radiation there." "And, as everybody knows, it can only affect you if you touch it."',
      author: 'Dr. Hidaka/Tom, #302',
    },
    {
      text: '"Don\'t point that goat at me, it might go off!"',
      author: 'Tom, #108',
    },
    {
      text: "\"Don't think I'm not grateful for your hospitality: I am. / But you're outta liquor, so ...\"",
      author: 'Captain Thorne Sherman/Crow, #407',
    },
    { text: '"Don\'t watch the jiggling..."', author: 'Tom, #622' },
    {
      text: '"Don\'t worry, he\'s as gentle as a kitten." "Yeah, a big, bald, deranged kitten that could snap your neck like celery!"',
      author: 'Bela/Tom, #423',
    },
    {
      text: '"Don\'t worry, kids: it\'s a promotional gimmick for Sushi Cereal!"',
      author: 'Mike, #819',
    },
    {
      text: '"Don\'t you do that! You put that butt right back in your pants, young man!"',
      author: 'Crow, #809',
    },
    {
      text: '"Don\'t you even care how insulting you are to the thousands of people out there who have big, prosthetic, foam chins??!!"',
      author: 'Tom, #1004',
    },
    {
      text: '"Don\'t! Don\'t squeeze those juice boxes on that couch!"',
      author: 'Pearl, #907',
    },
    {
      text: '"Drink it all. Sometimes the poison\'s at the bottom."',
      author: 'Crow, #301',
    },
    {
      text: '"Droppo, you are the laziest man on Mars."',
      author: 'Keemar. #321',
    },
    {
      text: '"Due to an error, there are still a few of you left alive. We sincerely apologize for any inconvenience this may have caused you."',
      author: 'Joel, #107',
    },
    { text: '"Dukes of Hazzard got renewed!"', author: 'Crow, #814' },
    {
      text: '"Eadie!! / I want my bikini area waxed!!"',
      author: 'Hank/Tom, #906',
    },
    {
      text: '"Eadie. / Don\'t start the New Bohemians."',
      author: 'Hank/Crow, #906',
    },
    {
      text: '"EASTMAN! He came from the east, to do battle with the amazing RANDO!"',
      author: 'Tom, MST3K:TM',
    },
    { text: '"Eating hamburgers while the world cried."', author: 'Tom, #515' },
    {
      text: '"Eggs are complicated.  They should cost like a hundred dollars each."',
      author: 'Mike, #702',
    },
    { text: '"Eight miles wrong!"', author: 'Crow, #905' },
    {
      text: '"El Santa flew 23 missions over North Korea!"',
      author: 'Tom, #521',
    },
    {
      text: "\"Electrons don't dance, Fingal. They don't make love. / They're Lutherans.\"",
      author: 'Appolonia/Crow, #822',
    },
    {
      text: '"Eleven dollars went a long way back then."',
      author: 'Crow. #610',
    },
    { text: '"Emotions are for ethnic people."', author: 'Crow, #602' },
    {
      text: '"Espionage is such a small community. It\'s quite like a little family, really. / In that we all drink and hurt each other."',
      author: 'Tree/Mike, #608',
    },
    { text: '"Et tu, Billy Bob?"', author: 'Crow, #814' },
    { text: '"Even the *soundtrack* is making coffee!"', author: 'Tom, #609' },
    {
      text: '"Even the chemistry teacher can shake his booty!"',
      author: 'Mike, #606',
    },
    {
      text: '"Even the musicians are mocking this film."',
      author: 'Crow. #210',
    },
    {
      text: '"Even these oil fields seem to remind me of her... Can\'t quite put my finger on it..."',
      author: 'Joel, #202',
    },
    {
      text: '"Even though your feet smell like cheese, we prefer to think they smell like *good* cheese."',
      author: 'Crow. #309',
    },
    {
      text: '"Even when it\'s bright it\'s dark in this movie."',
      author: 'Mike, #619',
    },
    {
      text: '"Ever get pieces of flesh caught in your teeth? I *hate* that!"',
      author: '#203',
    },
    {
      text: '"Ever thought of putting two similar sentences together?"',
      author: 'Crow. #408',
    },
    {
      text: "\"Every chick for herself, them's the real facts of life, cookie! If he wants to dance he's gonna have to pay the band. If he wants to paint the house he's gonna have to buy the enamel. ...And the primer. ...And you still might want to consider aluminum siding.\" \"Oh, Grandma Pearl, I don't know what you're talking about.\" \"I'm not sure I do either, darling. But if he wants to sod the lawn he's gonna have to...\"",
      author: 'Pearl/Darlene, #811',
    },
    {
      text: '"Every frame of this movie looks like someone\'s last known photograph."',
      author: 'Crow, #424',
    },
    {
      text: '"Every mad scientist has one of these speeches."',
      author: 'Crow. #102',
    },
    {
      text: '"Every movie from the South is gonna thank a trailer service at some point."',
      author: 'Crow, #1006',
    },
    {
      text: '"Every successful television show has had a winsome child of some sort! Take your Family Affairs, your Full Houses, your The Avengers!" "The Avengers didn\'t have a cute kid!" "Well, split hairs if you will. The point is we need something cute on the show!"',
      author: 'Dr. Forrester/Crow, #705',
    },
    {
      text: '"Every time I come close to not hating him I see those feet on the side of the van."',
      author: 'Mike, #706',
    },
    {
      text: '"Every time I get a boyfriend, he crushes my windpipe!"',
      author: 'Joel, #106',
    },
    {
      text: '"Every time I meet a man, he\'s either gay or a bear!"',
      author: 'Tom, #813',
    },
    { text: '"Everyone, quick, light your martinis!"', author: 'Joel, #407' },
    {
      text: '"Evil, beware, Coatimundi Man is born this day!"',
      author: 'Tom, #903',
    },
    { text: '"EW! I instantly need counseling."', author: 'Crow, #1001' },
    {
      text: '"EW! What is that?!" "Can you show that?!"',
      author: 'Crow/Tom, #706',
    },
    { text: '"Eww! He\'s really moist!"', author: 'Tom, #522' },
    { text: '"Ewww. Are they done? Can I look?"', author: 'Crow, #512' },
    {
      text: '"Exactly when in the future will you become not stupid, Mike?"',
      author: 'Crow, #821',
    },
    {
      text: '"Excuse me while I have a strange interlude."',
      author: 'Crow, #502',
    },
    { text: '"Excuse me, your nose is coming off..."', author: 'Crow. #806' },
    {
      text: '"Excuse me. I couldn\'t help but notice how much you look like everyone else."',
      author: 'Crow, #510',
    },
    {
      text: '"Excuse me. I\'m gonna go toss myself out the window."',
      author: 'Joel, #420',
    },
    { text: '"Existential void where prohibited."', author: 'Crow. #318' },
    {
      text: '"Exotic locations like Normandale Community College!"',
      author: 'Mike, #816',
    },
    { text: '"\'Expandable legbands\'? Yes *sir*!!"', author: 'Joel, #404' },
    {
      text: '"Expressing individualism is just plain wrong!"',
      author: 'Crow, #510',
    },
    {
      text: '"Father feigns eating, draws Junior out, then DISOWNS HIM!"',
      author: 'Mike, #602',
    },
    {
      text: '"Father, I had a feeling today." "Well don\'t, son."',
      author: 'Mike/Tom, #602',
    },
    { text: '"Feel free to dig your heel into my groin."', author: '#315' },
    { text: '"Feel some malaise, sucka!"', author: "'Jimmy Carter', #501" },
    {
      text: '"Fellow poultrymen, our cherished freedoms are threatened from all sides!"',
      author: 'Tom, #702',
    },
    { text: '"Filmed in a ghetto in Kingston, Jamaica?"', author: 'Tom, #819' },
    { text: '"Filmed in Bald Spot Vision!"', author: 'Crow, #804' },
    { text: '"Filmed in Panel Vision!"', author: 'Crow, #614' },
    {
      text: '"Filmed in Vermont, the other, smaller Wisconsin."',
      author: 'Crow. #821',
    },
    { text: '"Filmed on Joan Collins\' bed sheets!"', author: 'Crow, #809' },
    {
      text: '"Filmed on Minnie Ha Ha Avenue in Minneapolis." "Yeah, only the interiors were shot in Hawaii."',
      author: 'Crow/Tom, #608',
    },
    { text: '"Filmed through chocolate milk."', author: 'Crow, #1007' },
    { text: '"Finally! I killed my virtual pet."', author: 'Mike, #822' },
    {
      text: '"Finally! Possible nudity! Ok, sit back."',
      author: 'Crow, #1001',
    },
    {
      text: '"Find out what he put on his application under \'Do you dance a lot?\'"',
      author: 'Tom, #906',
    },
    { text: '"First base is LARGER than I remember!"', author: 'Mike, #523' },
    { text: '"First rule: No theme music."', author: 'Dr. Forrester. #523' },
    {
      text: '"Fish Argument Theater will be back! But first a scene from Plot Convenience Playhouse."',
      author: 'Crow, #316',
    },
    { text: '"Fishpicker!"', author: 'All. #907' },
    {
      text: '"Flag on the moon.  How did it get there?"',
      author: 'Coleman Francis, #621',
    },
    {
      text: '"Flat, drab passion meanders across the screen!"',
      author: 'Mike, #912',
    },
    {
      text: '"Flavia, the talking bushes are annoying me! Ow! And they\'re hitting me, too! And now they\'re trying to muffle my screams, Flaviaaa!!!"',
      author: 'Professor Bobo, #819',
    },
    { text: '"Flesh is a nice color for a house."', author: 'Mike, #706' },
    {
      text: '"Fond as I am of Ilya... / I hate him."',
      author: 'Prince/Tom, #617',
    },
    {
      text: '"Food?!? Is that all you can think of at a time like this?!" "You mean, lunchtime?"',
      author: 'Judy/Crow, #610',
    },
    {
      text: '"For you folks at home, this might be a good time to go get a sandwich."',
      author: 'Joel. #202',
    },
    {
      text: '"Forge a sampo! Or you\'ll never see the face of your pretty sister again." "Can we see the other parts?"',
      author: 'Witch Louhi/Crow, #422',
    },
    {
      text: '"Forgive me!  I\'m... / unappealing!"',
      author: 'Deathstalker/Crow, #703',
    },
    {
      text: '"Forgive me, Father, for I have sinned: I have rubbed Pernell\'s toupee all over my naked body!"',
      author: 'Crow, #614',
    },
    {
      text: '"Forty percent of all traffic accidents are caused by women\'s hinders!"',
      author: 'Crow. #520',
    },
    {
      text: '"Frank, Frank... Once again I\'m going to have to kill you."',
      author: 'Dr. Forrester. #320',
    },
    {
      text: '"Frank, I\'ll give you three seconds to stop licking my face." "Count slow..."',
      author: 'Dr. Forrester/Frank. #601',
    },
    {
      text: '"Frank, let\'s pretend that I hurt you and move on, shall we?"',
      author: 'Dr. Forrester. #405',
    },
    {
      text: '"Frank, my towel and your hinder have an appointment."',
      author: 'Dr. Forrester. #512',
    },
    {
      text: '"Frank, quit bogarting that toothpaste!"',
      author: 'Dr. Forrester. #417',
    },
    { text: '"Free trial sample! Try *me* for a week!"', author: 'Tom, #406' },
    { text: '"Fries are up."', author: 'Tom, #211' },
    {
      text: '"Frolicking has never been so depressing."',
      author: 'Crow. #609',
    },
    { text: '"Fun\'s over. We\'re gonna die now."', author: 'Tom, #506' },
    {
      text: '"Furthermore, I\'ll prove to you the strength of our great science; I shall cause the greatest earthquake the world has ever witnessed! It will ... destroy everything. All Tokyo will be annihilated in a minute." "Wow, she\'s PMSing big time."',
      author: 'Evil Girl/Crow, #316',
    },
    { text: '"Future cities of the past, by Kenner."', author: 'Tom, #819' },
    {
      text: '"Gardening always takes a back seat to science."',
      author: 'Tom, #103',
    },
    {
      text: '"Gather your eggs often: three or four times a day." "Make sure you put them all in one basket."',
      author: 'Narrator/Crow, #702',
    },
    {
      text: '"Gee, it\'s funny lookin\'. / Smaller than us; we could torment it!"',
      author: 'Kid #4/Tom, #819',
    },
    {
      text: '"Gee, the sky\'s as clear as Gary, Indiana."',
      author: 'Crow. #617',
    },
    {
      text: '"Gee. Ohio looks great after the apocalypse!"',
      author: 'Joel. #501',
    },
    { text: '"Geez, he broke the face barrier."', author: 'Tom, #612' },
    {
      text: '"Geez, Joel, I\'m riding such a major bummer I feel like I just spent a couple of hours at Liquor Lyle\'s talking to Neil Young!"',
      author: 'Crow, #414',
    },
    {
      text: '"Geez, lady, scream in someone else\'s ear!"',
      author: 'Crow. #406',
    },
    {
      text: '"Geez, they\'re making the Chicken Men of Krankor look super confident and dignified."',
      author: 'Tom, #819',
    },
    { text: '"Geez, you could shave with her voice."', author: 'Mike, #613' },
    {
      text: '"Geez... they washed up at a Klingon language camp."',
      author: 'Tom, #605',
    },
    {
      text: '"Genius...pure genius... / couldn\'t save this film."',
      author: 'Fingal/Crow. #822',
    },
    {
      text: '"Gentlemen! Ours is a society based on ancient traditions!" "Really? I thought we just sold each other fish."',
      author: 'Tom/Crow. #505',
    },
    {
      text: '"Gentlemen, this may be our last day on Earth. / Let\'s hit the karaoke bar."',
      author: 'The Japanese Don Knotts/Mike, #816',
    },
    { text: '"Gerrymandering!  Actual Footage!"', author: 'Tom, #1013' },
    {
      text: '"\'Get bent!!\' Is that official enough for you?!"',
      author: '#205',
    },
    {
      text: '"Get her onto the bed. / You know, this might sound crass, but we\'re getting really good at this." "They\'re gonna have to build an addition just to stack the bodies!"',
      author: 'Molly Stevens/Joel/Crow, #303',
    },
    {
      text: '"Get out of here! / You\'re in my pickled eel garden!"',
      author: 'Space Chief/Mike, #819',
    },
    {
      text: '"Get over here. We just wanna kill you for a minute."',
      author: '#503',
    },
    {
      text: '"Get your paws off me, you damn, dirty beat!"',
      author: 'Crow, #419',
    },
    {
      text: '"Get your shoes on, we\'re at the monster."',
      author: 'Crow, #311',
    },
    {
      text: '"Give him this. It\'s raw pork. He\'ll know what it means."',
      author: '#205',
    },
    {
      text: '"Give me five dollars or I\'ll kill my index finger!"',
      author: 'Crow, #818',
    },
    { text: '"Glen! Remember *these*?"', author: 'Crow (as Joyce). #319' },
    { text: '"Go bingle your bongle!"', author: 'Silver, #601' },
    {
      text: '"Go find out what he put on his job application under \'Do you drink a lot?\'"',
      author: 'Tom, #906',
    },
    {
      text: '"GO TO HELL, CITIZENS AND OFFICIALS OF RUTLAND, VERMONT!!!"',
      author: 'Tom, #821',
    },
    {
      text: "\"'Go to work'? / But I'm an actor.\"",
      author: 'Aram Fingal/Crow, #822',
    },
    { text: '"Go, Sir Sack of Door Knobs."', author: 'Crow, #806' },
    {
      text: '"God I love water.  I think I love it too much."',
      author: 'Mike, #603',
    },
    {
      text: '"God, he\'s a catcher\'s mitt with eyes!"',
      author: 'Crow, #1001',
    },
    {
      text: '"Good god I\'m ugly. Scared my own children out of the nursery today."',
      author: 'Crow, #508',
    },
    {
      text: '"Good thing the Venusians could never get very far from the bathroom."',
      author: 'Crow. #211',
    },
    {
      text: '"Good thing they made everything out of balsa wood back then."',
      author: 'Tom, #410',
    },
    { text: '"Goodbye, unfunny weird man!"', author: 'Crow, #816' },
    {
      text: '"Got to get going, there\'s still a lot to do!" "Oh, not on our account; feel free to end!"',
      author: 'Fingal/Tom, #822',
    },
    { text: '"Gotta be at my Ben Murphiest!"', author: 'Crow, #814' },
    {
      text: '"Gotta find a petting zoo. There are times when a man needs the touch of a llama."',
      author: 'Crow, #606',
    },
    { text: '"Granddad\'s *already* disturbed."', author: 'Crow. #308' },
    {
      text: '"Grandpa\'s so mad right now. He says he\'s gonna shoot you on sight the next time you set foot on the property." "Wow, we have some to root for."',
      author: 'Tom, #706',
    },
    { text: '"Graphics by *Kenner*."', author: 'Tom, #820' },
    { text: '"Great place to die, though. Real spooky!"', author: 'Tom, #421' },
    {
      text: '"Great, another Larry Storch wannabe, here."',
      author: 'Tom, #706',
    },
    {
      text: '"Great, they lapped the plot; we\'re back at the beginning!"',
      author: 'Crow, #403',
    },
    {
      text: '"Greetings for all! Who accepts the challenge that I have brought?" "Ope! Can\'t: got a bad knee." "I did the last challenge!"',
      author: 'Lemminkäinen/Tom/Crow, #422',
    },
    {
      text: '"Grooming is between you and the lord God."',
      author: 'Crow, #510',
    },
    {
      text: '"Guess the genders and win a free cocktail!"',
      author: 'Comedian/Mike, #812',
    },
    {
      text: '"Gummi bears don\'t spread darkness and death... do they?"',
      author: 'Tom, #404',
    },
    {
      text: '"Guys, I think we\'re deep into film student territory here."',
      author: 'Crow, #810',
    },
    {
      text: '"Guys, I\'ll show ya somethin\'! / Shorts you can tattoo on!"',
      author: 'Japanese Kid #3/Tom, #819',
    },
    {
      text: '"Guys, just skip the music and go right to the heroin."',
      author: 'Tom, #905',
    },
    { text: '"Guys, that\'s not cheese..."', author: 'Gypsy. #513' },
    {
      text: '"Guys, why is it so hard to accept that fact that time is not an immutable forward progression, but a factor in a spacetime model of relativistic causality and determinism? Huh? Guys? Hello?! Geez, like a couple of cattle, these two."',
      author: 'Tom, #511',
    },
    { text: '"GYAH! It\'s Uncle Fester in shorts!"', author: 'Tom, #906' },
    {
      text: '"Gypsy crushes Joel! We\'ll be right back."',
      author: 'Gypsy. #415',
    },
    {
      text: '"Gypsy, you\'ve got to have some eye wash, that big ass eye of yours."',
      author: 'Crow, #1001',
    },
    {
      text: '"Ha ha ha ha... We\'re bald and we\'re bonding!"',
      author: 'Joel, #322',
    },
    {
      text: '"Ha ha! Nothin\' like a good child endangerment joke."',
      author: 'Tom, #907',
    },
    {
      text: '"Ha ha! Unlucky for you I secrete pine tar from my hands!"',
      author: 'Crow, #821',
    },
    {
      text: '"Hallmark Hall of Fame presents: \'The Touch of Satan.\'"',
      author: 'Crow, #908',
    },
    {
      text: '"Halt! Who goes there?! Who played Doctor Zaius on Planet of the Apes?!"',
      author: 'Joel, #306',
    },
    {
      text: '"Harassing you, Bob? Ridiculous. / So I pinched your butt once."',
      author: 'Leonard Driscoll/Crow, #814',
    },
    {
      text: '"Hardly any animals were hurt in the making of this film."',
      author: 'Joel. #408',
    },
    {
      text: '"Have I mentioned I\'m immense and immortal?"',
      author: 'Joel (as Hercules). #502',
    },
    {
      text: '"Have people wised up to hiring guys like this?" "Oh, come on, Mike, how is he any different than you? Really?"',
      author: 'Mike/Crow, #912',
    },
    { text: '"Have some sun, little friend."', author: 'Mike, #811' },
    {
      text: '"Have you got the bird of happiness?" "Well, we\'ve got a pretty friendly chicken..."',
      author: 'Sinbad/Crow, #505',
    },
    {
      text: '"Have you noticed that my accent has grown as bad as yours?"',
      author: 'Tom Servo, #904',
    },
    {
      text: '"Having their headquarters on Pike\'s Peak is kind of a pain."',
      author: 'Mike, #622',
    },
    { text: '"HE COPIED MY FRIGGIN\' PAPER!"', author: 'Gypsy, #515' },
    { text: '"He copied my frigging paper!!!"', author: 'Gypsy. #515' },
    { text: '"He could use his chin as a piton."', author: 'Crow. #821' },
    {
      text: '"He cracked the code to Scrubbing Bubbles."',
      author: 'Mike. #822',
    },
    { text: '"He died as he died: dead."', author: 'Mike, #705' },
    {
      text: '"He does look like an easy target for thugs. Hell, *I\'d* like to beat him up."',
      author: 'Crow. #509',
    },
    {
      text: '"He drives a Maverick? You know, I would have put the Prince of Darkness in a muscle car."',
      author: 'Mike, #908',
    },
    {
      text: '"He enjoys pantaloons more than he should."',
      author: 'Mike. #521',
    },
    {
      text: '"He had an awesome dream...ala Lionel Ritchie."',
      author: 'Tom #614',
    },
    {
      text: '"He had on a suntan uniform." "You mean, he was naked?"',
      author: 'Penny/Crow, #112',
    },
    { text: '"He has a real screen absence."', author: 'Mike, #906' },
    {
      text: '"He is the master of torture; he would have really long credits like this."',
      author: 'Mike, #908',
    },
    {
      text: '"He just brought the level of discourse *way* down."',
      author: 'Tom, #505',
    },
    {
      text: '"He just polygraphed a sandwich! He needs real help!"',
      author: 'Mike. #517',
    },
    {
      text: '"He learned almost too late that man is a feeling creature, and because of it one of the greatest in the universe."',
      author: 'Peter Graves. #311',
    },
    {
      text: '"He looks like a cross between a Silver Back Gorilla and Eddie Van Halen."',
      author: 'Tom, #705',
    },
    {
      text: '"He looks like a cross between Jerry Mathers and James Dean. / Beaver Without A Cause."',
      author: 'Crow/Tom, #106',
    },
    {
      text: '"He looks like a welder who has fainting spells."',
      author: 'Mike, #816',
    },
    { text: '"He made her bark!"', author: 'Crow. #421' },
    { text: '"He made one mistake: he parked."', author: 'Crow, #705' },
    {
      text: '"He must devour three times his own weight every day... / plus a delicious shake."',
      author: 'Narrator/Tom, #407',
    },
    {
      text: '"He needs more facial hair. / Yeah, less face."',
      author: 'Tom/Crow, #614',
    },
    { text: '"He refrigerates his bowling bag?"', author: 'Joel, #512' },
    { text: '"He should die for those pants alone."', author: 'Mike, #622' },
    { text: '"He should use his chin as a piton."', author: 'Crow, #821' },
    {
      text: '"He shoulda slid him *across* the bar." "That hadn\'t been invented yet."',
      author: 'Joel/Tom, #511',
    },
    {
      text: '"He tampered in God\'s domain..."',
      author: 'Captain Robbins. #423',
    },
    {
      text: '"He tried to kill me with a forklift... Ole!"',
      author: 'All. #310',
    },
    {
      text: '"He was made in the image of an egg noodle!"',
      author: 'Crow, #910',
    },
    { text: '"Heeney hiney horny..."', author: 'Hiney. #209' },
    {
      text: '"Hehehe, drop the pizzas! Drop the pizzas! Make the cheese really hot and drop the pizzas!"',
      author: 'Crow, #819',
    },
    {
      text: '"He\'ll either win the Nobel Prize or the Heisman Trophy."',
      author: 'Crow. #513',
    },
    {
      text: '"He\'ll never touch you, Terry, you\'re dirt."',
      author: 'Mike, #610',
    },
    {
      text: '"Hello, hello, hello! This spring\'s ape is dashing, daring, and absolutely shameless in paramilitary garb as the House of Primates unveiled its Tarzan Collection to the dazzled designers assembled in Milan, and, ha, oh lucky me, I have the footage."',
      author: 'Crow, #306',
    },
    {
      text: '"Hello, this is Pete in props. Don\'t eat the cake!"',
      author: 'Tom, #415',
    },
    {
      text: '"Hello. I\'m Droppy, the Water Droplet. Did you know that there are literally tens of thousands of uses for water? Here are just a few thousand."',
      author: 'Crow, #1004',
    },
    { text: '"Help I\'m in another dimension!!..."', author: 'Crow, #513' },
    {
      text: '"Help me please!! I\'m being attacked by a ham sandwich!!"',
      author: 'Crow, #317',
    },
    { text: '"Help me, Obi Wan...oh, wrong movie."', author: 'Crow. #806' },
    { text: '"Help me, oh Saint Larry Csonka."', author: 'Crow, #910' },
    {
      text: '"Help! I\'m falling at a sixty degree angle; breaking all the laws of physics!"',
      author: 'Mike, #903',
    },
    {
      text: '"Her back looks like a Klingon\'s forehead!"',
      author: 'Crow. #209',
    },
    { text: '"Her neck is having a flash back!"', author: 'Crow, #610' },
    {
      text: '"Hercules now concludes his broadcasting day. Good night, and may Herc bless."',
      author: 'Tom, #502',
    },
    {
      text: '"Here come the comics of the air in dexterous and hilarious feats high above the tan bar. / Store this image away for a later nightmare."',
      author: 'Narrator/Tom, #422',
    },
    {
      text: '"Here comes the bride, she\'s out of her frickin\' mind..."',
      author: 'Mike. #805',
    },
    {
      text: '"Here in Texas, there is an additional problem... / Texans!"',
      author: 'Narrator/ Crow, #507',
    },
    {
      text: '"Here, drink this. It\'ll make you feel American."',
      author: 'Crow, #402',
    },
    {
      text: '"Here\'s a tip: Don\'t buy the Tommy Kirk Workout Video."',
      author: 'Mike. #609',
    },
    {
      text: '"Here\'s the plan: my army of trained rats will lead the charge, and my scabies will protect our flank."',
      author: 'Mike, #910',
    },
    {
      text: '"Here\'s to risking your life in the name of some national TV exposure."',
      author: 'Joel. #320',
    },
    {
      text: '"Here\'s to you, whiskey: guardian of all frustrated wives." "Defender of truth...friend to children..."',
      author: 'June/Mike, #802',
    },
    { text: '"He\'s a *spazz*stronaut."', author: 'Crow. #902' },
    {
      text: "\"He's a nurse's aid who doesn't play by the rules!\"",
      author: 'Crow, #811',
    },
    { text: '"He\'s a Woozle. His name is Peanut."', author: 'Tom, #201' },
    {
      text: '"He\'s been injecting her with young and pretty, now he should start injecting her with smart and nice."',
      author: 'Tom, #105',
    },
    { text: '"He\'s being kidnapped by Kiss."', author: 'Mike, #705' },
    {
      text: '"He\'s dead and therefore unable to come to the phone."',
      author: 'Tom, #319',
    },
    {
      text: '"He\'s dead, but don\'t worry; they have a spare."',
      author: 'Tom, #821',
    },
    {
      text: '"He\'s enjoying the bold, adventurous life of a TV repairman!"',
      author: 'Crow, #911',
    },
    {
      text: '"He\'s evil, and evil people always come out at night!" "Nobody\'s watching they even come in the daytime." "Although when evil people are mating they will appear at dawn."',
      author: 'Mickey/Wally/Crow, #816',
    },
    {
      text: '"He\'s flashing back to other people\'s memories."',
      author: 'Crow, #1001',
    },
    { text: '"He\'s got a figure like Marcia Brady."', author: 'Crow. #608' },
    { text: '"He\'s got a severe allergy to shirts."', author: 'Crow, #1007' },
    { text: '"He\'s got an extra Y chromosome."', author: 'Tom, #417' },
    { text: '"He\'s got double chins on his eye lids."', author: 'Tom, #1008' },
    { text: '"He\'s got his outdoor eyebrows on now."', author: 'Crow, #907' },
    { text: '"He\'s guarding Malta\'s only plant."', author: 'Crow, #1008' },
    { text: '"He\'s like an Armand Assantawannabe."', author: 'Tom, #820' },
    {
      text: "\"He's listening to 'Awaken the Beer Drinker Within.'\"",
      author: 'Crow, #911',
    },
    { text: '"He\'s only mostly dead!"', author: 'Mike #513' },
    {
      text: '"He\'s Prince of watching his SPACE Cushion!"',
      author: 'Crow, #816',
    },
    { text: '"He\'s shooting at a different movie!"', author: 'Crow. #423' },
    { text: '"He\'s staying at a bed and renfest."', author: 'Crow, #913' },
    { text: '"He\'s the clown who makes the dark side fun."', author: '#424' },
    {
      text: '"He\'s the guy with all the words." "...You mean he\'s the guy with all the BREAD." "Words, bread, must be Samuel Johnson with a baguette..."',
      author: 'Michelle/Buzz/Tom, #1002',
    },
    {
      text: '"Hey! Could you get the subplot off the road, please?!"',
      author: 'Crow, #308',
    },
    {
      text: '"Hey! I\'m sacred; leave me alone."',
      author: 'Joel (as a Cow). #505',
    },
    {
      text: '"Hey! Let\'s go play Wounded Baby Unicorn And Skinny, Sociopathic Janitor!"',
      author: 'Crow, #1006',
    },
    {
      text: '"Hey! My favorite number: wooo!" "Seventy?" "Yeah! I love seventy!"',
      author: 'Tom/Mike #814',
    },
    { text: '"Hey! Telekinetic book dumping!"', author: 'Tom, #903' },
    {
      text: '"Hey! That guy on the left: that\'s an American! Hey, buddy, get out of the movie! Get out! Go run while you can!"',
      author: 'Tom, #819',
    },
    {
      text: '"Hey! That\'s my mother! ... Still, everything\'s gotta go; bye, Mom!"',
      author: 'Crow, #608',
    },
    {
      text: '"Hey! Your sister wants her shirt back! / I\'ve figured out this kind of shirt: no complicated buttons to deal with!"',
      author: 'Tom/Mike, #706',
    },
    { text: '"Hey, can we stop and wrestle now?"', author: '#503' },
    { text: '"Hey, everyone...free samples of *me*!"', author: 'Mike, #904' },
    {
      text: '"Hey, guys! Are there things that are happening?"',
      author: 'AMC Crow, #913',
    },
    { text: '"Hey, Hitler! We wanna order over here!"', author: 'Crow, #515' },
    {
      text: '"Hey, if she goes up there we can see up her skirt again!"',
      author: 'Crow, #316',
    },
    {
      text: '"Hey, it\'s *my* piece of meat, I\'ll bite it where I want to!"',
      author: 'Crow, #502',
    },
    { text: '"Hey, look, Tab Hunter lives here."', author: 'Mike, #619' },
    { text: '"Hey, Malta, get a tree!"', author: 'Crow, #1008' },
    {
      text: '"Hey, Mike, let\'s get drunk later and go fridge tippin\'!"',
      author: 'Crow, #516',
    },
    {
      text: '"Hey, Mike. Just toying with Servo\'s eternal soul. Pitch is showing me how." "Ok, well, I\'m just drying some fruit if you need me."',
      author: '#818',
    },
    { text: '"Hey, stop feeding my bride."', author: 'Crow, #408' },
    {
      text: '"Hey, the Cheese Phone is ringing." "Yes, Mister Colby?" "Limburger on line two."',
      author: 'Crow/Joel/Tom, #306',
    },
    { text: '"Hey, the Don King State Forest!"', author: 'Tom, #906' },
    {
      text: '"Hey, Timmy, my dad\'s dead again; can I come over for dinner?!"',
      author: 'Crow, #421',
    },
    {
      text: '"Hey, uh, lady? I\'m a nice big pine that you could pray to, maybe. Maybe!..."',
      author: 'Joel, #422',
    },
    {
      text: '"Hey, wait a minute, this isn\'t our star, is it? I will not accept this as our star! Sorry."',
      author: 'Crow, #821',
    },
    {
      text: '"Hey, we got flashlights and a dark space; let\'s make a movie!"',
      author: 'Tom, #1004',
    },
    { text: '"Hey, what up there, Russell Crow?"', author: 'Tom, #907' },
    {
      text: '"Hey, when I said you could borrow some of my stuff, I didn\'t mean my torso!"',
      author: 'Crow. #103',
    },
    {
      text: "\"Hey, who *hasn't* been 'in the hands of the Queen'?\"",
      author: 'Joel. #410',
    },
    {
      text: '"Hey, you know what: I\'ll pick up that Tab!" "I\'d like to run a Tab ... all over my body!" "Hey, whaddaya say we split that Tab?!"',
      author: 'Crow/Tom/Crow, #614',
    },
    {
      text: '"Hey, Zeppelin was right! There is a stairway to heaven!"',
      author: 'Crow, #1001',
    },
    { text: '"Hi! I am Bob Evil!"', author: 'Mike, #821' },
    {
      text: '"Hi! I\'m Moisty, the national spokesman for sweat!"',
      author: 'Tom, #510',
    },
    {
      text: '"Hi, Barney." "Lieutenant. Tom. Doctor." "Honey. / Peepee. / Cookie."',
      author: 'Lieutenant/Barney/Mike/Tom/Crow, #610',
    },
    { text: '"Hi, honey, I\'m smooth!"', author: 'Crow, #504' },
    {
      text: '"Hi, I\'m a chocolate bar. Break off bits of me and enjoy me all day long."',
      author: 'Tom, #410',
    },
    {
      text: '"Hi, I\'m dangerous to myself and others."',
      author: 'Joel, #304',
    },
    {
      text: "\"Hi. I'm intrinsically evil, and I've got a new jogging suit I'm showing off.\"",
      author: 'Joel, #111',
    },
    {
      text: '"Hi. This is the human race. We\'re not in right now; please speak clearly after the sound of the bomb."',
      author: 'Crow. #108',
    },
    {
      text: '"His arteries are just looking at each other, shakin\' their heads."',
      author: 'Crow, #1008',
    },
    { text: '"His fortress of Balditude!"', author: 'Crow, #903' },
    {
      text: '"His Haggar pants have a waistband of repressed memories."',
      author: 'Mike, #810',
    },
    {
      text: '"His head just doesn\'t have the structural support for that hair."',
      author: 'Crow. #510',
    },
    {
      text: '"His Mickey Mouse gloves give him incredible power!"',
      author: 'Tom, #310',
    },
    { text: '"His nipples look like jean rivets."', author: 'Crow, #706' },
    {
      text: '"His only crime was being born delicious!"',
      author: 'Crow. #424',
    },
    {
      text: '"His power apparently lies in his choosing incompetent enemies."',
      author: 'Crow, #816',
    },
    {
      text: '"His underwear and his hat are interchangeable."',
      author: 'Pearl, #913',
    },
    {
      text: '"Hm, souls can press elevator buttons but they can\'t get shot."',
      author: 'Tom, #1001',
    },
    {
      text: '"Hm. Killer grandma. Locked in a barn. Something is not right here."',
      author: 'Mike, #908',
    },
    {
      text: '"Hmmm, nice plane, but it needs more plaid."',
      author: 'Mike, #821',
    },
    { text: '"Hmmm, this lamp has panties on it."', author: 'Joel, #106' },
    { text: '"Hmmm... This potato\'s got big ears..."', author: 'Crow, #303' },
    {
      text: '"Hold it hold it hold it, Sam: got a Teletype ... / Apparently my glasses are dirty."',
      author: 'Leonard Driscoll/Crow, #814',
    },
    {
      text: '"Hold on, I\'m having a series of elaborate heart attacks!"',
      author: 'Crow, #910',
    },
    { text: '"Home Alone 3: The Quickening."', author: 'Mike. #521' },
    {
      text: '"Home movies are more tightly edited than this!"',
      author: 'Tom, #706',
    },
    {
      text: '"Honeee...my face is as big as ever and someone shot my sizzler off..."',
      author: 'Crow, #702',
    },
    {
      text: '"Honestly, the two them are just like children! / Children love to hang drapes."',
      author: 'Mrs. Snow/Crow, #912',
    },
    { text: '"Honey Bunches of *Death*!!"', author: 'Tom, #902' },
    {
      text: '"Honey, we\'re at grandma\'s; put your shoes on."',
      author: 'Joel, #308',
    },
    {
      text: '"Honey, why can\'t you just *once* let me take over the world?"',
      author: 'Joel, #311',
    },
    {
      text: '"Honey, why do we have an oven in the living room?"',
      author: 'Joel, #506',
    },
    {
      text: '"Honey, your cocaine is all over the phone."',
      author: 'Crow, #701',
    },
    { text: '"Honk if you love Eegah!"', author: '#506' },
    { text: '"Hooray for socks."', author: 'Crow, #822' },
    {
      text: '"Hooray for the seventies! / John Cassidy for president! / We want Billy Beer!"',
      author: 'Mike/Crow/Tom, #811',
    },
    {
      text: '"Hooray! The movie\'s closed today because of snow!"',
      author: 'Crow, #910',
    },
    {
      text: '"Hope you didn\'t get any of that tattooed anyplace important."',
      author: 'Joel. #104',
    },
    {
      text: '"HOT MERGING ACTION!" "Oh, goodness: they merged successfully! My heart was in my throat."',
      author: 'Crow/Joel, #512',
    },
    { text: '"How about some fresh sliced *me*?"', author: 'Crow, #601' },
    {
      text: '"How about that guy I groined in the knee?"',
      author: 'Tom, #509',
    },
    { text: '"How about you bite me twice?"', author: 'Mike, #516' },
    {
      text: '"How can you have any meat if ya don\'t eat ya pudding?!"',
      author: 'Mike, #705',
    },
    {
      text: '"How come they all turned when he said \'Dad\'?"',
      author: 'Crow. #406',
    },
    {
      text: "\"'How do animals learn?' Well, as long as they learn to taste good I don't really care.\"",
      author: 'Crow, #906',
    },
    { text: '"How do pop tarts work?"', author: 'Joel, #317' },
    {
      text: '"How do we know when we\'ve done it?" "Oh boy, back to the dolls."',
      author: 'Larry/Crow, #616',
    },
    {
      text: '"How good can you really be at maps?" "It\'s like being good at eating cereal."',
      author: 'Mike/Crow, #913',
    },
    {
      text: '"How much Keefe is in this movie anyway?..."',
      author: 'Joel. #301',
    },
    { text: '"How was your day at the WHORE?!"', author: 'Crow, #810' },
    { text: '"Howard Stern in West Side Story."', author: 'Tom, #705' },
    {
      text: '"How\'d ya like it if we picked *your* apples?"',
      author: 'Joel, #416',
    },
    {
      text: '"How\'s your buddy?" "Kinda personal, isn\'t it?"',
      author: 'Paisley/Crow. #202',
    },
    {
      text: '"How\'s your supply of oxygen?" "Enough for about fifty minutes! / Wait! Wait! I found some more oxygen in a drawer; we\'re ok!"',
      author: '3rd Defense Army Commander/Henry/Crow, #316',
    },
    {
      text: '"Huge lamps of the seventies: tonight on Biography."',
      author: 'Mike, #811',
    },
    { text: '"Hugo\'s got a John Agar smile."', author: 'Mike. #818' },
    {
      text: '"Huh. Must be Christmas on the Borg ship."',
      author: 'Crow. #822',
    },
    {
      text: '"Hurry back, Bobo: there\'s a great fight on Jerry Springer!"',
      author: 'Brain Guy, #905',
    },
    { text: '"I *am* the button!"', author: "TV's Frank. #410" },
    {
      text: '"I *have* performed surgery once before, and although the patient didn\'t survive I feel quite confident."',
      author: 'Joel, #413',
    },
    { text: '"I accuse her hair!"', author: 'Crow. #507' },
    {
      text: '"I am Dictator of Krankor! I assure you that the word \'impossible\' is a word which, for me, does not exist! / Although I just said it."',
      author: 'Krankor/Crow, #816',
    },
    {
      text: '"I am Doctor Clayton Forrester, and I\'m going to hit TV\'s Frank because of my deep resentment at my own limitations."',
      author: 'Crow, #518',
    },
    {
      text: '"I am going to have to make so many phone calls to get my soul back."',
      author: 'Crow, #908',
    },
    { text: '"I am HOT! I turn *myself* on!"', author: 'Tom, #317' },
    { text: '"I AM IRONMAN!!!"', author: 'Joel. #401' },
    { text: '"I am Nimrod from the future!"', author: 'Mike, #806' },
    { text: '"I am Servotron, destroyer of worlds!"', author: 'Tom, #213' },
    {
      text: '"I am the atomic powered robot. Please give my best wishes to everybody!"',
      author: 'Minsky the Robot. #504',
    },
    { text: '"I am the Lemon Zester of Destruction!"', author: 'Crow, #816' },
    {
      text: "\"I bet the phrase, 'Where's my cocaine?' was heard a lot on this film.\"",
      author: 'Crow, #622',
    },
    {
      text: '"I betcha he smells like Jack Del Rio\'s jock strap."',
      author: 'Tom. #619',
    },
    { text: '"I call it, \'Bowling Ball in Repose.\'"', author: 'Mike, #806' },
    {
      text: '"I can drive that house across the border!"',
      author: 'Tom, #815',
    },
    {
      text: '"I can really see why Quebec wants to be separate from all this."',
      author: 'Crow, #910',
    },
    {
      text: '"I can\'t believe how really in London we are."',
      author: 'Crow. #416',
    },
    {
      text: "\"I can't hear what they're saying, and frankly I don't care.\"",
      author: 'Crow, #811',
    },
    {
      text: '"I can\'t imagine why Mitchell II never came out."',
      author: 'Crow, #512',
    },
    {
      text: '"I can\'t kill the man I love!" "Then kill the one you\'re with!"',
      author: 'Rita/Crow, #310',
    },
    {
      text: '"I crap bigger than this movie..."',
      author: 'Crow (as Jack Palance). #519',
    },
    {
      text: '"I didn\'t know they had plywood on the moon."',
      author: 'Crow. #103',
    },
    {
      text: '"I do not know what we are. Perhaps we are gauchos. Ole!"',
      author: '#503',
    },
    {
      text: "\"I don't care if I'm too old; I'm getting my Batman pajamas back out of here.\"",
      author: 'Mike, #910',
    },
    { text: '"I don\'t even know how fish work!"', author: 'Joel, #407' },
    {
      text: '"I don\'t get it. Is it cool to make no sense, Joel? Is it hip to be vague?"',
      author: 'Crow. #107',
    },
    {
      text: '"I don\'t have a precision mind like yours! / I\'m only a scientist!"',
      author: 'Scientist/Crow, #421',
    },
    {
      text: '"I don\'t know *what* to believe!" "Try believing in ESL for a while!"',
      author: 'Natalie/Tom, #904',
    },
    {
      text: '"I don\'t know ... maybe I will leave the Bronx."',
      author: 'Crow, #705',
    },
    {
      text: '"I don\'t know how but I just became sterile."',
      author: 'Tom, #810',
    },
    {
      text: '"I don\'t mean to rush you, ma\'am, but is this death going to take long?"',
      author: 'Tom, #522',
    },
    {
      text: '"I don\'t need to see every part of the human anatomy outlined in Spandex! I can trust it\'s all there!"',
      author: 'Tom, #907',
    },
    {
      text: '"I don\'t pay for your hookers, Mitchell." "Well, could ya?"',
      author: 'James Arthur Cummings/Joel, #512',
    },
    {
      text: "\"I don't suppose there's any chance this guy's going to end up under the wheels of a train, is there?\"",
      author: 'Tom, #321',
    },
    {
      text: "\"I don't think I'll ever get callous to murder. / Now where's the stiff?\"",
      author: 'Lt. Lewis/Mike, #603',
    },
    {
      text: '"I don\'t wanna go to no party." "Come on! I\'m cookin\' a pizza on the engine block!"',
      author: 'Billy/Crow, #706',
    },
    {
      text: "\"'I don't want to die, I don't want to die'... Look, I got problems too!!\"",
      author: 'Crow. #504',
    },
    {
      text: '"I dunno, some planet, I think. Supposed to be good for you..."',
      author: "'Glen Manning'. #309",
    },
    { text: '"I feel European upon seeing that."', author: 'Mike, #911' },
    {
      text: "\"I found a bunch of balloons in the men's room vending machine, so I bought us a whole bunch. And look, they're individually wrapped! Well, this one even protects us from something! Oh, and this one is from France, and it's supposed to tickle us! Oh, and this one, this is a red balloon that's supposed to make some lady happy, and I really don't understand...\"",
      author: 'Bobo, #1011',
    },
    { text: '"I give not a crap for thee."', author: 'Tom, #416' },
    {
      text: '"I give you ... Michaelangelo\'s David!" "Wow, Crow! Bigger than the original! And out of four ice cubes?" "Ah, I\'m just efficient, I guess."',
      author: 'Crow/Tom, #911',
    },
    {
      text: '"I got bit by a rattlesnake in a very embarrassing place.  Had to suck the poison out of myself!" "I\'ll never eat again."',
      author: 'Bobo/Pearl, #810',
    },
    {
      text: '"I guess a rocket *is* standard picnicking equipment in Japan..."',
      author: '#212',
    },
    { text: '"I hate being badgered by pharmacists!"', author: 'Crow, #814' },
    {
      text: '"I hate it when Aztecs force themselves into your hotel room and make you try on belts!"',
      author: 'Tom, #903',
    },
    {
      text: '"I hate movies where the men wear shorter skirts than the women."',
      author: 'Crow, #410',
    },
    {
      text: '"I hate to be picky, but pumas aren\'t really known for flying."',
      author: 'Mike, #903',
    },
    { text: '"I have a cat poster!"', author: 'Tom, #703' },
    {
      text: '"I have a communication for you. / \'Bite me!\'"',
      author: 'Novicorp Chairman/Mike, #822',
    },
    {
      text: '"I have a feeling Satan would regret making this movie."',
      author: 'Mike, #515',
    },
    {
      text: '"I have never piloted swim trunks before."',
      author: 'Tom Servo, #404',
    },
    {
      text: '"I have no clue what you\'re talking about, but I\'m profoundly devastated."',
      author: 'Tom, #515',
    },
    {
      text: '"I have no powers, but I can skip reasonably well!"',
      author: 'Crow, #816',
    },
    {
      text: '"I have the feeling *Satan* would regret making this movie."',
      author: 'Mike. #515',
    },
    {
      text: '"I have the feeling one of these characters is about to see their own intestines."',
      author: '#520',
    },
    { text: '"I have to go shower now!"', author: 'Crow, #622' },
    {
      text: '"I have waited until your daughter reached that age, so that my dragon could relish the flesh of the princess." "Oh, wowwww..."',
      author: 'Basil Rathbone/Joel. #411',
    },
    {
      text: '"I hear his theme music, he\'s around here somewhere."',
      author: '#322',
    },
    {
      text: '"I hope I look all right for my meeting with the Sun God..."',
      author: 'Crow, #516',
    },
    { text: '"I hope those are stunt tongues."', author: 'Crow, #622' },
    {
      text: '"I hope we\'ve touched you with a little bit of our evilness."',
      author: 'Crow, #307',
    },
    {
      text: '"I just disinfestated the Bronx; I\'m goin\' to Disney Land!"',
      author: 'Crow, #705',
    },
    {
      text: '"\'I just have one question: where do Crow, Tom Servo, and Gypsy sleep?\'" "Wherever the hell we want!"',
      author: 'Mike/Crow, #705',
    },
    { text: '"I just heard an eagle meow..."', author: 'Tom, #703' },
    { text: '"I just really, really miss Toblerone!"', author: 'Crow, #705' },
    {
      text: '"I just saved your immortal soul!" "But I could have saved a lot more in my doll budget!"',
      author: 'Mike/Crow, #818',
    },
    { text: '"I just saw half a crab kill a guy..."', author: 'Joel, #213' },
    {
      text: '"I just sent my whole allowance to Sally Struthers!" "Oh, to sponsor a child." "No, for her. I just feel sorry for her." "Sure, we all do!"',
      author: 'Tom/Joel, #502',
    },
    {
      text: '"I just thought I\'d take my morning constitutional." "Ewww! Here on the beach??"',
      author: 'Mr. Duvall/Tom, #204',
    },
    {
      text: '"I just want to be Bavarian for one brief, shining moment! Is that so wrong?!"',
      author: "TV's Frank. #508",
    },
    {
      text: '"I just want to know if the Lord said it this many times in a row."',
      author: 'Joel. #402',
    },
    { text: '"I KILLED THAT FAT BARKEEP!!"', author: 'Mooney. #415' },
    {
      text: '"I know all about your Luberia." "Hey! That cleared up."',
      author: 'Morgiana/Crow, #505',
    },
    { text: '"I know how toast works..."', author: 'Tom, #507' },
    {
      text: '"I know I\'m supposed to feel something but I don\'t."',
      author: 'Crow, #612',
    },
    {
      text: '"I know what to do! I think we should ask Gamera to save us!" "Great ... Another preschooler saves Japan."',
      author: 'Kenny/Joel, #316',
    },
    {
      text: "\"I know what you're thinking. / 'I'm boring and my slide show eats.'\"",
      author: 'Johnny Longbow/Tom, #1007',
    },
    {
      text: '"I know what you\'re thinking: do I fire flames 600 feet or just 5?"',
      author: 'Joel (as Godzilla), #212',
    },
    {
      text: '"I leave for twenty minutes and Evil Co is in shambles!"',
      author: 'Tom, #821',
    },
    {
      text: '"I like him in spite of the fact that he\'s not me."',
      author: 'Tom, #814',
    },
    {
      text: '"I like the color of your eyes. / The rest of you is hideous. Bye!"',
      author: 'Stella/Tom, #911',
    },
    {
      text: '"I like the smell of fresh wood chips and sawdust. / I put them in my underwear."',
      author: 'Narrator/Tom, #609',
    },
    {
      text: '"I like you, Gramps. That\'s why I\'ll kill you last."',
      author: 'Crow, #404',
    },
    {
      text: '"I love cigs.  I gotta get a carton of these for my pregnant wife."',
      author: 'Crow, #702',
    },
    {
      text: '"I love John Banner!" "Because he loves me!"',
      author: 'Crow/Tom, #417',
    },
    {
      text: '"I love Ken, he\'s my sweet sweet friend and I looooove him..."',
      author: 'Crow, #318',
    },
    { text: '"I love my dead Greek son!"', author: 'Tom, #502' },
    {
      text: '"I love you, Tom Servo!" "I love you, Joel!" "I love you, Crow!" "You\'re not my real father!"',
      author: '#303',
    },
    {
      text: '"I made you some trail mix out of rat droppings and hair."',
      author: 'Mike, #910',
    },
    {
      text: '"I may be color blind, but it\'s plain to see I\'m wearing a dress."',
      author: 'Tom, #413',
    },
    {
      text: '"I must be brutally frank with you... / Jesus told me he hates you."',
      author: 'Talent Agent/Tom, #613',
    },
    {
      text: '"I must be tired; I\'m almost interested in what you\'re talking about."',
      author: 'Joel, #201',
    },
    {
      text: '"I never realized the Russian army would have chaperones."',
      author: 'Tom, #602',
    },
    {
      text: '"I only peeped six hours, but I\'m gonna put eight on my time card."',
      author: 'Tom, #805',
    },
    {
      text: '"I predict that in the climactic finale, she jumps without a chute, and then her *hair* opens up."',
      author: 'Mike. #609',
    },
    {
      text: '"I put deuterium in a frog, too: it was great."',
      author: 'Tom, #814',
    },
    {
      text: '"I really think there\'s more nothing in this movie than in any movie we\'ve seen."',
      author: 'Mike, #612',
    },
    { text: '"I saw Gamera kissing Santa Claus."', author: 'Joel, #312' },
    { text: '"I saw it with my own hair!"', author: '#418' },
    {
      text: '"I see some really stupid children being born as a result of these two meeting."',
      author: 'Tom, #904',
    },
    {
      text: '"I see you\'ve decided to go psycho. Godspeed."',
      author: 'Dr. Forrester. #516',
    },
    {
      text: '"I sensed that with my hair. My hair is an elaborate network of nerves, constantly processing information."',
      author: 'Tom, #910',
    },
    { text: '"I should get my extra ear flap removed."', author: 'Mike, #818' },
    { text: '"I shun you version 3.0 for Windows!"', author: 'Tom, #515' },
    {
      text: '"I sure hope I don\'t park on any broken tequila bottles!"',
      author: 'Crow, #815',
    },
    {
      text: '"I tell ya, this\'d really be exciting if I knew what was going on."',
      author: 'Tom, #412',
    },
    { text: '"I think I had her in the eighth grade."', author: 'Crow. #508' },
    {
      text: "\"I think I'll combine a 'lick' and a 'me' and ship it right over to ya!\"",
      author: 'Tom, #911',
    },
    {
      text: '"I think I\'m getting allergic to Tom. / Yeah, join the club, Gypsy."',
      author: 'Gypsy/Mike, #606',
    },
    {
      text: '"I think it\'s a strapless evening gown." "You think *everything* is a strapless evening gown!"',
      author: 'Tom/Crow. #422',
    },
    {
      text: '"I think my body\'s rejecting this movie."',
      author: 'Mike, #905',
    },
    {
      text: '"I think they were going for a Touch Of Evil feel, but they got a touch of somethin\' else ..."',
      author: 'Crow, #706',
    },
    {
      text: '"I think they\'re trying to pass off a summer shower as an action sequence."',
      author: 'Tom, #819',
    },
    {
      text: "\"I think we oughtta take care of Caroline's wound. / She doesn't have one! ... Well, let's give her one!\"",
      author: 'Godo/Crow, #306',
    },
    {
      text: '"I think we\'re at the point where something\'s got to happen."',
      author: 'Tom, #612',
    },
    {
      text: "\"I think we've all 'reported to the moon' at one time or another.\"",
      author: 'Tom, #107',
    },
    { text: '"I thought it had no texture."', author: "TV's Frank. #309" },
    {
      text: '"I thought it was a sheer delight! I think that\'s about as grand as a hat party can get!  *BZZZZT* That was about as painful as a cattle prod to the shoulder can get."',
      author: "'Jack Perkins'. #310",
    },
    {
      text: '"I thought of a secret Santa gift for these people, Mike: the book How to Hide a Murder Weapon."',
      author: 'Crow, #908',
    },
    {
      text: '"I thought the robot with the human head was really offensive."',
      author: '#102',
    },
    { text: '"I thought we just sold each other fish!"', author: 'Crow, #505' },
    {
      text: '"I tried to be a lone wolf ... / And now I\'m a dead duck."',
      author: 'Paul/Tom, #106',
    },
    { text: '"I understand now. Gallagher *is* funny."', author: 'Joel, #311' },
    {
      text: '"I want to decide who lives and who dies!"',
      author: 'Crow, #321',
    },
    {
      text: '"I want to hurt this movie, but I can never hurt it the way it hurt me."',
      author: 'Crow, #619',
    },
    {
      text: '"I want you to dance in Joanie\'s place. / Chachi won\'t let her dance anymore."',
      author: 'Leo/Mike, #1002',
    },
    {
      text: '"I wanted to play hopscotch with the impenentrable mystery of existence, but he stepped in a wormhole and had to go in early."',
      author: 'Crow. #203',
    },
    {
      text: '"I was checking out your cleavage from behind."',
      author: 'Crow, #913',
    },
    {
      text: '"I was thinking maybe I could just *marry into* posture."',
      author: 'Crow. #416',
    },
    {
      text: '"I was working at a moon base. I was riding on a moon jeep. / I was eatin\' a Moon Pie. I had just mooned a passing moon jeep."',
      author: 'Laura Lee Warren/Crow, #316',
    },
    {
      text: '"I went and liberated France while you were dancing."',
      author: 'Crow, #507',
    },
    { text: '"I will bring you down, Johnny!"', author: 'Mike, #515' },
    {
      text: '"I will now open the floor to questions about my incompetence."',
      author: 'Tom, #809',
    },
    {
      text: '"I will now perform a modern dance to express my true feelings."',
      author: 'Crow, #413',
    },
    {
      text: '"I wish I had a castle in the sky..." "Yeah, well, wish in one hand and crap in the other, and see which piles up first!"',
      author: 'Lady/Mike, #701',
    },
    {
      text: '"I wonder if I could sunbathe nude up here."',
      author: 'Crow, #622',
    },
    {
      text: '"I wonder if the Natalie\'s Bath Channel is on."',
      author: 'Crow, #1001',
    },
    {
      text: '"I wonder if there\'s an abridged version of this movie."',
      author: 'Tom, #106',
    },
    { text: '"I wonder what I\'m thinking about."', author: 'Crow, #502' },
    {
      text: "\"I won't try to make you a bad woman if you stop trying to make me a good man. / That's a country song, isn't it?\"",
      author: 'Kay Miro/Tom, #511',
    },
    {
      text: '"I work for the government. / That\'s why I drive a black helicopter."',
      author: 'Sam Casey/Tom, #814',
    },
    {
      text: '"I worked at NASA back when we were next to Cost Cutters."',
      author: 'Tom, #420',
    },
    {
      text: '"I would have taken my head, and made a hat..."',
      author: 'Gypsy. #309',
    },
    {
      text: '"I\'d certainly like a chance to examine that dummy... / So if you could look away for a moment..."',
      author: 'Mark/Mike, #818',
    },
    {
      text: '"I\'d like to announce the engagement of me and Johnnie Walker!"',
      author: 'Mike, #802',
    },
    {
      text: '"I\'d like to be the Chicken of Tomorrow, but how can I be more the Man of Today?"',
      author: 'Mike, #702',
    },
    {
      text: '"I\'d like to drive Megaweapon to school."',
      author: 'Crow, #501',
    },
    {
      text: '"I\'d like to say a few words: Um, this guy\'s dead. The end. Thank you."',
      author: 'Crow, #810',
    },
    {
      text: '"I\'d like to see you again, lots of times. And then stop abruptly, breaking your heart like a toy."',
      author: 'Joel, #112',
    },
    {
      text: '"I\'d like to thank the fine people of Seatopia for giving me these totally useless arms!"',
      author: 'Joel, #212',
    },
    {
      text: '"I\'d like you to meet a friend of mine." "Um, could you at least kiss me first?"',
      author: 'Paul Carlsen/Crow, #1007',
    },
    {
      text: '"I\'d never wanted to die in a plane, but now I\'m very excited about it."',
      author: 'Crow, #208',
    },
    {
      text: '"I\'d put football pads on and just go up and down the street diving through plate glass windows!"',
      author: 'Crow. #501',
    },
    {
      text: '"I\'d rather share a needle with Keith Richards."',
      author: 'Tom, #310',
    },
    {
      text: '"I\'d rather spend a weekend in Robert Bork\'s underpants than watch more of this!"',
      author: 'Crow, #622',
    },
    {
      text: '"I\'d really like to see you rub your forearms together."',
      author: 'Crow, #517',
    },
    {
      text: '"I\'d say Mrs. Butterworth is going to have a lot of explaining to do..."',
      author: 'Tom, #211',
    },
    { text: '"I\'d slap this movie if I could."', author: 'Tom, #516' },
    {
      text: '"If a driver is killed, section 31B gets a free Hormel hot dog!"',
      author: 'Tom, #814',
    },
    {
      text: '"If I catch his death on tape I can send it to FOX."',
      author: 'Tom, #1007',
    },
    {
      text: '"If I die, I\'m gonna die eating string cheese and Frutopia!"',
      author: 'Tom, #1001',
    },
    {
      text: '"If I need another heart what am I supposed to do? / I blew all my Biography money; went straight up my nose."',
      author: 'Peter Graves/Tom, #811',
    },
    { text: '"If Jack Ruby owned a Denny\'s..."', author: 'Mike. #513' },
    {
      text: '"If my deepest, darkest despair had choreography, this would be it."',
      author: 'Tom, #606',
    },
    {
      text: '"If not for alcohol there\'d be no moisture in that woman at all."',
      author: 'Tom, #818',
    },
    {
      text: '"If not for rock and roll, ventriloquy would have been huge."',
      author: 'Mike, #818',
    },
    {
      text: '"If only an androgynous man would come and visit meeeee..."',
      author: 'Joel, #319',
    },
    { text: '"If that\'s God, I\'m quitting."', author: 'Crow, #501' },
    {
      text: '"If these people are beatniks then my *mom* is a beatnik and, she\'s not."',
      author: 'Joel, #415',
    },
    {
      text: '"If this is all the gods can do I\'m over to the dark side so fast!"',
      author: 'Tom, #903',
    },
    {
      text: "\"If we don't die, you want to catch a movie or something? I mean, if you're not alive I'll totally understand, it won't hurt my feelings or anything...\"",
      author: 'Joel, #111',
    },
    {
      text: '"If we don\'t get a volunteer, yer all gonna have to see my can!"',
      author: 'Crow, #818',
    },
    {
      text: '"If you could see my hands right now, you\'d be horrified."',
      author: 'Tom, #517',
    },
    {
      text: '"If you drag out our rotting, broken carcasses, it means it didn\'t really work out very well."',
      author: 'Tom, #108',
    },
    { text: '"If you find a melody, Sinbad, hop on..."', author: 'Joel. #505' },
    {
      text: '"If you get near a consonant, let us know."',
      author: 'Tom, #416',
    },
    {
      text: '"If you listen closely you can hear them beating the crap out of Pernell Roberts."',
      author: 'Mike, #614',
    },
    {
      text: '"If you lived here you\'d be strafed by now."',
      author: 'Crow, #819',
    },
    {
      text: '"If you lived here, you\'d be in hell by now."',
      author: 'Mike, #910',
    },
    {
      text: '"If you see only 10,000 movies this year make sure this isn\'t one of them!"',
      author: 'Tom, #810',
    },
    {
      text: '"If you want, I can turn him into furniture."',
      author: 'Mike, #515',
    },
    {
      text: '"If you\'re looking for plausibility, you won\'t find it here."',
      author: 'Joel. #210',
    },
    {
      text: "\"If, earlier, he had said, 'I'll never be impaled on my own rack', *then* this would have been ironic. But...\"",
      author: 'Mike. #607',
    },
    {
      text: '"I\'ll be fine as soon as I scrape my butt off the ceiling..."',
      author: 'Crow, #211',
    },
    {
      text: '"I\'ll be with you in a moment, I\'m just sealing some fates."',
      author: 'Crow (as God). #210',
    },
    {
      text: '"I\'ll disguise myself as a box! Must look corrugated and square."',
      author: 'Crow, #1004',
    },
    {
      text: "\"I'll drink to that! / But I'll drink to lint, though, so it's nothing special.\"",
      author: 'Security Guard/Crow, #904',
    },
    {
      text: "\"I'll explain it to you again. / You're a loser, and we're sending you away. / It's just that, sometimes, married people... / Break up because of their kids. / ...Simply have to live apart, for awhile. / Apart from their kids, I mean.\" \"You're gonna get a divorce.  \"Yes, and I don't want custody.\"",
      author: '#614',
    },
    {
      text: '"I\'ll give you ten dollars every time you don\'t hit me."',
      author: 'Crow, #509',
    },
    {
      text: '"I\'ll keep the fire going. / I\'ll just break off a pound of my hair at a time and burn it."',
      author: 'Arch Hall Jr./Tom, #506',
    },
    {
      text: "\"I'll never forget you, Trumpy. / ...The way you callously knocked off 15 people... I'll never forget that; don't ever change, Trumpy...\"",
      author: 'Tommy/Tom, #303',
    },
    {
      text: "\"I'll never grow old. I'll never die. And I'll always eat oatmeal.\"",
      author: 'Tom, #512',
    },
    {
      text: '"I\'ll show you a race. / Just let me get in my dad\'s electric supply van."',
      author: 'Billy/Crow, #706',
    },
    {
      text: '"I\'ll sneak out of here disguised as Chad Everett." "That always works."',
      author: 'Joel/Tom (as Ken), #310',
    },
    {
      text: '"I\'ll stay here and supervise the zombie."',
      author: 'Tom, #518',
    },
    {
      text: '"I\'ll take that dull, alive woman every time. / I guess the other way would be kind of sick, wouldn\'t it?"',
      author: 'Captain Sherman/Tom, #407',
    },
    { text: '"I\'ll walk back to the past."', author: 'Tom, #821' },
    { text: '"I\'m *mental*, not *stupid*!"', author: 'Tom, #601' },
    {
      text: '"I\'m a practical woman, Dr. Frank. Now: Lop my head off!"',
      author: 'Crow, #518',
    },
    {
      text: "\"I'm a pretty good judge of people who aren't going to leave the Bronx, and this guy is one of 'em.\"",
      author: 'Crow, #705',
    },
    {
      text: '"I\'m a representative of Aztechnologies."',
      author: 'Mike, #903',
    },
    { text: '"I\'m a stranger with parasites."', author: 'Tom, #210' },
    { text: '"I\'m cookin\' with Crisco now!..."', author: 'Tom, #112' },
    {
      text: '"I\'m doing this for Jody Foster. It\'s nothing personal."',
      author: 'Tom, #311',
    },
    {
      text: '"I\'m equipped with shifting antigens, so don\'t even try to find a cure!"',
      author: 'Tom, #420',
    },
    {
      text: '"I\'m glad I chose kickin\' butt as a career."',
      author: 'Tom, #410',
    },
    { text: '"I\'m going to call it Planet Me."', author: 'Joel, #416' },
    {
      text: '"I\'m going to go forage for tighter pants."',
      author: 'Crow, #1007',
    },
    {
      text: '"I\'m gonna drink the hell outta this Coke!"',
      author: 'Tom, #706',
    },
    { text: '"I\'m gonna facilitate connectivity."', author: 'Crow, #821' },
    {
      text: '"I\'m gonna grow an ugly face and KILL YOU."',
      author: 'Crow, #702',
    },
    {
      text: '"I\'m gonna make you a part of history." "Is that a sexual overture of some kind?"',
      author: 'J.K. Robertson/Crow, #821',
    },
    {
      text: "\"I'm gonna moon you, man, I'm gonna MOON you!!\" \"Well, that's gonna be tough, 'cause you really don't have a hinder to speak of.\"",
      author: 'Crow/Tom, #415',
    },
    { text: '"I\'m here for the Gumby wedding."', author: 'Crow, #111' },
    {
      text: '"I\'m here to eat candy canes and kick ass, and I\'m all out of candy canes!"',
      author: 'Krusher Kringle. #521',
    },
    { text: '"I\'m huge!"', author: 'Joel, #207' },
    { text: '"I\'m in it for the kicks!"', author: 'Gypsy. #207' },
    {
      text: '"I\'m just gonna look away until the funny part\'s over."',
      author: 'Crow, #622',
    },
    {
      text: '"I\'m kinda having a bad day.  I\'m better now, though; Crow bit me again!"',
      author: 'Mike, #806',
    },
    {
      text: '"I\'m learning a lot more about this guy\'s nasal hair than I ever wanted to know."',
      author: 'Joel. #323',
    },
    { text: '"I\'m LONG!"', author: 'Crow, #408' },
    {
      text: '"I\'m no good with sick people. What am I even going to say to my body?"',
      author: 'Crow, #1001',
    },
    {
      text: '"I\'m not a burglar. / I\'m a hot dog."',
      author: 'Rondo/Tom, #702',
    },
    {
      text: '"I\'m not holding back anything!" "Certainly talent."',
      author: 'Natalie/Crow. #904',
    },
    {
      text: "\"I'm not interested in what's fair. I'm only interested in things that make my head look bigger.\"",
      author: 'Joel, #112',
    },
    {
      text: '"I\'m not worried about it; my name\'s in the title."',
      author: 'Joel, #505',
    },
    { text: '"I\'m pleased at things."', author: 'Mike, #911' },
    {
      text: '"I\'m relative humidity. It\'s not so much the heat as it is me."',
      author: 'Tom, #422',
    },
    {
      text: '"I\'m so concerned I felt compelled to touch you."',
      author: 'Joel, #104',
    },
    {
      text: '"I\'m so upset that I\'ll binge on a Saltine."',
      author: 'Crow, #812',
    },
    {
      text: '"I\'m sorry that I dropped you, that I accidentally plopped you, when I held your brain in my arms..."',
      author: 'Observers, #807',
    },
    {
      text: '"I\'m sorry to hear you say that. / You have a really annoying voice."',
      author: 'Valmont/Crow, #1013',
    },
    {
      text: "\"I'm sorry. You're right. I really was selfish to not have rabies. I'll tell you what...go ahead and finish the series of painful injections.\"",
      author: 'Mike, #1010',
    },
    { text: '"I\'m squishy, and I need to move on it!"', author: 'Mike, #610' },
    {
      text: "\"I'm tempted to call her something that rhymes with 'bitch'!\"",
      author: 'Gypsy. #607',
    },
    { text: '"I\'m the god, I\'M THE GOD!!!"', author: 'Dr. Forrester, #M' },
    {
      text: '"I\'m thinking this and Roddy McDowall ate up about ninety percent of the film\'s budget."',
      author: 'Tom, #706',
    },
    {
      text: '"I\'m torn between mourning my boyfriend and subscribing to Marxist dogma."',
      author: 'Tom Servo, #602',
    },
    { text: '"I\'m young and free and feeling fresh!"', author: 'Tom, #506' },
    {
      text: '"Imagine an intelligent girl like her going for a common sea tramp like him." "Imagine in how much detail, senior?"',
      author: 'Jerry Farrell/Tom, #407',
    },
    {
      text: '"Impulse buying is primarily..." "...Women\'s fault."',
      author: 'Narrator/Mike, #603',
    },
    {
      text: '"In a few short hours from now we shall takeover your world! / That is all. Hold on, wait ... oh, Ted wants me to call you a bunch of wieners."',
      author: 'Neptunian Ultimatum/Mike, #819',
    },
    {
      text: "\"In addition, you obtain another dollar or so in what you call 'tips'. / Or, 'protection'.\"",
      author: 'Ben Franklin/Crow, #621',
    },
    {
      text: '"In an unforeseen tragedy, the two actors were not in the car at the time of the crash. / DAMN!"',
      author: 'Crow/Tom, #907',
    },
    {
      text: '"In the 50s, people responded well to authoritative disembodied voices."',
      author: 'Mike, #613',
    },
    {
      text: '"In the future, puerile ruses will still work."',
      author: 'Crow, #822',
    },
    {
      text: '"In the name of all that is good and decent, gentlemen, put on your penguin costumes!"',
      author: 'Observer, #912',
    },
    {
      text: '"In the time it takes him to fall the government in Italy changes three times."',
      author: 'Crow, #1008',
    },
    {
      text: '"In Wisconsin, that roof would say \'Diesel Cheese Snacks\'."',
      author: 'Tom. #611',
    },
    {
      text: '"Intersect...Making Lines Meet for over 50 Years."',
      author: 'Tom. #814',
    },
    {
      text: '"Is science supposed to smell like banana creme?"',
      author: "TV's Frank. #601",
    },
    {
      text: '"Is that car in your area?" "I beg your pardon!!"',
      author: 'Police Dispatcher/Crow, #517',
    },
    { text: '"Is that stud coming?"', author: 'Valmont, #1013' },
    {
      text: '"Is there another wise man we could talk to?"',
      author: 'Crow, #422',
    },
    {
      text: "\"Is there such a thing as 'Starring Ben Murphy'? Isn't it more honest just to say that most of the time the camera's pointed at Ben Murphy?\"",
      author: 'Mike, #814',
    },
    {
      text: '"Is there water at the bottom of the ocean? I guarantee it. Do I guarantee it? I guarantee it."',
      author: 'Crow, #806',
    },
    {
      text: '"Is this gonna be another case of a scientist declaring martial law?"',
      author: 'Mike, #810',
    },
    {
      text: '"Is this one of those movies based on a Jane Austen novel?"',
      author: 'Tom, #706',
    },
    {
      text: '"Is this the induction film when you go to Hell?"',
      author: 'Crow, #616',
    },
    {
      text: '"Is this the man?"  "No sir, you da man."',
      author: 'Kalgan/Mike, #820',
    },
    {
      text: '"Is your face odd? Misshapen? Join the Air Force!"',
      author: 'Mike, #612',
    },
    { text: '"Is your knob turned to Bob?"', author: 'Tom. #623' },
    {
      text: '"Isn\'t it against the rules to shoot an opponent in a foot race?"',
      author: 'Crow, #512',
    },
    {
      text: '"Isn\'t that Martin Sheen\'s son?" "Hey, who isn\'t?"',
      author: 'Joe/Crow, #303',
    },
    {
      text: '"It gives me the creeps having that big lamp staring at us. / I hate the prying eyes of furniture."',
      author: 'Meg/Tom, #414',
    },
    {
      text: '"It has been said that one cannot swing one\'s dead cat without hitting an item that has been advanced by the space program."',
      author: 'Tom, #401',
    },
    {
      text: '"It isn\'t broccoli that I want; it\'s you!"',
      author: 'Gypsy, #905',
    },
    {
      text: '"It might have been nice to show that scene with the monster, but use your imagination: it was true horror!"',
      author: 'Crow, #421',
    },
    {
      text: "\"It reads, 'Welcome Neptunians, please don't blow us up,' [building explodes] well, there you go.\"",
      author: 'Tom, #819',
    },
    { text: '"It says \'Bureau of Conformity.\'"', author: 'Crow, #819' },
    {
      text: '"It says that one time this big lobster came and attacked a lady, but Mr. Ed saved her."',
      author: 'Tom, #412',
    },
    {
      text: '"It seems like a nice night, but I do not know, for I am mad."',
      author: 'Crow, #806',
    },
    { text: '"It sounds like the soundtrack is drunk."', author: '#409' },
    { text: '"It stinks!"', author: 'Rick. #303' },
    {
      text: '"It took you two minutes to hook up and that\'s two minutes too long! / It should have been none minutes!"',
      author: 'Major Stevens/Mike, #612',
    },
    {
      text: '"It turns out that the aliens are a big bunch of pussies."',
      author: 'Mike. #819',
    },
    {
      text: '"It used to be a full time thing, now we just conquer men for charity. It\'s real, real fun."',
      author: 'Carol the Amazon, #502',
    },
    {
      text: '"It was a lot easier to entertain in the seventies."',
      author: 'Tom, #622',
    },
    { text: '"It\'s a bag of Homer Simpson!"', author: 'Joel. #422' },
    {
      text: '"It\'s a federal law to keep your monster in a car seat."',
      author: 'Tom, #606',
    },
    { text: '"It\'s a good thing drugs came along."', author: 'Mike, #606' },
    { text: '"It\'s a nauseating shorts detector!"', author: 'Mike, #819' },
    {
      text: '"It\'s a volatile mixture of hot munitions and cool jazz."',
      author: 'Crow, #612',
    },
    {
      text: '"It\'s after the apocalypse! Nobody\'s hiring!"',
      author: 'Tom, #403',
    },
    {
      text: '"It\'s all part of this nutritious war!"',
      author: "TV's Frank. #319",
    },
    {
      text: '"It\'s always funny when you turn down tea and then you want tea!"',
      author: 'Crow, #810',
    },
    { text: '"It\'s an Amish band!"', author: 'Tom, #1001' },
    {
      text: '"It\'s an entire race of mimes! We\'ve got to get back and warn Earth!"',
      author: 'Joel, #201',
    },
    {
      text: '"It\'s an exciting day indeed here at Japanese War Atrocity Park..."',
      author: 'Crow. #202',
    },
    {
      text: '"It\'s been a great pleasure knowing you. I hope you will think of us once in a while. / You know, when you drive past a blooded raccoon on the side of the road. That kind of thing."',
      author: 'The Ape Commander/Crow, #306',
    },
    { text: '"It\'s Coily\'s army of darkness!"', author: 'Crow, #1011' },
    {
      text: '"It\'s easy to get hurt. / Like when I got my butt caught in the toaster."',
      author: 'Scott Baio Guy/Crow, #811',
    },
    {
      text: "\"It's filled with lots of life's little instructions on how you can scan people's brains and make their heads explode.\"",
      author: 'Dr. Forrester, #511',
    },
    {
      text: '"It\'s Friday afternoon; the employees of City Corp. turn the speakers out of the windows."',
      author: 'Mike, #705',
    },
    {
      text: '"It\'s like they have two servings of tension but they\'re trying to stretch out for seven people."',
      author: 'Tom, #912',
    },
    { text: "\"It's like we're smart, but we're not!\"", author: 'Tom, #515' },
    {
      text: '"It\'s like when rats pop out of the toilets in St. Paul."',
      author: 'Mike. #620',
    },
    {
      text: '"It\'s not a good sign when your movie is upstaged by a mural."',
      author: 'Crow. #904',
    },
    {
      text: '"It\'s not locked! / And neither am I!"',
      author: 'Gloria/Mike, #613',
    },
    {
      text: '"It\'s not prison, Silver. / It\'s just the Catholic Church."',
      author: 'Mother Superior/Mike, #601',
    },
    {
      text: '"It\'s not so much the apocalypse as it is the humidity."',
      author: 'Joel. #501',
    },
    { text: '"It\'s not very invisible..."', author: 'Joel. #406' },
    {
      text: '"It\'s obsidian!" "Oh, I don\'t think it\'s *that* bad..."',
      author: 'Dr. Scott/Crow. #113',
    },
    {
      text: "\"It's Pernell's elaborate trick to demonstrate the need for men's room phones.\"",
      author: 'Crow, #614',
    },
    {
      text: "\"It's Spiro Agnew and Spiro Agnew in 'The Parent Trap'!\"",
      author: 'Joel. #207',
    },
    {
      text: '"It\'s still a better edited movie than Batman And Robin."',
      author: 'Crow, #911',
    },
    { text: '"It\'s Suicide Days in New Richmond."', author: 'Mike, #810' },
    { text: '"It\'s Tab Time."', author: 'Mike, #614' },
    {
      text: '"It\'s tasteless, odorless, and leaves no trace in the human body." "...Velveeta?"',
      author: 'Coleman/Crow. #504',
    },
    { text: '"It\'s the blind leading the bland."', author: 'Crow. #420' },
    {
      text: '"It\'s the Eastern Europe part of Southern California."',
      author: 'Crow. #815',
    },
    {
      text: '"It\'s the most feared geography teacher of Central High!"',
      author: 'Crow. #602',
    },
    {
      text: '"It\'s the parade of shame and wasted lives!"',
      author: 'Tom, #311',
    },
    { text: '"It\'s the pizza dominatrix!"', author: 'Tom, #603' },
    { text: '"It\'s the Subway Fondler Club."', author: 'Tom, #816' },
    {
      text: '"I\'ve been called a lot of things in my life but never a corn spy!"',
      author: 'Joel, #209',
    },
    {
      text: "\"'I've been through the proletariat areas and have consorted with the prostitutes,' now that's a little below the belt.\"",
      author: 'Mike, #806',
    },
    {
      text: '"I\'ve got a date with Mrs. Butterworth..."',
      author: 'Crow. #414',
    },
    {
      text: '"I\'ve got a fever for the flavor of Fingal."',
      author: 'Mike, #822',
    },
    {
      text: '"I\'ve got a thing about women on stepladders."',
      author: 'Joel. #108',
    },
    { text: '"I\'ve got the music in me!"', author: 'Mike, #606' },
    {
      text: '"I\'ve got to take the fridge to the airport. You need anything?"',
      author: 'Crow, #202',
    },
    {
      text: '"I\'ve got two words for you, kid...FOSTER HOME!"',
      author: 'Crow, #614',
    },
    {
      text: '"I\'ve observed that coffee houses are bursting at the seams with pretentiousness; therefore, you can\'t even be in a coffee house and not be pretentious!"',
      author: 'Dr. Forrester, #606',
    },
    {
      text: '"I\'ve seen *Baptists* that can dance better than this!"',
      author: 'Tom, #818',
    },
    {
      text: '"I\'ve seen Dali paintings that make more sense than this film."',
      author: 'Joel. #107',
    },
    {
      text: '"Jam Handy reminds you to eep your preserves in a convenient place."',
      author: 'Crow, #1011',
    },
    {
      text: '"JC Penney Hooker Wear! For the casual hooker at work, rest, or play."',
      author: 'Crow, #1007',
    },
    {
      text: '"Jeez! *Tolkien* couldn\'t follow this plot!"',
      author: 'Crow. #301',
    },
    { text: '"Jesus was a kickboxer, right?"', author: 'Tom, #1004' },
    {
      text: '"Jet Jaguar?  Jet Jaguar!  He mother never really love him."',
      author: 'Subtitles, #212',
    },
    { text: '"Jim Henson\'s Chairman Mao Babies."', author: 'Crow, #705' },
    {
      text: '"Joel, I can\'t help but think that this film was flawed in some ways."',
      author: 'Crow. #406',
    },
    {
      text: "\"Joel, it's this movie! It was really depressing! It was like ... it was like being a little kid and eating dinner at your Aunt Ruth's apartment in the suburbs and it's hot in there and she's got a local, Christian radio station on and there's nothin' to do or look at 'cause all she's got in the apartment are Good Housekeeping magazines and linen doilies!\"",
      author: 'Tom, #421',
    },
    {
      text: '"Joel, my invention today is for all the right reasons: because stress levels aren\'t high enough in America, because people need to know how very important you are, and because you may be in line for a movie when that important project deadline comes and goes. I\'m talking about the Cellular Desk."',
      author: 'Dr. Forrester, #502',
    },
    {
      text: '"Joel, this is gonna turn into a snuff film."',
      author: 'Crow, #424',
    },
    {
      text: '"Joel, this movie hurts. Quite bad, actually."',
      author: 'Tom, #413',
    },
    {
      text: '"Joel, when I grow up I wanna be a DRIFTER!"',
      author: 'Crow. #509',
    },
    {
      text: '"Joel, why are you spending your time psychoanalyzing robots?"',
      author: 'Crow. #507',
    },
    {
      text: '"Joel, why can\'t *you* wear nice things like that?"',
      author: 'Crow. #105',
    },
    {
      text: '"Joel, you been nippin\' at the tester\'s glue or what here?"',
      author: 'Tom, #310',
    },
    {
      text: '"Joel, you magnificent bastard, I READ YOUR MENU!!"',
      author: 'Tom, #507',
    },
    {
      text: '"John Carradine has major booty compared to Michael Landon."',
      author: 'Crow, #809',
    },
    {
      text: '"Johnny feels dark hands pressing him onward. The voices in his head get meaner."',
      author: 'Tom, #419',
    },
    {
      text: '"Johnny Longtorso /Johnny Longtorso / the man who comes in pieces... He\'s LONG!"',
      author: "Dr. Forrester and TV's Frank. #421",
    },
    { text: '"Just a normal Tuesday for Cher..."', author: 'Mike. #513(' },
    {
      text: '"Just advancing the plot, nothing personal."',
      author: 'Joel, #406',
    },
    {
      text: '"Just \'cause me big and dumb they think me killed Boyd. Oh ... right ... me did."',
      author: 'Mike, #805',
    },
    {
      text: '"Just for that you can\'t drink for an hour."',
      author: 'Crow, #407',
    },
    {
      text: '"Just go sit in front of your stupid network TV, you cheap fascist!"',
      author: 'Tom, #822',
    },
    { text: '"Just kill it, don\'t play with it!"', author: 'Crow, #311' },
    {
      text: '"Just shoot him! You told him you would. Don\'t pad your part!"',
      author: 'Tom, #103',
    },
    {
      text: "\"'Keep on truckin'. Peace. Ben Murphy.'\"",
      author: 'Crow, #814',
    },
    {
      text: "\"Keep out of sight. I'll find out if they're friends or enemies. / Uh, if they kill me, they're probably enemies.\"",
      author: 'Crash Corrigan/Tom, #406',
    },
    {
      text: '"Kids come running for the great taste of sampo!"',
      author: 'Joel. #422',
    },
    { text: '"Kids worship the darndest things!"', author: 'Joel. #424' },
    { text: '"Kids! No weapons on the waterbed!"', author: 'Tom, #605' },
    {
      text: '"Kids, don\'t ever sit on an archaeologist\'s lap."',
      author: 'Tom, #107',
    },
    {
      text: '"Kids, it\'s laying eggs in my chest. Is that normal?"',
      author: 'Mike, #906',
    },
    { text: '"Kim\'s having my fantasy about her!"', author: 'Crow, #403' },
    { text: '"Knew your father, I did!"', author: 'Mr. B Natural. #319' },
    {
      text: '"Known as \'the Lonely Moon.\'" "You should know, Winky."',
      author: 'Winky/Crow, #413',
    },
    {
      text: '"Krakatoa: East of Java." "Fentonville: East of Muncie."',
      author: 'Tom/Crow. #108',
    },
    {
      text: '"Ladies and gentlemen, ... I give you ... Narcotic Casserole!"',
      author: 'Pearl Forrester, #1004',
    },
    {
      text: '"Laserblast / Terrible name for laser eye surgery! Scares away the customers."',
      author: 'Credits/Crow, #706',
    },
    {
      text: '"Last time *you* helped on a project, we had to jetison a whole pan of burning rock candy into outer space!"',
      author: 'Tom, #112',
    },
    {
      text: '"Later, these moths turn up in the mouths of Bob\'s victims."',
      author: 'Crow, #608',
    },
    {
      text: '"Leave the Bronx! / Even though this is Italy, leave the Bronx!"',
      author: 'Megaphone Guy/Tom, #705',
    },
    {
      text: '"Leave the children alone! / You hear that, Jerry Seinfeld?!"',
      author: 'Prince of Space/Crow, #816',
    },
    {
      text: '"Let me explain it to you one more time: No."',
      author: 'Crow, #505',
    },
    {
      text: '"Let me guess. The last words in this movie are gonna be \'Ahahahahaha...ha!\'"',
      author: 'Tom, #608',
    },
    { text: '"Let me guess: da sheews in da baa."', author: 'Crow, #407' },
    {
      text: '"Let\'s crap in our hands and throw it at people! Heh heh... You know, no matter how much you evolve, that\'s still damn funny."',
      author: 'Crow, #306',
    },
    { text: '"Let\'s get the dog drunk next!"', author: 'Crow. #101' },
    { text: '"Let\'s go eat something gray."', author: 'Mike, #608' },
    {
      text: '"Let\'s go film tampon commercials, everybody!"',
      author: 'Crow, #811',
    },
    {
      text: '"Let\'s go kill something we don\'t understand."',
      author: 'Crow, #201',
    },
    {
      text: '"Let\'s go, director\'s college buds who brought their own suits to the shoot."',
      author: 'Mike, #821',
    },
    { text: '"Let\'s play with phosphorous!"', author: 'Tom, #421' },
    {
      text: '"Let\'s see, I could kill me, hollow me out, and live in me ..."',
      author: 'Crow, #821',
    },
    {
      text: "\"Let's see, what am I thinking? ... Uh, 'Duh,' that's right.\"",
      author: 'Mike, #811',
    },
    { text: '"Level 5 smug alert..."', author: 'Tom, #815' },
    {
      text: '"Like Morrissey, Joel, your movie this week comes from that shameful decade known as the eighties."',
      author: 'Dr. Forrester, #403',
    },
    {
      text: '"Listen, you got any idea who those turkeys were? / Were they butterballs?"',
      author: 'Sam Casey/Crow, #814',
    },
    {
      text: '"Listening to him is like flossing with a razor blade."',
      author: 'Tom, #814',
    },
    {
      text: '"Live fast, die young, and leave a fat, bloated, ugly corpse."',
      author: 'Tom, #207',
    },
    {
      text: '"Look at that. Too many sevens in that date; I\'m going out for a smoke."',
      author: 'Eddie, #821',
    },
    {
      text: '"Look familiar, Mike? Girls leaving you for ventriloquists?"',
      author: 'Crow, #818',
    },
    {
      text: '"Look look look at my crotch..."',
      author: 'All (as Cheerleaders). #317',
    },
    {
      text: '"Look on the window back there, it says \'Midgets\'. What is that, a menu item?" "As long as they\'re boiled and come with chips, sure!"',
      author: 'Crow/Mike, #818',
    },
    { text: '"Look to the slum, graduate!"', author: 'Mike, #705' },
    { text: '"Look! God has a spring!"', author: 'Crow, #1011' },
    {
      text: '"Look, Ann, brooding about it\'s not going to do any good. / But breeding will."',
      author: 'Captain Thorne Sherman/Crow, #407',
    },
    {
      text: '"Look, can\'t I just help you take your clothes off?"',
      author: 'Crow, #1001',
    },
    {
      text: '"Look, free breast rides and we\'ll waive the membership fees."',
      author: 'Mike, #523',
    },
    {
      text: '"Look, I ... I look stupid, I\'m admitting that now."',
      author: 'Crow, #403',
    },
    {
      text: '"Look, if I want my opinion I\'ll beat it out of me!"',
      author: 'Dr. Forrester. #309',
    },
    {
      text: '"Look, she can take over the world, but she can\'t sneak up on these pesky kids!" "Oh, yeah! Look, travel through the fourth dimension: no problem, but stuffed animals: look out!"',
      author: 'Tom/Joel/Crow, #316',
    },
    {
      text: '"Look, why don\'t I save you the trouble and just give *these* guys the money?"',
      author: 'Joel, #509',
    },
    {
      text: '"Looks like I got the load end of the pan that time."',
      author: 'Crow. #212',
    },
    { text: '"Looks like it hurts to be him."', author: 'Joel. #506' },
    {
      text: '"Looks like this scene was shot by a bank camera."',
      author: 'Crow. #415',
    },
    { text: '"Loser status confirmed."', author: 'Crow, #821' },
    {
      text: '"Lost in the valley of the shadow of the subplot..."',
      author: 'Crow. #319',
    },
    {
      text: '"Love? Is that where you find the least common denominator and then add the numerators?"',
      author: 'Pearl, #1005',
    },
    {
      text: '"Man ... evolved ... from ape. / Ah, but which man? / Yeah, what about women? / And what about Scarecrow\'s brain?"',
      author: 'Gypsy/Tom/Crow/Joel, #306',
    },
    {
      text: '"Man, half this movie has been peoples\' butts on ladders!"',
      author: 'Crow, #1004',
    },
    {
      text: '"Man, I could eat him. Tear off the wrapper, pop him in the microwave. One bite."',
      author: 'Mike, #706',
    },
    {
      text: '"Man, I could just hear the filth on his glasses."',
      author: 'Tom, #814',
    },
    {
      text: '"Man, if those pants blow it\'s gonna be like a piano exploding!"',
      author: 'Crow, #706',
    },
    {
      text: '"Man, I\'m so hungry: only two steaks for dinner, didn\'t get to finish my orange! All the frozen yogurt places were closed! Dah! ... Big buttery moon up there ... Sidewalk kinda looks like ice cream if you squint hard."',
      author: 'Tom, #512',
    },
    {
      text: '"Man, the Dutch Embassy\'s neighbors must always be calling the cops."',
      author: 'Mike, #903',
    },
    {
      text: '"Man, this movie is just sitting on my head and crushing it."',
      author: 'Joel, #511',
    },
    {
      text: '"Man, with those boots she has to use high altitude baking directions."',
      author: 'Crow, #903',
    },
    {
      text: '"Man... I never knew Arabia was so *Russian*."',
      author: 'Joel. #505',
    },
    {
      text: '"Man... I wish they had a more effective monster. This is taking forever!"',
      author: 'Joel. #423',
    },
    {
      text: '"Matthew 6:19 at the half... heh heh, little preacher joke."',
      author: 'Tom, #510',
    },
    {
      text: '"May I have a piece of my own white meat, please?"',
      author: 'Crow, #702',
    },
    {
      text: "\"Maybe I dialed wrong. Let me try again. Let's see... 'zero'.\"",
      author: 'Tom, #108',
    },
    {
      text: '"Maybe I should have washed my hands after handling that dead woodchuck.  Oh well..."',
      author: 'Mike, #602',
    },
    {
      text: "\"Maybe it's the funk of the hooded sweat shirt he's been wearing through the whole film that's got him down.\"",
      author: 'Mike, #812',
    },
    {
      text: '"Maybe now would be a good time to put my hands on my knees and rotate."',
      author: 'Mike, #619',
    },
    {
      text: '"Maybe this is a chick film and we just don\'t get it."',
      author: 'Tom, #705',
    },
    {
      text: '"Maybe this is an anthology of short, plotless movies."',
      author: 'Crow, #1004',
    },
    {
      text: '"Maybe this is an outrageously funny Italian movie." "I think it was originally conceived as a still photograph."',
      author: 'Mike/Tom, #705',
    },
    {
      text: '"Maybe we should turn this off." "No! No! It\'s his fault for not suspecting that we would build a sophisticated robot to spy on his undergarments." "I guess ..."',
      author: 'Tom/Crow/Tom, #1007',
    },
    {
      text: '"Me help! Attacked I\'m being! Hitting me stop you must! God, dear bleeding am I! Break my leg think I did you!"',
      author: 'Mike, #806',
    },
    { text: '"Meanwhile at Stifle Joy Co. ..."', author: 'Crow, #818' },
    { text: '"Meanwhile in a Barbara Feldon movie ..."', author: 'Tom, #209' },
    {
      text: '"Meanwhile in a better but more confusing story..."',
      author: 'Mike, #806',
    },
    { text: '"Meanwhile, at a carwash in Yokohama ..."', author: 'Tom, #816' },
    { text: '"Meanwhile, at Dolly World ..."', author: 'Crow, #316' },
    {
      text: '"Meanwhile, at the Ford Falcon with the Viper Slap option ..."',
      author: 'Tom, #306',
    },
    {
      text: '"Meanwhile, Elliot Ness and his men and his cousins and friends and some guys he met down at the auto parts store were all on their way to ... here."',
      author: 'Crow, #819',
    },
    {
      text: '"Meanwhile, in a hideous, little simulacrum of the United States ..."',
      author: 'Crow, #819',
    },
    { text: '"Meanwhile, in a Kurosawa film ..."', author: 'Mike, #606' },
    {
      text: '"Meanwhile, in a soap opera not far away ..."',
      author: 'Crow, #421',
    },
    {
      text: '"Meanwhile, in a totally different movie at a completely unrelated high school ..."',
      author: 'Tom, #606',
    },
    { text: '"Meanwhile, in Schaumburg, Illinois ..."', author: 'Crow, #705' },
    { text: '"Meanwhile, in the very same scene ..."', author: 'Tom, #412' },
    { text: '"Meanwhile, on the Buffalo Bill set ..."', author: 'Crow, #614' },
    {
      text: '"Meanwhile, the Midvale police visit his locker and find out why they call him \'Buzz\'..."',
      author: 'Tom, #319(WotCB)',
    },
    {
      text: '"Merry Christmas everyone!  Let\'s go to the Haunted House!"',
      author: 'Crow, #1002',
    },
    {
      text: '"Microsoft Service. Could you hold for an hour?"',
      author: '#324',
    },
    {
      text: '"Mike Nelson to the lunchroom, there\'s a robot from the future waiting for you."',
      author: 'PA Guy, #821',
    },
    {
      text: '"Mike, I swear I am never going to forget what the Japanese did to us with this ... movie."',
      author: 'Crow, #816',
    },
    {
      text: '"Mike, I think I need a handle: call me The Delta Lady." "Uhh, no."',
      author: 'Tom/Mike, #814',
    },
    {
      text: '"Mike, I want you to call me \'Farciot Edouart\' from now on." "Okay ... Farcy."',
      author: 'Crow/Mike, #906',
    },
    {
      text: '"Mike, if I run out of vomit can I have some of yours?"',
      author: 'Tom, #907',
    },
    {
      text: '"Mike, if I should slip into a coma during this movie, please, PLEASE do not take any extraordinary measures to revive me. Thank you."',
      author: 'Tom, #810',
    },
    {
      text: '"Mike, I\'m going to watch your shoes for awhile; it makes about as much sense."',
      author: 'Crow, #905',
    },
    {
      text: '"Mike, insert me into her cleavage, then get lost."',
      author: 'Tom, #805',
    },
    {
      text: '"Mike, it all started in a place called Hope, but, sadly, it\'s ending today. Mike, I\'ve recommended to the board that you be declared incompetent, and, therefore, unsafe at any speed to lead as captain."',
      author: 'Tom, #809',
    },
    {
      text: '"Mike, let\'s just torch the theater and get out of here. Come on."',
      author: 'Crow, #901',
    },
    {
      text: '"Mike, you think if I went away and painted the entire satellite and then came back that I\'d miss anything?"',
      author: 'Crow, #818',
    },
    {
      text: '"Mister Misty Meanor." "That\'s what you get when you rob a Dairy Queen, right?"',
      author: 'Joel/Crow, #106',
    },
    {
      text: '"Mister Wangler, sir, call from Mister Clark for you!" "Fine, I\'ll take it now!" "Good, since he\'s on the phone NOW!"',
      author: 'DAS Guy/Wide Wangler/Tom, #705',
    },
    {
      text: '"Mitchell, people don\'t like you. In fact, I don\'t care for you myself. Why is that?" "Perhaps our brief but bitter affair?"',
      author: 'Commissioner/Crow, #512',
    },
    {
      text: "\"'Mitchell.' Even his names says, 'Is that a beer?'\"",
      author: 'Joel, #512',
    },
    {
      text: '"Mitchell... Even his name says, \'Is that a beer?\'"',
      author: 'Joel. #512',
    },
    {
      text: "\"'Mittens'? An action film called 'Mittens'?\" \"Joe Don Baker IS Mittens! He's a cop!\"",
      author: 'Tom/Crow, #512',
    },
    {
      text: '"Mmmm... your lips are like crisp, delicious bacon..."',
      author: 'Tom, #607',
    },
    {
      text: '"Models by Eric and Louis Grundin, fourth and fifth grade, Misses Johnson\'s class ..."',
      author: 'Crow, #316',
    },
    {
      text: '"Mom said \'no,\' huh? So, you come to me: the nice cop."',
      author: 'Crow, #512',
    },
    {
      text: '"Mom! No!! She\'s putting out for a nickel! ... I have no mother..."',
      author: 'Tom, #523',
    },
    {
      text: '"Mom, ... \'m I nuts?" "\'My nuts\'?!"',
      author: 'Aram Fingal/Tom, #822',
    },
    {
      text: '"Mom, if the UN calls, I\'m playing with my slot cars."',
      author: 'Joel, #308',
    },
    { text: '"MONKS. IN. SPAAAAAACE!"', author: 'All, #310' },
    {
      text: '"Moon, let\'s leave your bag over there." "\'Moon\'?" "Is his name Moon W. Trash?"',
      author: 'Strike/Tom/Mike, #705',
    },
    {
      text: '"More panties, Mike: I need another four pack. / Servo, your body can only absorb so many panties per hour."',
      author: '#819',
    },
    { text: '"Most face any screen has ever held!"', author: 'Mike, #822' },
    {
      text: '"Most people are morally ambiguous, which explains our random dying patterns."',
      author: 'Tom, #511',
    },
    {
      text: '"Mount your horses, gentlemen." "We\'re not *that* lonely!"',
      author: 'Sir Branton/Crow, #411',
    },
    {
      text: '"Movie sponsored by the Plaid Council. Plaid: it\'s what\'s for dinner."',
      author: 'Tom, #1004',
    },
    { text: '"Mr. B, you\'re HOT!!"', author: 'Joel. #319' },
    {
      text: '"Mr. Moffit, I have never spanked a senior before..."',
      author: 'Mike, #702',
    },
    {
      text: '"Mrs. Beck!  Mrs. Beck, are you all right?" "She\'s all right except for the fact that she married Beck."',
      author: 'Cop/Crow, #1010',
    },
    { text: '"Mrs. Beck... / you\'re a loser, baby."', author: '#1010' },
    {
      text: '"Mrs. Talbot, I know this is painful, but it\'s one of those things that has to be done when you plan a divorce." "You have to be married."',
      author: 'Neil/Mike, #802',
    },
    { text: '"My cardigan alerted me some time ago."', author: 'Mike, #815' },
    {
      text: '"My fajita plate was really hot and I touched it!!!"',
      author: 'Mike, #907',
    },
    {
      text: '"My father was a bat master!  I guess that makes me Bat Master\'s son."',
      author: 'Mike, #1010',
    },
    {
      text: '"My father was a can opener; my mother was a wood duck."',
      author: 'Tom, #619',
    },
    {
      text: '"My God! I\'ve been captured by Wayne Newton!"',
      author: 'Joel (as Ken), #310',
    },
    {
      text: '"My monster is silent as tomorrow.  He kills in the night. He has been acquainted with the night." "...It sounds like your monster reads Frost, too." "Yeah, and he sprays it like icy death from his bloody stumps!"',
      author: 'Crow/Joel, #212',
    },
    { text: '"My mother\'s donuts are a saint!!"', author: 'Crow, #312' },
    { text: '"My name is Death, I\'ll be your waiter."', author: 'Crow, #619' },
    {
      text: '"My race is pacifist and does not believe in war. We only kill out of personal spite."',
      author: 'Observer, #814',
    },
    {
      text: '"My shorts are never boring." "Thank you, Tom."',
      author: 'Tom/Joel. #102',
    },
    {
      text: '"My theory is that the director shot the entire movie without looking at it."',
      author: 'Crow, #1004',
    },
    {
      text: '"My wife just died in my arms!" "Hey, you were way over by the window."',
      author: 'Joe/Crow, #310',
    },
    {
      text: '"Natalie!! / Don\'t tongue the Reaper!"',
      author: 'Zach/Crow, #1001',
    },
    { text: '"Neat. Dark specter in basic black."', author: 'Tom, #511' },
    {
      text: '"Need an atlas? Come to Atlas King, where maps rule."',
      author: 'Tom, #812',
    },
    {
      text: '"Nellie Manley, who\'s real name is Sissy Macho."',
      author: 'Crow. #906',
    },
    {
      text: '"Never saw my grandma naked, but ... this\'ll do."',
      author: 'Mike, #616',
    },
    {
      text: '"Next time I make a robot, no more free will."',
      author: 'Joel. #106',
    },
    {
      text: '"Nice location for a beating. Real scenic!"',
      author: 'Tom, #403',
    },
    {
      text: '"Nick\'s a smoking husk right now; he won\'t mind if we take his van."',
      author: 'Crow, #907',
    },
    { text: '"Nipple check. ... Yep: nipples intact."', author: 'Mike, #706' },
    { text: '"No acting beyond this point."', author: 'Tom, #403' },
    {
      text: '"No matter where he goes he finds a basement to dwell in."',
      author: 'Tom, #910',
    },
    { text: '"No monologues with poopie pants, please."', author: 'Tom, #821' },
    {
      text: '"No one could hold a candle to him in this role. Well, maybe they could douse him in something flammable and *then* hold a candle to him."',
      author: 'Crow. #105',
    },
    {
      text: '"No one wants to eat cake and he can\'t find Cathy; now the movie is really starting to find its purpose!"',
      author: 'Crow, #706',
    },
    {
      text: '"No one will be seated during the famous Fish Argument."',
      author: '#316',
    },
    {
      text: '"No one\'s ever attempted an interpretive dance of Mein Kampff before."',
      author: 'Crow, #812',
    },
    {
      text: '"No sweat, dad. I don\'t want nothin\' from you. She owes me a fin, that\'s all." "Uh, in English please; I ... don\'t dig ... with you, ... freaky ... lactose man."',
      author: 'New York Method Actor/Joel, #414',
    },
    {
      text: '"No, I\'m saving myself for the right oily drifter."',
      author: 'Joel, #509',
    },
    {
      text: "\"'No, Joel, *you* can't go to Woodstock, *you're* only nine years old...'\"",
      author: 'Joel. #204',
    },
    {
      text: '"No, my doctor says I can\'t have Mr. Pibb!"',
      author: "TV's Frank. #508",
    },
    {
      text: '"No, no, I know what he looks like: he looks like a woodchuck that got nailed by a splat ball."',
      author: 'Tom, #306',
    },
    {
      text: "\"No, that's fine, Mike: you cut my hand up and serve it to me. Good. The thing is I'm so hungry, it actually looks good to me! [verge of sobs] My own hand looks delicious! You see what you've done to me, Mike?! You see what you've done?!\"",
      author: 'Crow, #814',
    },
    {
      text: '"No, this can\'t be the star, can it movie? Movie, hey, movie, can I see your supervisor, movie? This will not stand!"',
      author: 'Crow, #821',
    },
    {
      text: '"No, you can\'t inject puberty, it has to happen over time."',
      author: '#503',
    },
    {
      text: '"Nobody leaves the World Wrestling Federation!"',
      author: 'Crow, #910',
    },
    {
      text: '"Not a good sign when the action in your movie is upstaged by a mural."',
      author: 'Crow, #904',
    },
    {
      text: '"Not only is this just a telephone dialing scene, the person dialing isn\'t even on the screen!"',
      author: 'Crow, #805',
    },
    {
      text: '"Not since the Fuzzy Zoeller and Lee Trevino collision has there been a worse golf cart accident."',
      author: 'Mike. #820',
    },
    {
      text: '"Not that back hair is bad, some people might like it. / Yeah, if you\'re an alien or something."',
      author: 'Crow/Tom, #515',
    },
    {
      text: '"Nothing like an invigorating swim with a corpse in the morning."',
      author: 'Tom, #414',
    },
    {
      text: '"Now do you think we have anything more than BOING?" "Why, yes: I think you\'ve made a good start towards getting ready for marriage. / But never make light of BOING, son. / BOING, everybody! / BOING! BOING!"',
      author: '#616',
    },
    {
      text: '"Now for me, let me relax with a little black book and a gay night life." "Uh ... insert joke here."',
      author: 'Winky/Joel, #413',
    },
    {
      text: '"Now he takes out a personal ad: \'Saw you in museum. You took bone. I chased you.\'"',
      author: 'Crow, #903',
    },
    {
      text: '"Now I can schottische while watering my plants, in my Lederhosen...Hosen..."',
      author: 'Frank, #508',
    },
    {
      text: '"Now make way for the Ku Klux Klowns! Woo! / Yes, children of all ages are confronted by forces they can\'t begin to understand! / Sieg heil! Sieg heil!"',
      author: '#422',
    },
    {
      text: "\"Now that I think about it, I must've been in some sort of coma. / I was in a coma: that's probably why I don't remember it.\"",
      author: 'Sam Casey/Crow, #814',
    },
    {
      text: '"Now that we need a narrator he clams up. What\'s going on?!"',
      author: 'Tom, #606',
    },
    {
      text: '"Now that\'s dirty pool! If I knew what was going on I\'d really be indignant."',
      author: 'Crow, #403',
    },
    {
      text: '"Now they\'re just gratuitously postponing the nudity!"',
      author: 'Tom, #1001',
    },
    { text: '"Now this looks positively Baywatchian!"', author: 'Crow, #512' },
    {
      text: '"Now this may sound selfish, / but I want everything for me!"',
      author: 'Eric/Tom, #912',
    },
    {
      text: '"Now wait a minute, did this movie just lap itself?"',
      author: 'Tom, #424',
    },
    {
      text: '"Now we get to watch people watching a movie?  What is that about?!"',
      author: 'Mike, #807',
    },
    {
      text: '"Now, back to the rotting septic system of this futuristic space ship!"',
      author: 'Tom, #820',
    },
    {
      text: "\"Now, don't you kids get drunk and go swimming under the dock. (Doodly doodly doodly...) Help! I'm drunk and I'm swimming under the dock!!\"",
      author: 'Tom, #502',
    },
    {
      text: '"Now, I envision Big Stupid as pure energy..."',
      author: "TV's Frank. #509",
    },
    {
      text: '"Now, my dead wife will scream. Listen to the difference."',
      author: 'Crow, #912',
    },
    {
      text: '"Now, this is how the NRA wants the world to look. And, frankly ... sorry."',
      author: 'Mike, #515',
    },
    {
      text: '"Now, to shave his butt and put cosmetics on it."',
      author: 'Crow, #306',
    },
    {
      text: '"Now, wasn\'t there at one point a fortune teller who killed a doughy, middle manager guy or am I thinking of a different movie?"',
      author: 'Crow, #812',
    },
    {
      text: '"Now, were these dance numbers supposed to be provocative or merely boring?" "Well, provocative in their boringness?" "Ah."',
      author: 'Crow/Mike/Crow, #812',
    },
    {
      text: '"Now, where were we. Ah, yes, I believe I had my tongue down your throat."',
      author: 'Crow, #206',
    },
    {
      text: '"Now, you see, this is irresponsible. They\'re encouraging people to go out and drug Kathy Ireland."',
      author: 'Mike. #516',
    },
    {
      text: '"Now, your movie today is a little tab of Orange Sunshine called Future War. We have a word for this kind of film in the illicit pharmacology business: ... \'crap\'!"',
      author: 'Pearl Forrester, #1004',
    },
    {
      text: '"O! Prince of Space!" "I was thinking it was going to be Fritz Mondale, but, of course, it\'s Prince of Space!"',
      author: 'Z1/Mike, #816',
    },
    {
      text: '"Observer, how are things looking from your end?" "I\'m five feet away, you stupid primate!"',
      author: 'Bobo/Observer, #812',
    },
    {
      text: '"Of course I am American and not Italian: I drive a truck!"',
      author: 'Tom, #911',
    },
    {
      text: '"Off to meet my doom, Mom! See you after school!"',
      author: 'Joel, #306',
    },
    {
      text: '"Oh geez, nature videos of big spiders goin\' at it are more appealing!"',
      author: 'Crow/Tom, #706',
    },
    {
      text: '"Oh good: another large guy. The movie was needing one."',
      author: 'Crow, #1004',
    },
    { text: '"Oh I\'d hate to shoot a butt like that!"', author: 'Tom, #102' },
    {
      text: '"Oh man, it\'s like a buffet of victims; I don\'t even know where to start!"',
      author: 'Crow, #912',
    },
    {
      text: '"Oh my god! The humidifier committed suicide!!"',
      author: 'Crow, #212',
    },
    {
      text: '"Oh my goodness! I\'m growing so fast I\'m giving myself a wedgie!"',
      author: 'Joel, #309',
    },
    {
      text: '"Oh no! His car turned into a Sedan and crashed and exploded!"',
      author: 'Mike, #706',
    },
    {
      text: '"Oh no! It\'s Plane Protection Services, they know I kissed my plane!"',
      author: 'Crow, #821',
    },
    {
      text: '"Oh no, this guy went to the Torgo School of Fondling!"',
      author: 'Joel, #506',
    },
    { text: '"Oh thanks, Otto von Bringmedown!"', author: 'Mike, #623' },
    {
      text: '"Oh the passion. I find you so acceptable."',
      author: 'Mike, #610',
    },
    {
      text: '"Oh yeah, old guys becoming pandas, that\'s the future!"',
      author: 'Mike, #822',
    },
    {
      text: '"Oh yeah, this was when NASA was just a car and a helicopter, right."',
      author: 'Joel, #421',
    },
    {
      text: '"Oh yeah, we could prevent Newt Gringrich, Jim Carrey, and the Smashing Pumpkins!"',
      author: 'Crow, #821',
    },
    {
      text: '"Oh your smoldering averageness. Stop me."',
      author: 'Crow, #610',
    },
    {
      text: '"Oh, and \'go Packers,\' too, but mostly burn the witch!"',
      author: 'Mike, #908',
    },
    { text: '"Oh, and you must have a wire rack."', author: 'Tom, #313' },
    {
      text: '"Oh, as if *you\'re* the only person who ever got their head cut off. Quit whining!"',
      author: 'Tom, #513',
    },
    {
      text: '"Oh, Billy ... can\'t you be more ... ordinary?" "[scoffs] \'More ordinary?\' Man, he\'d have to work at that."',
      author: 'Cathy/Mike, #706',
    },
    {
      text: '"Oh, bummer. Hope they don\'t blow our crotches up."',
      author: 'Tom, #705',
    },
    {
      text: '"Oh, come on! One more sacrifice before we go!" "Okay, but this is the final sacrifice."',
      author: 'Tom / Mike, #910',
    },
    {
      text: "\"Oh, come on. There's nothing you can put in somebody's food that'll turn them into the cast of Renegade. ... AH!\"",
      author: 'Mike, #622',
    },
    {
      text: '"Oh, David Spade as Satan. / That\'s good casting."',
      author: 'Mike/Crow, #908',
    },
    { text: '"Oh, don\'t invoke Jupiter..."', author: 'Mike. #514' },
    {
      text: '"Oh, Federated Incorporated Industries Limited."',
      author: 'Crow, #818',
    },
    { text: '"Oh, fine. Everyone pick on the Devil."', author: 'Mike. #603' },
    {
      text: '"Oh, god! I was going to get through this movie without thinking about his hips and now this!"',
      author: 'Mike, #515',
    },
    { text: '"Oh, god. Pant Alert."', author: 'Crow, #809' },
    {
      text: '"Oh, god... I was gonna get through this movie without thinking about his hips, and now *this*!..."',
      author: 'Mike. #515',
    },
    {
      text: '"Oh, good, he\'s all stained again! / Why did the movie just cover him in cat vomit?"',
      author: 'Mike/Crow, #821',
    },
    {
      text: '"Oh, great, a harmonica. As if this guy wasn\'t annoying enough."',
      author: 'Crow. #201',
    },
    {
      text: '"Oh, great, I never wanted to see this! / And I don\'t want to see Torgo in something sheer!"',
      author: 'Joel/Crow, #424',
    },
    {
      text: '"Oh, great, Tony, you shot a midget. Hope you sleep well tonight."',
      author: 'Joel, #105',
    },
    {
      text: '"Oh, great. Another oily, unlikable character."',
      author: 'Crow. #419',
    },
    {
      text: '"Oh, great. The plague. And I\'ve got a date!"',
      author: 'Crow, #405',
    },
    {
      text: '"Oh, ho, ho, heavens, I\'m nude! Gee, oh, look at the definition! Could\'ve used a little more marble here and there, but still a creditable effort, eh? [claps]"',
      author: 'Bobo, #819',
    },
    { text: '"Oh, I don\'t go map finding behinding."', author: 'Mike, #910' },
    { text: '"Oh, I feel so sorry for his shirt."', author: 'Crow, #822' },
    {
      text: '"Oh, I get it! Based on today\'s rampant comic book culture in today\'s experiment you two have gone back through your comic collection to find the meaning of life!" "No, no, we found that! That\'s that pile! It\'s the Desiderata comic. Del version."',
      author: 'Joel/Crow, #403',
    },
    {
      text: '"Oh, I get it! You take a rototiller, and you hook a guy between two, and, therefore, AVOID ANY PLOT!"',
      author: 'Crow, #209',
    },
    {
      text: '"Oh, I get it: Mario\'s gonna become a shrew!"',
      author: 'Tom, #407',
    },
    {
      text: '"Oh, I know I\'m being punished for something... What have I done??... I DIDN\'T ASK TO SEE THIS MOVIE!!"',
      author: 'Tom, #506',
    },
    {
      text: '"Oh, I see some *really* stupid children being born as a result of these two meeting."',
      author: 'Tom, #904',
    },
    { text: '"Oh, I suppose *Hell* got an NEA grant."', author: 'Mike. #521' },
    {
      text: '"Oh, I suppose I\'ll accept a little bit of sex from you; go ahead."',
      author: 'Mike, #911',
    },
    {
      text: '"Oh, if Clu Gulager isn\'t in this it\'ll be very wrong."',
      author: 'Tom, #814',
    },
    {
      text: '"Oh, I\'m being punished for something, I know I am! What could I have done?! I DIDN\'T ASK TO SE THIS MOVIE!!!"',
      author: 'Tom, #506',
    },
    {
      text: '"Oh, I\'m sorry!  I failed to tell you. / I\'m made of liverwurst."',
      author: 'Krasker/Mike, #603',
    },
    {
      text: '"Oh, it was pretty rough, man! I had to eat a lizard and drink my urine!" "You were only here for ten minutes!" "I know, but ..."',
      author: 'Mike/Tom, #612',
    },
    {
      text: '"Oh, it\'s just part of the American way: turning a neighboring country rich in culture and beauty into a goofy appetizer."',
      author: 'Joel. #319',
    },
    {
      text: '"Oh, it\'s Microsoft Spell Check By Phone."',
      author: 'Tom. #811',
    },
    {
      text: '"Oh, Krankor blew up a potential tackler on the forty, and now there\'s nothing but daylight!"',
      author: 'Mike, #816',
    },
    {
      text: '"Oh, mom, I don\'t wanna ... I hate the circus on ice anymore! I wanna go home!" "Shut up and watch the deer get slaughtered; it\'s fun!"',
      author: 'Crow/Tom, #421',
    },
    {
      text: '"Oh, Nelson. So close and yet so not."',
      author: 'Dr. Forrester. #522',
    },
    {
      text: '"Oh, no! My aunt\'s coming to take me to the Michael Bolton concert!"',
      author: 'Tom, #502',
    },
    {
      text: '"Oh, no! Someone knocked over the sump pump in the Mir!"',
      author: 'Mike. #819',
    },
    {
      text: '"Oh, not the shrimp paste tanks! / Oh, and that was Miso Soup for tomorrow\'s lunch!"',
      author: 'Mike/Crow, #819',
    },
    {
      text: '"Oh, please little fart...stay inside my dress."',
      author: 'Tom, #818',
    },
    {
      text: '"Oh, right in my vast, doughy midsection!"',
      author: 'Crow, #511',
    },
    { text: '"Oh, she\'s a Ouija *broad*!"', author: 'Tom, #805' },
    {
      text: "\"Oh, shut up! Ever since we left all it's been out of you is 'Texas this' and 'Texas that.' Well, listen, bub, I've been to Texas and it's not all that great!\"",
      author: 'Crow, #201',
    },
    {
      text: '"Oh, stop pretending that there\'s a plot. Don\'t cheapen yourself further."',
      author: 'Crow, #515',
    },
    {
      text: '"Oh, stop pretending there\'s a plot. Don\'t cheapen yourself further."',
      author: 'Crow. #515',
    },
    {
      text: '"Oh, that\'s dirty pool! If I knew what was going on I\'d really be indignant!"',
      author: 'Crow, #403',
    },
    {
      text: '"Oh, that\'s right: dogs can\'t see idiots."',
      author: 'Mike, #1001',
    },
    {
      text: '"Oh, that\'s the problem: he was sitting on a bush."',
      author: 'Crow, #309',
    },
    {
      text: '"Oh, this is a great date. I\'ve always wanted to be nuzzled by a hobo."',
      author: 'Joel, #509',
    },
    {
      text: '"Oh, this reminds me, Mike, we\'re out of embalming fluid."',
      author: 'Tom, #912',
    },
    {
      text: '"Oh, \'Tormented.\' I have a feeling this is aptly named."',
      author: 'Tom, #414',
    },
    {
      text: '"Oh, we sold her into slavery for some magic beans. Why?"',
      author: 'Joel, #112',
    },
    {
      text: '"Oh, yeah, feel real good about kicking a fish\'s butt. That\'s real nice."',
      author: 'Joel. #204',
    },
    {
      text: '"Oh, yuck, he\'s got Leonardo DiCaprio physique."',
      author: 'Tom, #1005',
    },
    { text: '"Oh. Flying hellbeast. Seen it."', author: 'Joel, #308' },
    { text: '"Oh. We\'re in Hell. Neat."', author: 'Joel. #208' },
    {
      text: '"Ok ... let\'s try ... escalate access! / YEAH!!! LET\'S TRY THAT!!! YEAH!!! WEEEEEE!!!"',
      author: 'Aram Fingal/Crow, #822',
    },
    {
      text: '"Ok, Crow, I\'m putting you on Clu Gulager alert."',
      author: 'Mike, #908',
    },
    {
      text: '"Ok, everyone set with the premise of the movie? Some sort of plane thing or something?"',
      author: 'Mike, #612',
    },
    {
      text: '"Ok, I\'ve done the budget, and if we really watch expenses, we should be able to have a drunken dorm hootinanny, with your precious beer, sometime next year."',
      author: 'Tom, #818',
    },
    {
      text: '"Ok, Mike\'s headed in for a nap and our little friend is right behind; heheheh. Soon, after all these years, we\'ll know what kind of pajamas our enigmatic pal wears!" "Yeah! And then we can kill him!" "... Uh, no, Crow." "Oh, right! I was thinking of someone else; I\'m sorry."',
      author: 'Tom/Crow, #1007',
    },
    { text: '"Ok, stop! Everyone go up a shirt size."', author: 'Crow, #904' },
    {
      text: '"Ok, Trash, let\'s take it again. Remember: you\'re really upset, your parents have been fried, and ... ah, forget it."',
      author: 'Mike, #705',
    },
    {
      text: '"Ok, you big babies, here are the rules: go to bed now and don\'t get up or I\'ll put your hands in the garbage disposal!"',
      author: 'Crow, #702',
    },
    {
      text: '"Okay, *don\'t* kill me! Sure could go for a sammich, though..."',
      author: 'Tom, #416',
    },
    {
      text: '"Okay, thanks a lot, sorry about the tip: I left the money in my other, uh ... pocket."',
      author: 'Frank, #422',
    },
    {
      text: '"Okay, who wants their butt kicked first?"',
      author: 'Mike, #814',
    },
    {
      text: '"Once again, Big has saved Danny from a heterosexual experience."',
      author: 'Joel. #509',
    },
    {
      text: "\"Once you're over the age of eleven you should not say 'pow.'\"",
      author: 'Mike, #706',
    },
    {
      text: '"One of our local spiders! / So it\'s stupid and smells of beer sweat?"',
      author: 'Alan Hale/Crow, #810',
    },
    {
      text: '"Ooo, bad move: robots do not fight clean."',
      author: 'Crow, #912',
    },
    {
      text: '"OOOH, is the great George Worthing Yates writing the screenplay?!"',
      author: 'Crow, #414',
    },
    {
      text: '"Oooh, so she would actually have to turn the knob to get out."',
      author: 'Crow, #614',
    },
    {
      text: '"OPERAYTOR! OPERAYTOR! / GET ME SYLVIA\'S MOTHER!"',
      author: 'Ingenue/Mike, #613',
    },
    {
      text: "\"Origami! Well, that's Japanese, right; I like that? Well...maybe 'like' is too strong a word: I don't mind it. ...Actually I hate it; folding paper: it's stupid, stupid!\" \"Turned on that pretty quick.\"",
      author: 'Tom/Crow, #819',
    },
    {
      text: '"Original story by Sasquatch. Adapted from a play by Coco and a novel by Chuckles."',
      author: 'Tom, #306',
    },
    {
      text: '"Oscar Wilde only *wished* he were this gay."',
      author: 'Joel. #319',
    },
    {
      text: '"Our campus features a low gym coach to clone ratio!"',
      author: 'Crow, #811',
    },
    {
      text: '"Our drink special tonight is: you stand by me and I breathe!"',
      author: 'Crow, #802',
    },
    {
      text: '"Our electronic brain stopped working immediately." "It just stopped?" "Yes. [rapid jump cut] / Much like our scene."',
      author: 'Mr. Tabana/Dr. Tanaway/Mr. Tabana/Crow, #819',
    },
    {
      text: '"Our invention this week is based on one\'s natural inclination to bite the heads off of chocolate bunnies."',
      author: 'Frank, #424',
    },
    {
      text: '"Our next contestant is Buffalo Bill. / Actually he\'s more like Calamity Jane ..."',
      author: 'Announcer/Crow, #814',
    },
    {
      text: '"Ow! Ow! Somebody put wasabi in our jocks!"',
      author: 'Crow, #819',
    },
    { text: '"Packers Fans: The Horrible Truth."', author: 'Crow, #810' },
    { text: '"PACKERS!!! WHOOO!!!"', author: 'M&tB, #810' },
    { text: '"Pagoda of the Damned!"', author: 'Crow, #816' },
    {
      text: '"Pajamas, jazz, and communism. This is WILD!!"',
      author: 'Crow. #610',
    },
    {
      text: '"Papa, what\'s magnitude mean?" "I think it means \'green.\'"',
      author: 'Kenny/Crow, #316',
    },
    {
      text: '"Part of me is laughing. I think the part of me that hates life."',
      author: 'Crow, #907',
    },
    { text: '"Parts: the visible panty line horror."', author: 'Mike, #811' },
    {
      text: '"Passed from editor to editor in a desperate attempt to save it!"',
      author: 'Mike, #820',
    },
    {
      text: '"Patients are evacuated from the Center for Blowfish Poisoning."',
      author: 'Tom, #819',
    },
    {
      text: '"Pepsi gave him big bucks to place Coke in this movie."',
      author: 'Mike, #706',
    },
    {
      text: '"Pie doesn\'t make you wanna kill yourself; you want some?"',
      author: 'Mike, #619',
    },
    { text: '"Plaid Avengers, HOOO!!!"', author: 'Tom, #1004' },
    {
      text: '"Please be seated, Dr. Meecham. And welcome aboard. / You\'re being kidnapped by the Lite FM!"',
      author: 'Plane Voice/Mike, MST3K:TM',
    },
    { text: '"Please don\'t be splayed ... AH!"', author: 'Crow, #1006' },
    { text: '"Please don\'t drink from the bidet ..."', author: 'Joel, #407' },
    {
      text: '"Please don\'t ever stop being the eighties or my life is over."',
      author: 'Tom, #1001',
    },
    {
      text: '"Please slip corpses under the door after ten."',
      author: 'Crow. #206',
    },
    {
      text: '"Please! Please! If you could keep all your rhubarbs to a minimum!"',
      author: 'Crow, #705',
    },
    {
      text: '"Please, God! Cut away to anything! Please!"',
      author: 'Mike, #515',
    },
    {
      text: '"Please...try to understand... / I\'m a magic man."',
      author: 'Old Guy/Joel, #417',
    },
    {
      text: '"Pond scum is fleeing in terror from her face."',
      author: 'Tom. #813',
    },
    {
      text: '"Poor dope, he figures if he can\'t see the camera the camera can\'t see him."',
      author: 'Tom, #822',
    },
    {
      text: '"Potatoes are what we eat!"',
      author: 'Dirty Peasant Woman, #703',
    },
    { text: '"Prelude to the Afternoon of a turkey."', author: 'Crow, #814' },
    {
      text: '"Pretty please, with Herc on top?"',
      author: 'Crow (as Hercules). #502',
    },
    {
      text: '"Pretty soon he\'s going to be flashing back to the beginning of the flashback."',
      author: 'Crow, #1004',
    },
    { text: '"Prince of ... Space ... Chief."', author: 'Crow, #819' },
    {
      text: '"Probation isn\'t a right, it\'s a privilege." "Like stretch pants."',
      author: 'Probation Officer/Crow. #601',
    },
    {
      text: '"Produced in association with the Kingdom of Darkness."',
      author: 'Crow, #814',
    },
    {
      text: '"Professor Hitler and his Invisible Knee Machine!"',
      author: 'Crow, #403',
    },
    { text: '"Proof there is no life after drama club."', author: 'Tom, #907' },
    {
      text: '"QUIT SCREWIN\' AROUND AND EAT THAT OMELET!!!"',
      author: 'Mike, #809',
    },
    {
      text: '"Quite a long line at the petty larceny window."',
      author: 'Crow, #507',
    },
    {
      text: '"Quite right, my dear Mahkin. A monster which I created! He obeys my slightest command! / Like, \'Wander around aimlessly and gain weight!\'"',
      author: 'Krankor/Crow, #816',
    },
    { text: '"Rat Fink\'s got a good watch voice."', author: 'Mike, #515' },
    { text: '"Real Africa...Hollywood Africa..."', author: '#802' },
    { text: '"Red alert! All hands prepare to die!"', author: 'Gypsy, #805' },
    {
      text: '"Red Zone Cuba III: The Destruction Of Cherokee Jack."',
      author: 'Crow, #706',
    },
    {
      text: '"Remember last week, when he was alive? Well, that didn\'t last..."',
      author: 'Joel, #510',
    },
    {
      text: '"Remember men!  Top rung not to be used as step!"',
      author: 'Mike, #703',
    },
    {
      text: '"Remember: for all your top secret government project needs, trust Central Moving and Storage!"',
      author: 'Crow, #814',
    },
    {
      text: '"Remember: people like you better if you\'re pretty.  Conform."',
      author: 'Crow, #613',
    },
    { text: '"Remind me to never be a child."', author: 'Crow, #906' },
    {
      text: '"Remind your engineers to use coasters on me."',
      author: 'Crow (as the Table). #504',
    },
    {
      text: "\"Right now the screen writers are asking themselves, 'Have we had a boat chase yet?' 'Uh, no, I don't think so.'  'Well, should we look back at what we've written just to make sure, errr?' 'I think I'd remember if we'd done that.' 'Well, then if we haven't done one then we should put one in: people love 'em.'\"",
      author: '#1008',
    },
    {
      text: '"Right now, Bennie Hill is smiling down from heaven."',
      author: 'Mike, #622',
    },
    { text: '"Riverdance...the strip show."', author: 'Tom. #812' },
    { text: '"Rodeos are the opiate of the masses."', author: 'Tom, #407' },
    { text: '"ROMPELO!"', author: 'M&tB, #811' },
    {
      text: "\"Rook came ashore last night. ... He's dead. / Or he's naked somewhere, I don't know. It's kinda leanin' that way.\"",
      author: 'Captain Thorne Sherman/Tom, #407',
    },
    {
      text: '"Roses are green, violets are red ... / I like to shoot heroin straight into my head."',
      author: 'Stoned Biker Guy/Joel, #209',
    },
    {
      text: '"Rowsdower...?" "Could you put out my head?"',
      author: 'Troy/Tom, #910',
    },
    {
      text: '"Saaaay... These Klan meetings have really lightened up!"',
      author: 'Tom, #204',
    },
    {
      text: '"Sadly, Sinbad wandered to the edge of the enchanted sea..." "...and had a magic B.M."',
      author: 'Narrator/Tom, #505',
    },
    {
      text: '"Sam, I\'m blown to several thousands bits, but I\'ve gotta finish these patent papers!"',
      author: 'Mike, #814',
    },
    {
      text: '"Sam, my patent papers are wondering why we stopped!"',
      author: 'Crow, #814',
    },
    {
      text: '"Sample my *fist*, you community theater reject!"',
      author: "TV's Frank. #402",
    },
    {
      text: '"Samuel Barber\'s Adagio for Young Japanese Children. [music stops abruptly and pointlessly] And it\'s gone."',
      author: 'Mike, #816',
    },
    { text: '"Sandcrabs are using their death ray!!"', author: 'Crow. #906' },
    { text: '"Santa\'s doing the forbidden dance!"', author: 'Crow. #521' },
    { text: '"SAT farms OF THE FUTURE!"', author: 'Crow, #822' },
    {
      text: '"Satan\'s gonna be peeved at the turn out, but he didn\'t do any mailing."',
      author: 'Crow, #806',
    },
    {
      text: '"Say, do you want to make people\'s heads explode? Sure, we all do."',
      author: 'Dr. Forrester, #511',
    },
    {
      text: '"Say, look at this: it says that Mike here is eight times a lady!"',
      author: 'Crow, #1004',
    },
    {
      text: '"Say, Mike, was there a Hitler Building where you grew up?"',
      author: 'Crow, #819',
    },
    {
      text: '"Science always seems to destroy nature instead of doing everything to protect it. It\'s up to biologists like us to warn the world what\'s happening around us." "Hey, save the message for the end of the movie!"',
      author: 'Young Japanese Biologist/Crow, #316',
    },
    {
      text: '"Scientists labor to find out what the hell is the deal with Japan."',
      author: 'Mike, #819',
    },
    {
      text: "\"'Scognamillo'? I think that's a triple word score in Scrabble.\"",
      author: 'Joel, #414',
    },
    {
      text: '"Search: celebrities, nude, Cameron Diaz."',
      author: 'Crow, #821',
    },
    {
      text: '"Seaworld: the International Marine Life Center. / Sushi lovers go straight to the source."',
      author: 'Narrator/Tom, #316',
    },
    { text: '"Security guards of foreign wars!"', author: 'Tom, #515' },
    {
      text: '"See now, a lot of villains aren\'t bold enough to wear clam diggers."',
      author: 'Mike, #515',
    },
    {
      text: '"See what you can do about making this lobby spookier."',
      author: 'Crow, #413',
    },
    { text: '"See, *this* should have a brain in it."', author: 'Mike, #516' },
    {
      text: "\"See, I could point out that this isn't the future and it's not a war, but, you know me, I don't like to complain.\"",
      author: 'Crow, #1004',
    },
    {
      text: '"See, the way I figure it, I got a monkey: why not develop a space program around that monkey?! No reason at all!"',
      author: 'Pearl, #906',
    },
    { text: '"See? Crackers and hicks can get along."', author: 'Crow, #814' },
    {
      text: '"See? If he\'d put the club on his soul this would not have happened."',
      author: 'Crow, #1001',
    },
    {
      text: '"Seeing these guys it\'s hard to believe human beings ever have sex."',
      author: 'Crow, #816',
    },
    { text: '"Sentence fragments... just phrases!!"', author: 'Crow, #907' },
    {
      text: '"Servo, you\'re gonna die in space." "Crow! ... He might not."',
      author: 'Crow/Gypsy, #518',
    },
    {
      text: '"Shall I sing you to sleep after the truckin\' ..."',
      author: 'Crow, #814',
    },
    { text: '"Shame fuels the economy!"', author: 'Dr. Forrester. #505' },
    {
      text: '"She dusted herself with Super Weight Gain Powder to attract him."',
      author: 'Crow, #820',
    },
    {
      text: '"She just has those glasses so she can pull them off dramatically."',
      author: 'Tom, #403',
    },
    { text: '"She just put her brain in."', author: 'Crow, #419' },
    {
      text: '"She left him to go back to the house for a few minutes ... / Then a lion jumped out and shot her, at least that\'s what Eric said."',
      author: 'Reverend Snow/Crow, #912',
    },
    {
      text: '"She looks like a guy dressing up like Diana Ross."',
      author: 'Crow, #705',
    },
    {
      text: '"She passed out from sheer length of the film!"',
      author: 'Joel, #306',
    },
    {
      text: '"She really likes me! Really...sure, I hypnotized her a little to part...but I can tell she really likes me!!"',
      author: 'Tom, #818',
    },
    {
      text: '"She took off her dress and now she\'s less nude than she was before!"',
      author: 'Tom, #805',
    },
    {
      text: '"She turned into a slightly overdone potroast."',
      author: 'Crow. #806',
    },
    {
      text: '"She wouldn\'t have me on a silver platter." "How about on an air mattress slathered with butter?"',
      author: 'Major Moore/Tom, #109',
    },
    {
      text: "\"She'd really been slipping lately, just off in another world. Like last week, she said, 'Clayton, where's the coat rack?' Ha ha ha, 'Where's the coat rack?' We don't even have a coat rack! Ha ha ha...Well, I guess we do, but...\"",
      author: 'Dr. Forrester, #705',
    },
    {
      text: '"Sheriff, if your stomach can handle it... / I\'d like to show you my vasectomy scar."',
      author: 'Vance/Mike, #810',
    },
    {
      text: '"Sheriff, I\'ve just discovered something terrifying! / Carol Channing nude!"',
      author: 'Doctor West/Crow, #911',
    },
    {
      text: '"She\'s a prostitute. You can\'t really shame her, okay?"',
      author: 'Joel. #509',
    },
    {
      text: '"She\'s calling Kleenex Consumer Complaints." "Uh, yes, ma\'am: one kind has lotion in it, the other has nitroglycerine, yes."',
      author: 'Mike/Tom, #814',
    },
    {
      text: '"She\'s dressed like Minnie Mouse, only many times HOTTER!"',
      author: 'Tom, #601',
    },
    {
      text: '"She\'s driving, so, technically, she\'s an accessory to her own kidnapping."',
      author: 'Mike, #608',
    },
    {
      text: '"She\'s got an armadillo down \'er trousers!"',
      author: 'Crow, #820',
    },
    {
      text: '"She\'s got her radio tuned to the Marching Band Station."',
      author: 'Mike. #517',
    },
    {
      text: '"She\'s having a wonderful dream of owning her very own set of pants."',
      author: 'Crow, #818',
    },
    {
      text: '"She\'s the kind of girl you bring home to Mother... if Mother is a cigaretty, retired hooker."',
      author: 'Tom, #907',
    },
    {
      text: '"She\'s the most masculine man I\'ve ever seen."',
      author: 'Crow, #812',
    },
    {
      text: '"She\'s wearing a gownless evening strap!"',
      author: 'Crow, #515',
    },
    {
      text: '"Shmook? Trunk? Zip? I\'m afraid! ... Chunks? Krull? Phlegm? Tworque? Brian?!"',
      author: 'Tom, #705',
    },
    {
      text: '"Shots ring out, but the people of Bosnia bravely go on with their peep shows!"',
      author: 'Crow, #812',
    },
    {
      text: '"Should I go put a horse\'s head in my bed again?"',
      author: 'Crow, #204',
    },
    {
      text: '"Should I spit on your dice now ... er not?"',
      author: 'Gypsy, #515',
    },
    {
      text: '"Shouldn\'t we be carefully placing these comic books in plastic bags?" "No, we have lives."',
      author: 'Crow/Tom, #403',
    },
    { text: '"Shut up! The MEN are talking in here!"', author: 'Mike, #610' },
    {
      text: '"Shut up, Frank, or I\'ll let the dog play with you."',
      author: 'Dr. Forrester. #415',
    },
    {
      text: '"Shut your mouths!" "I\'m just talking about Joel."',
      author: 'Joel/Tom, #310',
    },
    {
      text: '"Sign there, boy; I\'ll make you a star." "Eat me, you fuzzy marshmallow."',
      author: 'Bulbous Cracker Sheriff/Mike, #706',
    },
    {
      text: '"Since I\'m Bob Villa I won\'t return your greeting."',
      author: 'Mike, #1001',
    },
    { text: '"Since when did NASA paint anything red?"', author: 'Tom, #401' },
    { text: '"Sir Bob of Packwood."', author: 'Crow, #806' },
    { text: '"Sir Gull of Ble."', author: 'Crow, #806' },
    {
      text: '"Sir, we both know that there\'s only one man on this ship who\'s capable ... of combat. Who\'s had the training physically and mentally." "...All right. / Fetch me my warrior muumuu."',
      author: 'Captain Deevers/Admiral Jansen/Crow, #820',
    },
    {
      text: '"Sir... Sir, we may be underwater, but we still have standards."',
      author: 'Crow. #505',
    },
    {
      text: '"Sirs, when are you going to realize that when you kill each other you\'re only hurting yourselves?"',
      author: 'Joel, #511',
    },
    {
      text: '"Sister, that dress is headed for trouble and it\'s taking you with it."',
      author: 'Crow. #316',
    },
    { text: '"Sixteen men on a dead Dodge Dart!"', author: 'Mike, #821' },
    {
      text: '"Slab Bulkhead. Fridge Largemeat! Punt Speedchunk! Butch Deadlift! Bold Bigflank! Splint Chesthair! Flint Ironstag! Bolt Vanderhuge! Thick McRunfast! Blast Hardcheese! Buff Drinklots! Trunk Slamchest! Fist Rockbone! Stump Beefgnaw! Smash Lampjaw! Punch Rockgroin! Buck Plankchest! Stump Chunkman! Dirk Hardpec! Rip Steakface!  Slate Slabrock! Crud Bonemeal! Brick Hardmeat! Rip Slagcheek! Punch Sideiron! Gristle McThornbody! Slate Fistcrunch! Buff Hardback! Bob Johnson!...Oh, wait. Blast Thickneck! Crunch Buttsteak! Slab Squatthrust! Lump Beefbroth! Touch Rustrod! Reef Blastbody! Big McLargehuge! Smoke Manmuscle!  Beat Punchbeef! Hack Blowfist! Roll Fizzlebeef!"',
      author: '#820',
    },
    { text: '"Slick as a slab of Canadian bacon."', author: 'Crow, #821' },
    { text: '"Smurf urine."', author: 'Mike, #814' },
    { text: '"So ... it\'s an armed Levis ad?"', author: 'Crow, #821' },
    {
      text: '"So convenient to have a Hostage Inn right near the airport."',
      author: 'Mike, #614',
    },
    {
      text: '"So Corman did Swamp Diamonds on Monday, and this one on Wednesday?"',
      author: 'Crow, #511',
    },
    {
      text: '"So far his greatest adversary is a ladder."',
      author: 'Crow, #705',
    },
    {
      text: '"So he kills a dear, he tans the hides, he stretches the skins, he makes an anodized aluminum frame, he learns how to extrude and weld, all in about five minutes"',
      author: 'Tom, #301',
    },
    {
      text: '"So he\'s really a serial killer sanctioned by the government."',
      author: 'Mike, #815',
    },
    {
      text: '"So how much penance does this movie count for? A couple of years?"',
      author: 'Tom, #612',
    },
    {
      text: '"So I wonder what the flaw was that kept Maltin from giving this the full three stars."',
      author: 'Mike, #706',
    },
    {
      text: '"So many children in needy families. Really bugs me."',
      author: 'Mike, #610',
    },
    {
      text: '"So many people poured their entire weekend into the making of this movie."',
      author: 'Mike. #815',
    },
    {
      text: '"So the aliens come and strangle little kids; thank you, Japan!"',
      author: 'Tom, #819',
    },
    {
      text: '"So the message is, ladies, if your husband becomes a bat, abandon your career and become one too."',
      author: 'Tom, #1010',
    },
    {
      text: '"So they put a tiny bit of movie in a box and then just filled the rest with a bunch of foam peanuts." "This, for example, this scene is a foam peanut. Maybe two."',
      author: 'Mike/Crow, #912',
    },
    {
      text: '"So this guy comes in, stops the plot cold, then leaves??"',
      author: 'Crow. #510',
    },
    { text: '"So, aging lesbian nuns run the future."', author: 'Tom, #822' },
    {
      text: '"So, does this qualify as \'getting lucky\' for either of them?"',
      author: 'Tom, #818',
    },
    {
      text: '"So, every time I eat a burger, an acre of this stuff disappears? I don\'t understand..."',
      author: '#203',
    },
    {
      text: '"So, fifty years from now it\'ll be three years from now."',
      author: 'Tom, #821',
    },
    {
      text: '"So, for some reason, Vermont became the epicenter of the dystopia?"',
      author: 'Tom, #821',
    },
    {
      text: '"So, how many hours have rotary phones added to movies over the years?"',
      author: 'Tom, #818',
    },
    {
      text: '"So, in a way, pain is good. Otherwise, we might be hurt and not know it and die during social studies."',
      author: 'Tom, #409',
    },
    {
      text: '"So, in the end Satan wins?" "Yep, pretty much a shutout for Satan."',
      author: 'Tom/Mike, #908',
    },
    {
      text: '"So, in the future there is absolutely no shame."',
      author: 'Mike. #820',
    },
    {
      text: '"So, in the future, kids become gay agents?"',
      author: 'Crow, #821',
    },
    { text: '"So, it\'s an armed Levi\'s ad."', author: 'Crow. #821' },
    {
      text: '"So, Morrissey ... how ya doin\'?" "He hurt me with that remark. Did I mention that I cried?"',
      author: 'Frank/Morrissey, #403',
    },
    {
      text: '"So, Ray Dennis Steckler just filmed an open stage night and made it half his movie!"',
      author: 'Mike, #812',
    },
    { text: '"So, she\'s a narcoleptic somnambulist?"', author: 'Crow, #805' },
    {
      text: '"So, the afterlife is just vague, menacing dopes from our own lives? That\'s the ultimate answer?"',
      author: 'Crow, #1001',
    },
    {
      text: '"So, the aliens have been sent to Earth to acquire righteous vans."',
      author: 'Tom, #706',
    },
    {
      text: '"So, the hero of our movie has to go hire another hero?"',
      author: 'Mike, #705',
    },
    {
      text: '"So, the point of this short is that we should dabble in mushroom growing?"',
      author: 'Crow, #610',
    },
    {
      text: '"So, the rest of the movie is just watching them all get shot, one by one?" "We may enjoy it."',
      author: 'Tom/Crow, #619',
    },
    {
      text: '"So, there\'s a subplot about a horseshoe, then?..."',
      author: 'Crow. #515',
    },
    { text: '"So, they glued wings to a fuel filter."', author: 'Crow, #819' },
    {
      text: '"So, this is public television, huh? Suddenly I feel like beatin\' the crap out of Fred Rogers."',
      author: 'Crow, #822',
    },
    {
      text: '"So, we\'re just watching someone fritter away their afternoon here?"',
      author: 'Tom, #910',
    },
    {
      text: '"So, what does a mushroom eat for hallucinations?"',
      author: 'Crow. #813',
    },
    {
      text: '"So, what, are we about a half hour into this movie?" "No, I\'m afraid not. It\'s more like a minute."',
      author: 'Tom/Joel, #424',
    },
    {
      text: '"So, when Joe went into the employment agency, did he say, \'I like driving women wrestlers to the country and looking at their breasts\'?"',
      author: 'Mike, #616',
    },
    {
      text: '"So, you can just take a sharp left turn in space."',
      author: 'Crow. #902',
    },
    { text: '"So, your company\'s called Evil Co., eh?"', author: 'Tom, #814' },
    { text: '"So...aging lesbian nuns run the future."', author: 'Crow. #822' },
    {
      text: '"So...apparently, the director has a girlfriend."',
      author: 'Mike. #605',
    },
    { text: '"Society didn\'t give me enough bullets!"', author: 'Tom, #522' },
    {
      text: '"Some monsters just should not wear belts."',
      author: 'Crow, #816',
    },
    {
      text: '"Someone with a really big butt sat there."',
      author: 'Tom, #101',
    },
    { text: '"Someone\'s rubbing puppets on us!"', author: 'Crow, #907' },
    {
      text: '"Something there is that doesn\'t love a crummy monster film..."',
      author: 'Tom, #303',
    },
    {
      text: '"Sometimes I worry that my lamps aren\'t feminine enough."',
      author: 'Crow, #805',
    },
    {
      text: '"Somewhere behind that tree there\'s a scene happening."',
      author: 'Crow, #619',
    },
    {
      text: '"Somewhere there\'s a clown missin\' a sleeve."',
      author: 'Crow, #403',
    },
    { text: '"Somewhere, a deer is smiling."', author: 'Mike. #522' },
    { text: '"Sorry about my face!"', author: 'Joel, #506' },
    {
      text: '"Sorry about that electrician crack earlier. My father\'s village was attacked by electricians."',
      author: 'Mike, #911',
    },
    {
      text: '"Sorry about the costume. Corman\'s poodle died, and he doesn\'t like to waste anything."',
      author: 'Crow, #317',
    },
    {
      text: '"Sorry I scared you. You looked so angry I thought I was going to get strangled. "Well, there\'s still time for that."',
      author: 'Tammy/Crow, #310',
    },
    {
      text: '"Sorry, folks. Should\'ve told you about the subplot. It even caught me, the narrator, off guard."',
      author: 'Mike, #606',
    },
    {
      text: '"Sorry, I\'m gonna have to impale you, that\'s my job..."',
      author: 'Joel, #213',
    },
    {
      text: '"Sound? Are you getting this? Well, we\'ll move on, we\'ve got a lot of other lines."',
      author: 'Mike. #812',
    },
    {
      text: '"Sounds like the foley guy\'s frying up a burger."',
      author: 'Crow. #404',
    },
    {
      text: '"Sounds like your monster reads Frost." "Yeah, and he sprays it like icy death from his bloody stumps!"',
      author: 'Joel/Crow #212',
    },
    {
      text: '"Soundtrack available on RSO Records, or hit the demo button on any Casio Keyboard!"',
      author: 'Joel, #401',
    },
    {
      text: '"Space Chief is actually Racer X, Speed\'s brother."',
      author: 'Mike, #819',
    },
    {
      text: '"Space Chief\'s off having a couple of sapporos with Jet Jaguar and Prince of Space."',
      author: 'Tom, #819',
    },
    { text: '"Space Feeb!"', author: 'Crow, #819' },
    { text: '"Space mimes. In color!"', author: 'Tom, #310' },
    {
      text: '"Spacemen just don\'t look at home in a breakfast nook."',
      author: 'Crow. #105',
    },
    { text: '"Spankin\' time!"', author: 'Joel. #319' },
    {
      text: "\"Speaking of conserving waste, just because your British pop sensation's career has gone stale doesn't mean he has to. That's why we've invented the Tupperware container to lock in pop star freshness.\"",
      author: 'Dr. Forrester, #403',
    },
    {
      text: '"Special agents are called in to slow the film down and grind it to a screeching halt..."',
      author: 'Tom, #205',
    },
    {
      text: '"Special effects by Industrial Light and Morons."',
      author: 'Mike, #820',
    },
    {
      text: '"Special effects by Industrial Light and Morons."',
      author: 'Mike. #820',
    },
    {
      text: '"Spill your drinks on me!" "No, they\'re four dollars each!"',
      author: 'Amy/Mike, #907',
    },
    {
      text: '"Splash me on in the morning, wear the great smell of *me* all day long."',
      author: 'Tom, #410',
    },
    { text: '"Spoken like a true fart."', author: 'Tom, #608' },
    {
      text: '"Stand by to jettison tail section..." "Oh, wowwww..."',
      author: "Hugh O'Brien/Joel. #201",
    },
    {
      text: '"Standard sitcom joke landing on runway seven..."',
      author: 'Crow. #610',
    },
    { text: '"\'Starring\' NO ONE!"', author: 'Crow, #424' },
    {
      text: '"Stay away from the fat man, Mister Fingal. / He smells like feet!"',
      author: 'The Place Guy/Crow, #822',
    },
    { text: '"STAY!"', author: 'Quentiss, #806' },
    {
      text: '"Steve\'s dead now. From here on in, Steve\'s death will be represented by the oboe."',
      author: 'Tom, #209',
    },
    {
      text: '"Still, this is easier to read than Wired magazine."',
      author: 'Crow. #822',
    },
    {
      text: '"Still, you could make a case that this is a better film than Roadhouse."',
      author: 'Crow, #907',
    },
    { text: '"Stock footage is hell."', author: 'Crow. #309' },
    { text: '"Stop in the name of Europe!"', author: 'Crow, #911' },
    {
      text: '"Stop it! No heaving, thrusting, or splaying!"',
      author: 'Crow, #811',
    },
    {
      text: "\"Stop saying 'whee!' Nobody says 'whee!'.\"",
      author: 'Tom, #506',
    },
    { text: '"Stop splaying!"', author: 'Tom, #821' },
    {
      text: '"Stop zooming in!  I have no emotions to show you!"',
      author: 'Mike. #819',
    },
    {
      text: '"Store this image away for a later nightmare!"',
      author: 'Tom, #422',
    },
    { text: '"\'Stunned\'?! He took six bullets!!"', author: '#205' },
    {
      text: '"Stupid Topo Gigio was stepping all over our lines, the bastard."',
      author: 'Crow, #818',
    },
    {
      text: '"Such a nice day and they\'re wasting it making this movie."',
      author: 'Crow, #810',
    },
    {
      text: '"Suddenly I have a refreshing mint flavor."',
      author: 'Tom, MST3K:TM',
    },
    {
      text: '"Sugar is the next best substitute for parental love!"',
      author: 'Dr. Forrester. #417',
    },
    { text: '"Super Cracker!"', author: 'Crow, #814' },
    { text: '"Suppository Man."', author: 'Tom. #819' },
    { text: '"Sure hope the liquor holds out."', author: 'Joel, #303' },
    {
      text: '"Sure, I\'ll go. / If I die it\'d be a boon to the movie!"',
      author: 'Jerry/Crow, #407',
    },
    {
      text: '"Sure, you buy a jet, but then you have to put in a long driveway."',
      author: 'Crow, #612',
    },
    {
      text: '"Tab Hunter: huntin\' down people who don\'t pay their tabs! Heh heh."',
      author: 'Crow, #614',
    },
    { text: '"Tab is just a specter in this film!"', author: 'Crow, #614' },
    {
      text: '"Take a long second and get used to this face!"',
      author: 'Crow, #612',
    },
    {
      text: '"Take care of my leather mug! Insult people from the suburbs!"',
      author: 'Tom, #913',
    },
    {
      text: '"Take her." "Here? In front of the circus people?"',
      author: 'Basil Rathbone/Crow, #411',
    },
    { text: '"Take us away, little shorts!"', author: 'Mike, #819' },
    { text: '"Taste it all: Diet Hemlock."', author: 'Joel, #502' },
    {
      text: '"Teat teat teat teat teat teat..."',
      author: 'Crow and Tom, #516',
    },
    {
      text: '"Technicolor wishes to apologize for its use in this film."',
      author: 'Tom, #814',
    },
    { text: '"Tell him I\'m smoking."', author: 'Tom, #106' },
    {
      text: '"Tell me again why they have an elaborate security system, but they don\'t *lock* anything?!"',
      author: 'Mike. #907',
    },
    {
      text: '"Tell me all about yourself." "Well, I don\'t have any bones, I\'m all cartilage..."',
      author: 'Sadie/Tom, #509',
    },
    {
      text: '"Tell me: what would you like the most right now?" "Well, it involves Jello ..."',
      author: 'Thorbella/Joel, #312',
    },
    {
      text: '"Tell you what, let\'s not fight, let\'s just shoot any girl we see." "Ok."',
      author: 'Crow/Mike, #610',
    },
    { text: '"Terror at sea level."', author: 'Tom, #614' },
    { text: '"Thank god methane rises!"', author: 'Crow. #608' },
    { text: '"Thank god we have RADAR!"', author: 'Mike, #522' },
    {
      text: '"Thank God! / And his son, Santa Claus!"',
      author: 'Mr. Tabana/Crow, #819',
    },
    {
      text: '"Thank me for flying Me Airline. Please check around my own seating area for any garments I may have stowed there."',
      author: 'Crow, #612',
    },
    {
      text: '"Thank you for ... not killing me." "They should make a Hallmark card for that."',
      author: 'Sister Ann/Crow, #1004',
    },
    { text: '"Thank you for being gutless."', author: 'Crow, #201' },
    { text: '"Thank you for extruding me."', author: 'Crow. #510' },
    {
      text: '"Thank you movie, but we\'d like to move on please."',
      author: 'Tom, #821',
    },
    {
      text: '"Thanks for annihilating everything I know!"',
      author: 'Crow, #210',
    },
    { text: '"Thanks for comforting me with a gun."', author: 'Crow, #406' },
    { text: '"Thanks for hurling that gas bomb at me!"', author: 'Joel, #416' },
    {
      text: '"Thanks, Bob. You\'re real gone." "Thanks. I\'m no expert, but I\'m pretty sure I\'m right here."',
      author: 'Janey/Crow, #112',
    },
    { text: '"That bear has Hammerpants on!"', author: 'Joel. #505' },
    { text: '"That bitch! I\'m the hostage!"', author: 'Crow, #614' },
    {
      text: '"That bra\'s about as sexy as a concrete abutment."',
      author: 'Mike, #616',
    },
    {
      text: '"That felt good... Now I\'m going to turn my daughter into a woodchuck."',
      author: 'Tom, #103',
    },
    {
      text: '"That guy looks like a chainsaw sculpture. / Tom Cruise IS Doctor John!"',
      author: 'Joel/Tom, #424',
    },
    {
      text: '"That guy\'s not even sexual enough to be a femme."',
      author: 'Mike. #522',
    },
    {
      text: '"That guy\'s uglier than a mud fence. He could make a train take a dirt road."',
      author: 'Tom, #107',
    },
    {
      text: '"That scene brought to you by the Superfluous Foundation."',
      author: 'Crow, #912',
    },
    {
      text: '"That shirt just screams \'British advertising executive\'."',
      author: 'Mike, #515',
    },
    {
      text: '"That square bugs me... he REALLY BUGS ME!!..."',
      author: 'Banjo. #207',
    },
    {
      text: '"That was a long, dull scene.  Congratulations."',
      author: 'Tom, #702',
    },
    {
      text: '"That was the sound of the director getting up and leaving."',
      author: 'Crow, #904',
    },
    {
      text: '"That\'s a great stew. What\'s in it? / Oh, a lot of things... / Rattlesnake, Velveeta... / Chicken... corn... green peppers... chili... [sighs] onions... uh... / Hair..."',
      author: '#1007',
    },
    {
      text: '"That\'s a pretty complex insult, for a drunk."',
      author: 'Crow. #407',
    },
    { text: '"That\'s me, except I\'m moist."', author: 'Tom, #420' },
    {
      text: '"That\'s right, Captain Thorne. And if you\'d stuck with your rowboat and played captain instead of trying to play detective you wouldn\'t have to worry about how many were out there, would you?" "That\'s a pretty complex insult for a drunk."',
      author: 'Jerry Farrell/Crow, #407',
    },
    {
      text: '"That\'s the most casually dressed monster I\'ve ever seen."',
      author: 'Joel. #103',
    },
    { text: '"The *most* face ANY screen has held."', author: 'Tom, #822' },
    {
      text: '"The aftermath of the great Snowball Wars of 1955. / Ice balls are now outlawed by the Geneva Convention."',
      author: 'Tom/Mike, #804',
    },
    {
      text: '"The aorta makes an excellent sock puppet, and from here on will be represented by the tympani."',
      author: 'Crow, #206',
    },
    {
      text: '"The average warrior from hell consumes three times his weight in insects every day."',
      author: 'Crow, #703',
    },
    { text: '"The awesome power of Absorbine Senior!"', author: 'Tom, #403' },
    {
      text: '"The best beach party ever was the one at Normandy!"',
      author: 'Frank. #204',
    },
    {
      text: '"The body sees a hernia as a series of ones and zeroes..."',
      author: 'Tom, #413',
    },
    { text: '"The boiler room has a receptionist?"', author: 'Mike, #820' },
    {
      text: '"The Cessna Skyhawk *is* usually your best choice for bombardiering missions..."',
      author: 'Tom, #108',
    },
    {
      text: '"The Cheese Phone is back! And it\'s red and angry."',
      author: 'Crow, #403',
    },
    { text: '"The Church of Science!"', author: 'Mike, #906' },
    {
      text: '"The computer sucked everything in!" "\'Sucked.\' Let\'s go with that word, movie!"',
      author: 'Doctech/Crow, #822',
    },
    { text: '"The cops ate my Chiclets!"', author: 'Joel, #419' },
    { text: '"The Corn Palace is built!"', author: 'Crow. #804' },
    {
      text: '"The Diamond Anniversary Ring. Tell her you\'d marry her right out of high school all over again."',
      author: 'Mike, #810',
    },
    {
      text: '"The diet consists mostly of small insects such as leaf hoppers, grasshoppers, caterpillars, and others. A substantial part of the diet may also include small frogs and lizards. The female is larger than the male and invariably destroys her mate when he\'s fulfilled his function in life. / Oh, sorry, this is about Ukrainians."',
      author: 'Dr. Ned Jackson/Mike, #804',
    },
    {
      text: '"The director got to borrow his cousin\'s dolly for the week."',
      author: 'Mike. #904',
    },
    {
      text: '"The director\'s vision: lots of shots of things."',
      author: 'Mike, #911',
    },
    {
      text: '"The doctor should have been more specific when he told her to get plenty of fluids."',
      author: 'Mike, #810',
    },
    {
      text: '"The Dog\'s meat, have you seen it?"',
      author: 'Mrs. Hargrove, #905',
    },
    {
      text: '"The drugs from the Chemical Wonderland start to kick in..."',
      author: 'Crow (as the Narrator). #419(tRS)',
    },
    {
      text: '"The Duluth Chamber of Commerce bids you adieu."',
      author: 'Mike. #617',
    },
    {
      text: '"\'The End?\'? Yes! No! Uh, I wanna change my answer!" "No, always wonder."',
      author: 'Crow/Joel, #424',
    },
    {
      text: '"The first 10,000 lucky kids get slide whistles, and the second 10,000 lucky kids get real brass knuckles. Then, the first 10,000 lucky kids become unlucky..."',
      author: 'Dr. Forrester. #509',
    },
    {
      text: '"The first day we met I knew that there was something different about you. You know what it is? Your eyes. So alive! / Your nose. So truncated!"',
      author: 'Richard/Crow, #811',
    },
    {
      text: '"The first director to realize the dramatic potential of a rectal thermometer!"',
      author: 'Mike, #606',
    },
    { text: '"The first test of the female condom!"', author: 'Crow, #814' },
    {
      text: '"The first year of bondage is the hardest."',
      author: 'Crow, #515',
    },
    {
      text: '"The fountain will spray out a substance which resembles blood, both in smell and in taste." "Who tested it?"',
      author: 'Chuchumi/Tom, #308',
    },
    { text: '"The four dullards of the apocalypse."', author: 'Tom, #416' },
    { text: '"The future means cheesy graphics."', author: 'Tom, #821' },
    {
      text: '"The gods were supposed to help me move last weekend!!"',
      author: 'Tom, #605',
    },
    {
      text: '"The implications are staggering! The most temporal social intercourse with ones peers might escalate to the degree that neither party knows what of the other speaks, thus profoundly affecting interpersonal relationships and the resulting of that could change the entire social fabric of modern civilization! Really makes you think, don\'t it?" "No."',
      author: 'Tom/Crow, #413',
    },
    { text: '"The Japanese Army plays freeze tag."', author: 'Mike, #819' },
    {
      text: "\"The 'K' stands for 'Karl'. Karl was the man who invented lightning.\"",
      author: 'Tom, #518',
    },
    {
      text: '"The King of the Apes rides in style in the new Buick Skylark."',
      author: 'Tom, #306',
    },
    {
      text: '"The land they lived in was large and beautiful. / As were the women."',
      author: 'Narrator/Tom, #422',
    },
    { text: '"The mean streets of Appleton."', author: 'Crow. #618' },
    {
      text: '"The medical definition of this is \'spaz attack.\'"',
      author: 'Crow, #809',
    },
    {
      text: '"The modern bachelor lifestyle: flying to work ... or play!"',
      author: 'Tom, #821',
    },
    {
      text: '"The monster can destroy everything with its tongue. / *You* try saying that without laughing."',
      author: 'Japanese General/Crow. #304',
    },
    {
      text: '"The movie that dares to ask the question \'Will he find the dwarf?\'."',
      author: 'Tom, #813',
    },
    {
      text: '"The movie that takes the bold step of not including the audience."',
      author: 'Mike, #810',
    },
    {
      text: '"The music, the mood, the sweeping romance of peeping."',
      author: '#805',
    },
    {
      text: '"The music\'s terrible, but at least it\'s drowning out the dialogue."',
      author: 'Crow, #515',
    },
    { text: '"The night, the monster, and thou."', author: 'Crow, #423' },
    {
      text: '"The old man calls it his *combat rod*? That\'s disgusting!!"',
      author: 'Tom, #902',
    },
    {
      text: '"The only consolation is that they\'re all dead now!"',
      author: 'Crow, #421',
    },
    {
      text: '"The other day I said the word *damn*!! Huh, huh, huh!!"',
      author: 'Crow, #818',
    },
    {
      text: '"The Pant Association urges you to wear pants at least three times a day!"',
      author: 'Joel. #410',
    },
    { text: '"The plot\'s totally crashing."', author: 'Crow, #806' },
    {
      text: '"The Pony Express: when it absolutely, positively has to be there in three or four months or so."',
      author: 'Tom, #511',
    },
    {
      text: '"The potential for something to happen is very high right now."',
      author: 'Mike, #612',
    },
    {
      text: '"The problem of communicating with the departed... / is that they\'re dead."',
      author: 'Krasker/Tom, #603',
    },
    { text: '"The rare happy German."', author: 'Tom, #818' },
    {
      text: '"The reason Miss van Horn is making this trip is because of her pioneering research with monatomic hydrogen. / And, she\'s cute as a button."',
      author: "Hugh O'Brien/Crow. #201",
    },
    {
      text: '"The rodent kingdom mourned the loss of the rat queen, today."',
      author: 'Tom, #705',
    },
    {
      text: '"The sad thing is they\'re all former prostitutes."',
      author: 'Crow, #1004',
    },
    {
      text: '"The sad thing is they\'re trying to tap dance! [forced laughter] Kill me."',
      author: 'Tom, #515',
    },
    {
      text: '"The sides of his heart are blowing out like old tires ..."',
      author: 'Mike, #910',
    },
    {
      text: '"The Spanish named it Puerto Rico." "They couldn\'t think of anything else."',
      author: 'Narrator/Mike, #621',
    },
    {
      text: '"The sphere is turning red!" "Well, wouldn\'t yours?"',
      author: 'Orloff/Tom, #211',
    },
    {
      text: '"The St. Louis Parrots\' mascot was burned to death today."',
      author: 'Tom. #701',
    },
    {
      text: '"The Stay Puff Marshmallow Man in chains?!"',
      author: 'Joel, #422',
    },
    { text: '"The Syren song of seventies hits."', author: 'Mike, #706' },
    { text: '"The unspoken language of bald men!"', author: '#418' },
    { text: '"The Very Large Corporation of America."', author: 'Tom, #705' },
    {
      text: '"The White Rock Cafe, where whitey comes to drink and dance!"',
      author: '#418',
    },
    {
      text: '"The whole movie was leading up to this shot! / Brought to you by the Breast Council. / Buy breasts where you work or bank!"',
      author: 'Crow/Mike/Tom, #805',
    },
    {
      text: '"The wrestling program in hell is second only to Iowa."',
      author: 'Crow. #624',
    },
    {
      text: '"Their chances of being cool are ruined for life."',
      author: 'Crow. #320',
    },
    {
      text: '"There are no small laws, just small criminals."',
      author: 'Tom, #419',
    },
    {
      text: '"There are times when a man needs the touch of a llama."',
      author: 'Crow, #606',
    },
    {
      text: '"There aren\'t enough bell towers in the world for Johnny."',
      author: 'Mike, #515',
    },
    { text: '"There goes a deeply mediocre man."', author: 'Mike, #806' },
    {
      text: '"There goes another one! Man, the pool cue budget must be enormous!"',
      author: 'Mike, #904',
    },
    {
      text: '"There goes the dumbest knight if the realm."',
      author: 'Crow, #806',
    },
    {
      text: '"There is an audible thud every time he tells a joke."',
      author: 'Crow. #610',
    },
    {
      text: '"There is just plain no need for sex. / We\'ll all be just fine without it, dear."',
      author: 'Mike/Crow, #606',
    },
    { text: '"There is no God, there\'s just dude."', author: 'Tom, #1001' },
    {
      text: '"There is no way out of here. It\'ll be dark soon. There is no way out of here. / It\'ll be dark soon."',
      author: 'Torgo/Joel, #424',
    },
    {
      text: '"There is one terrifying word in the world of nuclear physics ... / \'Oops.\'"',
      author: 'Narrator/Tom, #421',
    },
    {
      text: '"There were a lot of forensics going on in Kansas in the fifties."',
      author: 'Crow, #619',
    },
    {
      text: '"There!  That was terror!  Thanks for waiting!"',
      author: 'Mike, #807',
    },
    {
      text: '"There! There! You ever heard her sing about evil superconsciousnessesses swallowing us whole?" "Well, maybe you have a point, Mike." "Nah, I\'ve heard her sing about that stuff lots of times."',
      author: 'Mike/Tom/Crow, #810',
    },
    {
      text: '"There! Your announcer feels vindicated. This is extremely horrible!"',
      author: 'Tom, #421',
    },
    {
      text: '"There, now you look like Admiral Perry playing the Elephant Man."',
      author: 'Tom, #303',
    },
    {
      text: '"There\'re evil voices, too. Sometimes they\'re very hard to resist." "Well, just don\'t listen to talk radio."',
      author: 'Jessica/Tom, #805',
    },
    { text: '"There\'s a bonus if you get killed."', author: 'Joel, #109' },
    {
      text: '"There\'s a bunch of armed nuns on jet skis out there waiting for him."',
      author: 'Mike, #1008',
    },
    {
      text: '"There\'s a dangerous death ray situation on the outbound 94...you might want to take an alternate route."',
      author: 'Mike. #620',
    },
    { text: '"There\'s a peeping Zorro outside."', author: 'Mike, #515' },
    {
      text: '"There\'s a white trash spill on Old Possum Junction."',
      author: 'Crow, #810',
    },
    {
      text: '"There\'s absolutely no reason yet to fear the worst. Until now, we only know that the plane caught fire and that we\'ve lost radio contact." "So there\'s absolutely nothing to be worried about."',
      author: 'Agent/Mike, #1011',
    },
    {
      text: '"There\'s another story behind most accidents. / Communism."',
      author: 'Reverend/Tom, #623',
    },
    {
      text: "\"There's just something about that guy that screams 'I'm not really a cop'.\"",
      author: 'Crow. #207',
    },
    { text: '"There\'s no steam in a vaccuum!"', author: 'Tom, #524' },
    {
      text: "\"There's one good thing: we met Pepe. I don't think I'll ever forget her.\" \"I don't think you'll ever get the smell out of the seats, either.\"",
      author: 'Catherine/Tom, #306',
    },
    {
      text: "\"There's somethin' so dark in you sometimes. It just sends shivers up my spine. / Like, when you crank Don't Fear The Reaper.\"",
      author: 'Cathy/Mike, #706',
    },
    {
      text: '"These are just the drunks, they haven\'t even gotten to the wounded yet."',
      author: 'Crow, #810',
    },
    {
      text: '"These credits give [you] a chance to finish your popcorn, talk a little, ... decide to see another movie."',
      author: 'Mike, #706',
    },
    { text: '"These guys are aggressively incompetent."', author: 'Tom, #406' },
    {
      text: "\"These two girls they make quite a pair/ They both come from your worst nightmare/ They will haunt your soul forever/ And now, when you see pink, you're gonna think, 'We're doomed!'/ They are agents of Satan...\"",
      author: 'Tom, #421',
    },
    {
      text: '"They got off on the second floor; they\'re masters of escape!"',
      author: 'Mike, #821',
    },
    {
      text: '"They had to call in the Volunteer Filmmakers Squad to end the movie."',
      author: 'Tom, #619',
    },
    {
      text: '"They just put a bunch of movies in a blender and pressed the \'mix\' button!"',
      author: 'Crow, #515',
    },
    { text: '"They killed H.R. Puffinstuff!"', author: 'Joel. #317' },
    {
      text: '"They made love in their Chevy van and it\'s *not* allright with me!"',
      author: 'Tom, #907',
    },
    {
      text: '"They really had no idea what this movie was about, did they?"',
      author: 'Mike, #804',
    },
    {
      text: '"They say you never hear the snack with your name on it."',
      author: 'Crow. #319',
    },
    { text: '"They shot my spare turtlenecks!"', author: 'Joel, #305' },
    { text: '"They shot the asthmatic Nazi!"', author: 'Crow, #705' },
    {
      text: '"They should just put booze in squeeze bottles."',
      author: 'Crow, #802',
    },
    {
      text: '"They somehow gave her plaid theme music."',
      author: 'Crow, #821',
    },
    {
      text: '"They tried to resell this as a Hallmark Christmas Special when it didn\'t get a theatrical release, you know."',
      author: 'Tom, #903',
    },
    {
      text: '"They were part of the second line of defense. / Also known as \'dessert\'."',
      author: 'Narrator/Crow. #606',
    },
    {
      text: '"They\'re doing it clown style!! Noooo!..."',
      author: 'Tom, #422',
    },
    {
      text: '"They\'re escaping under cover of afternoon in the largest car in the county!"',
      author: 'Tom, MST3K:TM',
    },
    {
      text: '"They\'re going to invoke the spirit of the continuity man."',
      author: 'Crow. #609',
    },
    { text: '"They\'re just pausing out of spite, now."', author: 'Tom, #908' },
    {
      text: '"They\'re like Klingons without the Kling."',
      author: 'Joel. #104',
    },
    { text: "\"They're listening to 'Kinda White.'\"", author: 'Crow, #809' },
    {
      text: "\"They're not dead, they're just 'metaphysically challenged'.\"",
      author: 'Tom, #411',
    },
    {
      text: '"They\'re not gonna let us drop the bomb! Don\'t answer it!"',
      author: 'Crow, #517',
    },
    {
      text: '"They\'re poor only in money, and spirit, and dignity, and moral fiber, and hygiene."',
      author: 'Mike, #810',
    },
    { text: '"They\'re roasting Bullwinkle!"', author: 'Joel. #422' },
    {
      text: '"They\'re trying to kill me! I guess I\'ll park."',
      author: 'Crow, #403',
    },
    {
      text: '"Think it\'s really a good idea to jingle *all* the way?"',
      author: 'Tom, #521',
    },
    {
      text: '"Think, Bob: throughout human history what has been the first activity of explorers of any new region?" "Genocide?" "Slavery?" "Diseased blankets, maybe?"',
      author: '#807',
    },
    {
      text: '"This always happens! People throw me out the window, tell me I\'m Pumaman, then leave!"',
      author: 'Crow, #903',
    },
    {
      text: '"This balance of power keeps ice cream in check."',
      author: 'Mike, #603',
    },
    {
      text: '"This band will appear at the Bombay Bicycle Club."',
      author: 'Crow. #820',
    },
    {
      text: '"This contract arrived for you from a Mr. Elzebub."',
      author: 'Tom, #515',
    },
    { text: '"This corn relish is radioactive!"', author: 'Joel, #106' },
    {
      text: '"This dance represents that there are no baked potatoes until after five."',
      author: 'Mike, #513',
    },
    {
      text: '"This delicious meal has a flat trajectory and a muzzle velocity of 2400 feet per second."',
      author: 'Dr. Forrester. #319',
    },
    {
      text: '"This dinosaur was found with a head sticking out of its butt!"',
      author: 'Mike, #904',
    },
    { text: '"This film dares you to watch it."', author: 'Crow, #619' },
    { text: '"This freezer RULES!"', author: 'Mike. #603' },
    {
      text: '"This goes out to ma friends in the posse comitatus!"',
      author: 'Mike, #814',
    },
    {
      text: '"This grainy photograph is the only direct evidence we have of a woman in her natural environment. The longer hair, the gentle and nurturing demeanor are typical of how witnesses describe their supposed encounters with women."',
      author: 'Crow, #907',
    },
    {
      text: '"This guy breasts the tape in front of Steve Alaimo for loathsomeness."',
      author: 'Tom, #706',
    },
    {
      text: '"This guy gets his kicks by calling about used Vegas. / Does it have three on the tree? Ooo ..."',
      author: 'Mike/Crow, #907',
    },
    {
      text: '"This guy leaves grease stains in the air as he walks."',
      author: '#808',
    },
    {
      text: '"This guy\'s real strength is that he\'s totally used to getting wailed on."',
      author: 'Crow, #821',
    },
    {
      text: '"This has been a test. Had this been an actual movie, you would have been entertained."',
      author: 'Tom, #421',
    },
    {
      text: '"This invasion is awfully sluggish." "It\'s more like a minor spider unrest."',
      author: 'Tom/Mike, #810',
    },
    { text: '"This is a strange remake of Jane Eyre."', author: 'Crow, #904' },
    {
      text: "\"This is an orchestral piece called 'Peoples' Court Suite'.\"",
      author: 'Mike, #810',
    },
    {
      text: '"This is Beverly Hills Cop, only the slow, white version."',
      author: 'Tom, #420',
    },
    {
      text: '"This is Dolby Stereo reminding you to leave the Bronx!"',
      author: 'Mike, #705',
    },
    {
      text: '"This is getting weird, you guys. There\'s a silhouette of theater seats behind that movie."',
      author: 'Joel. #317',
    },
    {
      text: '"This is gonna be the front page story in the Glenview Shopper."',
      author: 'Crow. #610',
    },
    {
      text: '"This is hard to watch because I care about them both so much." "I care about the boxes; why should innocent freight have to suffer?"',
      author: 'Crow/Mike, #1004',
    },
    {
      text: '"This is how much pure cocaine you would need to enjoy this movie."',
      author: 'Mike, #822',
    },
    {
      text: '"This is how they selected the jury for the Rodney King trial."',
      author: 'Crow. #505',
    },
    {
      text: '"This is it. The final game of the world series. And we\'re the Mariners."',
      author: 'Tom, #602',
    },
    {
      text: '"This is just like Aliens! Except ... without the underwear." "I like the underwear."',
      author: 'Crow/Tom, #407',
    },
    {
      text: '"This is just, plain crooked! I hate evil corporations that try and evacuate the Bronx and plant guns!"',
      author: 'Crow, #705',
    },
    {
      text: '"This is less subtle than an Annie Sprinkle performance piece!"',
      author: 'Mike. #820',
    },
    { text: '"This is like a Mentos ad!"', author: 'Mike. #515' },
    {
      text: "\"This is like an Obsession commercial: 'If I'm an ape ... then pronounce me guilty.'\"",
      author: 'Joel, #306',
    },
    {
      text: '"This is like Three Days of the Condor: I trust no one in this short!"',
      author: 'Mike, #610',
    },
    {
      text: '"This is one of those movies that\'s just as famous for its great soundtrack. / Yeah, Hobgoblins is usually on a double bill with The Harder They Come. ... Who am I kidding, no it isn\'t."',
      author: 'Crow/Tom, #907',
    },
    {
      text: '"This is one of those planned curling communities." "The teenage suicide capital of the world!"',
      author: 'Tom/Crow, #804',
    },
    {
      text: '"This is so almost Mitchell." "About fifty pounds short of Mitchell."',
      author: 'Tom/Crow, #608',
    },
    { text: '"This is so sick."', author: 'Joel, #506' },
    {
      text: '"This is so weird. You know, this happened to me on one of my first dates: grandma killed a guy. ... Dated the girl for awhile, though."',
      author: 'Mike, #908',
    },
    {
      text: '"This is South Carolina\'s national movie."',
      author: 'Mike, #814',
    },
    { text: '"This is the BEST TASTING SWORD EVER!"', author: 'Mike, #617' },
    { text: '"This is the drum solo of movie scenes."', author: 'Crow, #814' },
    {
      text: '"This is the fifties...why am I explaining things to a woman...get in the car."',
      author: '#802',
    },
    {
      text: '"This is the kind of padding I *like* to see in a film!"',
      author: 'Crow. #204',
    },
    {
      text: '"This is the largest single shipment of dollers ever made at six o\'clock in the morning." "There was a larger one at seven a.m. once."',
      author: 'Banker/Mike, #1013',
    },
    {
      text: '"This is the most dramatic asbestos suit scene I\'ve ever seen."',
      author: 'Joel, #421',
    },
    {
      text: '"This is the most emotion ever expressed in Sweden."',
      author: 'Joel. #422',
    },
    {
      text: '"This is the part where the Zagnut is fertilized by the salted nut roll..."',
      author: 'Joel. #413',
    },
    {
      text: '"This is what a buggy was made for! / It\'s swell for tearing up fragile ecosystems! Watch!"',
      author: 'Arch Hall Jr./Tom, #506',
    },
    {
      text: '"This is where you step back and say, \'Thank God for my exoskeleton!\'"',
      author: 'Crow, #517',
    },
    {
      text: '"This is wild! I\'ve never killed a guy like *this* before. Neat!"',
      author: 'Joel, #410',
    },
    {
      text: '"This isn\'t from America! Ha ha ha! It comes from the river. / I mean what?! Were you cloned from a sack of doorknobs?!"',
      author: 'Dick Sargent/Crow, #811',
    },
    {
      text: '"This isn\'t paper. It\'s some kind of metal." "Uh, no, that\'s paper, sir."',
      author: 'Cal/Mike, MST3K:TM',
    },
    {
      text: '"This looks like a fine place to set down my pasty white bottom."',
      author: 'Joel, #406',
    },
    {
      text: '"This looks like spermatozoa vacation pictures."',
      author: 'Joel. #405',
    },
    {
      text: '"This man must have led a very full and active life. / \'Cause he\'s got a squirrel in his stomach!"',
      author: 'Dr. Raeburn/Crow, #206',
    },
    { text: '"This movie has severe contraindications."', author: 'Tom, #612' },
    {
      text: '"This movie has taken the joy out of looking at someone\'s hinder!" "My loins will never stir again."',
      author: 'Tom/Crow, #616',
    },
    {
      text: '"This movie has the courage to unabashedly repeat itself."',
      author: 'Crow, #619',
    },
    {
      text: '"This movie is comprised strictly of second unit footage."',
      author: 'Mike, #706',
    },
    {
      text: '"This movie is giving me *current* traumatic stress disorder!"',
      author: 'Tom, #907',
    },
    { text: '"This movie is just ropes and asses!"', author: 'Mike. #803' },
    {
      text: '"This movie is just sitting on my head and crushing it."',
      author: 'Joel. #511',
    },
    {
      text: '"This movie isn\'t healthy for children and other living things."',
      author: 'Crow, #506',
    },
    {
      text: '"This movie just staggers from one commercial break to another!"',
      author: 'Mike, #614',
    },
    {
      text: '"This movie means two things to me: sheet cake and back fat."',
      author: 'Crow, #706',
    },
    {
      text: '"This movie was like spending the weekend with your dentist."',
      author: 'Crow. #207',
    },
    {
      text: '"This movie\'s giving me Current Traumatic Stress Disorder!"',
      author: 'Tom, #907',
    },
    {
      text: '"This music would work better with women in bikinis shaking it all over the place... I guess that\'s true of any music really..."',
      author: 'Crow, #1013',
    },
    {
      text: '"This scene has powerful asexual overtones."',
      author: 'Mike, #805',
    },
    {
      text: '"This trampoline accident could have been avoided. Never use a trampoline with unstable TNT in your pocket."',
      author: 'Crow, #705',
    },
    { text: '"This was *written*? I don\'t think so!"', author: 'Crow. #821' },
    {
      text: '"This was Jim Backus\'s first film after he died."',
      author: 'Crow, #622',
    },
    {
      text: '"This\'ll be no picnic." "More like a small dinner."',
      author: 'Joe/Tom, #310',
    },
    { text: '"Thong? No, it\'s a dance belt."', author: 'Tom, #301' },
    {
      text: '"Those of you who never associated sexuality with your great aunt: here it is."',
      author: 'Crow, #616',
    },
    {
      text: '"Thrill as he doesn\'t talk, and I don\'t move my lips."',
      author: 'Crow, #818',
    },
    {
      text: '"Thursday afternoon, I pulled up at this one place. I don\'t know what time it was. I kind of got out of my car. I looked at that place. I walked towards some sort of structure in which people possibly dwelled. I noticed the agriculture. Sound editor added a tasty sax lick. I passed a tree or shrub of some variety. Then I arrived at the door. I went through the door, it was quite cool. I had heard Batwoman had quite a lingerie collection; I decided to investigate."',
      author: 'Tom, #515',
    },
    { text: '"Time elapsed. Just take our word for it."', author: 'Tom, #518' },
    { text: '"Time for go to bed."', author: 'Tor Johnson. #320' },
    { text: '"Time to drown the lemur!"', author: 'Crow, #210' },
    { text: '"Time to mellow these turkeys out!"', author: 'Tom, #814' },
    {
      text: '"To escape this heinous living hell you have only one option: you must offer Gypsy to us in a pagan Earth ceremony, which we hold every Tuesday at eight!"',
      author: 'Crow, #414',
    },
    {
      text: '"To every Asian and every human being, we apologize for that last scene."',
      author: 'Mike, #515',
    },
    { text: '"To have said goodbye to things!"', author: 'AMC Crow, #913' },
    {
      text: '"To hell with you and your stupid wish to be protected!"',
      author: 'Crow, #1006',
    },
    {
      text: '"To me, that leash screams \'unhealthy relationship\'."',
      author: 'Crow. #515',
    },
    {
      text: "\"'To whom it may concern, I'm breaking up with you and burning down the warehouse.'\"",
      author: 'Tom, #814',
    },
    {
      text: '"Today is dedicated to Uranus." "Why, thank you, I..."',
      author: 'Ismene/Crow, #412',
    },
    {
      text: '"Today on Involuntary Cliff Diving, we\'ve got three contestants ..."',
      author: 'Crow, #306',
    },
    {
      text: '"Today, the moon narrowly missed hitting a man\'s eye like a big pizza pie." "Scientists believe, that\'s amore."',
      author: 'Crow./Tom, #902',
    },
    {
      text: '"Tom, I don\'t get you." "Nobody does. I\'m the wind, baby!"',
      author: 'Gypsy/Tom, #421',
    },
    {
      text: '"Tom?" "Yup." "I don\'t get you." "Nobody does: I\'m the wind, baby."',
      author: 'Gypsy/Tom, #421',
    },
    {
      text: '"Tommy, now you quit warping time and space this instant!"',
      author: 'Tom, #303',
    },
    {
      text: '"Tonight on \'Fischer Price Medical Center\'!"',
      author: 'Crow, #413',
    },
    { text: '"Tonight on \'Invisible Cracker Mom\'!"', author: 'Crow, #1005' },
    { text: '"Tonight: All Dogs Go to Hell!"', author: 'Joel, #407' },
    {
      text: '"Tonight: Manos gets caught in a deadly game of cat and mouse..."',
      author: 'Tom, #424',
    },
    {
      text: '"Torgo, you\'re missing the fight! Put your dress on and get in there!"',
      author: 'Tom, #424',
    },
    {
      text: '"Tornado Magnet Trailer Park welcomes you!"',
      author: 'Mike. #906',
    },
    { text: '"Tragedy tomorrow. Kabuki tonight!"', author: 'Tom, #310' },
    { text: '"Trains are blameless, holy creatures..."', author: 'Mike, #520' },
    { text: '"Tripping. Still works in the future!"', author: 'Joel. #417' },
    { text: '"Trumpy, you can do magic things!"', author: 'Tommy. #303' },
    {
      text: '"Tuesdays are human sacrifice day at the Sizzler."',
      author: 'Joel. #301',
    },
    { text: '"Turkeys, hoo!"', author: 'Mike, #814' },
    { text: '"Two words: Get over it!!"', author: 'Dr. Forrester. #414' },
    {
      text: '"Uh ... this scene was lit by an Indiglo watch, apparently."',
      author: 'Mike, #811',
    },
    {
      text: '"Uh oh! It\'s Prince! His Royal Purpleness!" "Those invaders must be caught! At this time you mustn\'t let them get away or I\'ll have you all shot!" "It is Prince."',
      author: 'Crow/District Leader/Crow, #306',
    },
    {
      text: '"Uh oh! The movie has a Surgeon General\'s warning!"',
      author: 'Crow, #610',
    },
    {
      text: '"Uh oh... <sniff sniff>... Smells like a montage."',
      author: 'Tom, #415',
    },
    {
      text: '"Uh oh...flyspecks...they\'ve been aiming."',
      author: 'Mike. #813',
    },
    {
      text: '"Uh, can we get back to our bleak, meaningless lives please?"',
      author: 'Tom, #603',
    },
    {
      text: '"Uh, could we get a table closer to the plot?"',
      author: 'Joel, #416',
    },
    {
      text: '"Uh, honey, I think we\'re growing apart. We don\'t have the same interests anymore. You want to conquer the world, I want to put a shop in the basement."',
      author: 'Joel, #311',
    },
    {
      text: '"Uh, is that poison gas part of the presentation?"',
      author: 'Crow. #608',
    },
    {
      text: '"Uh, Joel, I think the butter\'s slipped off your stack of waffles, buddy."',
      author: 'Crow. #317',
    },
    {
      text: "\"Uh, Joel, if you don't mind, I'm *cooking* here, I'm beginning to smell *delicious*...\"",
      author: 'Tom, #416',
    },
    {
      text: '"Uh, Mister B, what would *you* know about dignity?"',
      author: 'Joel. #319',
    },
    {
      text: '"Uh, Peggy, would you look up some numbers for me, please? / Two and nine."',
      author: 'Ambigender Doctor/Mike, #706',
    },
    {
      text: '"Uh, prepare to jettison the core!" "We don\'t have a core; we\'re all gonna die!" "[singing] Nearer my God to theeeeee!"',
      author: 'Mike/Tom/Crow, #705',
    },
    {
      text: '"Uh, so, she followed him, and he followed her, and now she\'s following him following her?"',
      author: 'Crow, #511',
    },
    {
      text: '"Uh, The Dead, could you turn your radio down please?"',
      author: 'Tom, #603',
    },
    {
      text: '"Uh, there. I\'ve broken the Hexfield. Carry on, I\'ll be in my ready room."',
      author: 'Mike, #1001',
    },
    { text: '"Uh, you\'re standing on his head, dear."', author: 'Tom, #511' },
    {
      text: '"Um ... was that part of the movie or did that actually happen?"',
      author: 'Tom, #515',
    },
    {
      text: '"Um, excuse me, were we supposed to do some readings for this movie?"',
      author: 'Mike, #822',
    },
    {
      text: '"Um, how come your so old, dad? Did you have me when you were, like, sixty er somethin\'?"',
      author: 'Crow, #816',
    },
    {
      text: "\"Um, Mike, if you're ever this family's secret Santa, don't buy them pot holders.\"",
      author: 'Crow, #908',
    },
    {
      text: '"Um, should the sky have trees and houses and be rushing rights towards us?"',
      author: 'Crow, #821',
    },
    {
      text: '"Um...that\'s poison oak on your naughty bits, ma\'am..."',
      author: 'Crow. #822',
    },
    {
      text: '"Unbelievable: they\'ve managed to make the movie more irritating."',
      author: 'Crow, #819',
    },
    {
      text: '"Under NO circumstances look to the right, men!"',
      author: 'Mike, #705',
    },
    {
      text: '"Underneath those clothes he\'s as smooth as a Ken doll!"',
      author: 'Crow, #413',
    },
    {
      text: '"Underwater fights are like the drum solos of movies."',
      author: 'Crow, #911',
    },
    {
      text: '"Unfettered avarice courtesy of Madison Avenue!"',
      author: '#524',
    },
    { text: '"UPS fashions OF THE FUTURE!"', author: 'Crow, #822' },
    { text: '"Use *your own* magic bird!"', author: 'Tom Servo, #505' },
    {
      text: '"Use plenty of lip and tongue action!"',
      author: 'Prof. E.C. Buehler. #313',
    },
    {
      text: '"Usually, when my husbands died on the honeymoon, it was more mundane. Like Wendell here. Somebody shot him... Hmm, same thing with Felipe... And Maury. Somebody shoved hatpins through his eyes right before the ceremony. We still don\'t know who... Hey, sit down! You want me to marry you?!"',
      author: 'Pearl, #1010',
    },
    {
      text: '"Vandals came in and put skin on him!"',
      author: 'Joel, #309(tACM)',
    },
    {
      text: '"Venus! / You know, no arms, nice rack..."',
      author: 'Bad Guy/Tom, #311',
    },
    { text: '"Vermont! City on the go!"', author: 'Crow, #821' },
    { text: '"Vermont! The other, smaller Wisconsin!"', author: 'Crow, #821' },
    {
      text: '"Visit your Government Church! / First Federal Church, member FDIC."',
      author: 'Tom/Mike, #616',
    },
    {
      text: '"Wait there! We\'re coming to attack you!"',
      author: 'Crow, #406',
    },
    {
      text: '"Wait! I haven\'t read Tolkien in almost a week!"',
      author: 'Tom, #910',
    },
    {
      text: '"Wait, we can\'t hunt; we\'re not drunk enough!"',
      author: 'Tom, #810',
    },
    {
      text: "\"Wait, you can't use 'Amazing Grace' in a devil movie!\"",
      author: 'Crow, #908',
    },
    {
      text: '"Wait... he\'s alive, but his head\'s gone..."',
      author: 'Crow, #309',
    },
    {
      text: '"Wake up, you boozy, old sex fiend, they\'re gonna kill us all!"',
      author: 'Tom, #502',
    },
    {
      text: '"Was that a bad movie or was I just in the wrong movie?"',
      author: 'Tom, #811',
    },
    { text: '"Wasn\'t he caucasian earlier?"', author: 'Crow, #612' },
    { text: '"Watch out for snakes!"', author: 'Disembodied Voice. #506' },
    {
      text: '"Water! / That\'s what dried soup needs!"',
      author: 'Gary/Crow, #1010',
    },
    {
      text: '"We are not *murderers*!" "Sir? Uh, actually, we are."',
      author: 'Crassus/Mike, #516',
    },
    {
      text: '"We don\'t want you to pray *for* us, we want you to pray *to* us!"',
      author: 'Dr. Forrester. #204',
    },
    {
      text: '"We gratefully acknowledge the Reynolds Wrap Corporation for donating the costumes."',
      author: 'Mike, #705',
    },
    {
      text: '"We had to scrub the launch." "Hey! Hey! There are ladies present!"',
      author: 'Gregory Peck/Tom, #401',
    },
    {
      text: "\"We hope you've enjoyed 'No Moral Theater'!\"",
      author: 'Crow, #812',
    },
    {
      text: '"We just came to beat everybody up, we\'re leaving now! Thanks!"',
      author: 'Crow, #508',
    },
    {
      text: '"We just took your libido and starched and pressed it."',
      author: 'Crow, #510',
    },
    {
      text: '"We like it here! Now we\'re even closer to the atomic pile! And someday..."',
      author: 'Dr. Forrester. #201',
    },
    {
      text: '"We now return you to our musical program." "During our news break we changed station format to \'All trumpet fanfare.\'"',
      author: 'Radio Announcer/Crow, #812',
    },
    {
      text: '"We rented three vintage cars, and dammit, we\'re gonna use \'em!"',
      author: 'Mike, #821',
    },
    {
      text: '"We suggest you start breathing in shifts."',
      author: 'Crow, #401',
    },
    {
      text: '"We used to come here and club seals together."',
      author: 'Crow, #202',
    },
    {
      text: '"We were just saying goodbye...""...with our reproductive organs."',
      author: 'Adam Chance/Crow. #815',
    },
    { text: '"We will return to Non Sequitur Theater!"', author: 'Tom, #511' },
    {
      text: '"We won\'t start cracking the whip on Meecham until tomorrow. / Then I ram my ovipositor down your throat, and lay my eggs in your chest! But I\'m not an alien!"',
      author: 'Exeter/Crow, MST3K:TM',
    },
    {
      text: '"Welcome to *my* Flagstaff, ifyaknowwhatImean."',
      author: 'Tom, #904',
    },
    { text: '"Welcome to Plot Convenience Playhouse."', author: 'Tom, #306' },
    {
      text: '"Welcome to the Girl Pavilion! / See the leg waxing demonstration. / See the latest in stripping technology!"',
      author: 'Mike/Tom/Crow, #812',
    },
    {
      text: '"Well believe me, Mike, I calculated the odds of this succeeding versus the odds I was doing something incredibly stupid... and I went ahead anyway."',
      author: 'Crow, MST3K:TM',
    },
    {
      text: '"We\'ll find him. That miserable scum! / He stole my cup!"',
      author: 'Krankor/Tom, #816',
    },
    {
      text: '"Well how big do they get? [ominous music]" "How big does that music make it sound?"',
      author: 'Captain Thorne Sherman/Tom, #407',
    },
    {
      text: '"Well maybe it\'s not tangible: I mean maybe sampo is found in the laughter of children or in the roar of the tide or in the capacity to love and be loved."',
      author: 'Crow, #422',
    },
    {
      text: '"Well, at least it\'s better than the Soldier Field bathrooms."',
      author: 'Mike. #705',
    },
    {
      text: '"Well, better than Quest of the Delta Burkes, I suppose."',
      author: 'Crow, #913',
    },
    {
      text: '"Well, George, your party was step number one for your journey to America, but before you actually leave us there are a ... a few little details to be taken care of. / Like your death."',
      author: 'Dick Sargent/Crow, #811',
    },
    {
      text: '"Well, got a squirrel in the oven if you\'re hungry!"',
      author: 'Crow, #904',
    },
    {
      text: '"Well, he can\'t walk too fast or he\'ll run out of set."',
      author: 'Crow, #822',
    },
    {
      text: "\"Well, I see the movie has finally thrown up its hands and said, 'I just don't know'.\"",
      author: 'Tom, #619',
    },
    {
      text: '"Well, I take my corn black so it\'s not a problem."',
      author: 'Crow, #1001',
    },
    {
      text: "\"Well, I think the point they've established so far is that they're going into space. I think that's probably established. Maybe we'll need another TEN MINUTES or so to make it clear!!\"",
      author: 'Tom, #401',
    },
    {
      text: '"Well, if it\'s not too much trouble, could somebody ritual seppuku me right now, please?"',
      author: 'Tom, #819',
    },
    {
      text: '"Well, I\'ll just rifle the dead\'s stuff here..."',
      author: 'Crow (as Joe), #310',
    },
    {
      text: '"Well, I\'m glad to know that the future has CONSTANT ORGAN MUSIC!"',
      author: 'Crow, #906',
    },
    {
      text: '"Well, I\'m gonna go over to the morgue and cheer up."',
      author: 'Crow, #415',
    },
    {
      text: '"Well, it looks like the fish got a new Pope."',
      author: 'Tom, #605',
    },
    { text: '"Well, it\'s hardly worth it, but: Booo!"', author: 'Crow, #814' },
    {
      text: '"Well, it\'s not a plot point... and it\'s not an action sequence... so what *is* it??"',
      author: 'Mike. #605',
    },
    {
      text: '"Well, Joel, say what you will, but Roger Corman pushes my buttons."',
      author: 'Tom, #511',
    },
    {
      text: '"Well, Lawgiver, the war\'s over for me: I\'ve got trench mouth." "How could you?! We just got started five minutes ago and you already have trench mouth?"',
      author: 'Bobo/Pearl Forrester, #814',
    },
    {
      text: '"Well, let\'s see... fourteen minutes to live. Wonder if I can get a pizza in that time?"',
      author: 'Crow, #603',
    },
    {
      text: '"Well, look at that.  \'Breach hull; all die.\' Even had it underlined!"',
      author: 'Crow, MST3K:TM',
    },
    {
      text: '"Well, no, it\'s just that I\'ve never heard of an action sequence in a Christmas carol before..."',
      author: 'Joel. #321',
    },
    {
      text: '"Well, okay, but don\'t duplicate my butt."',
      author: 'Joel, #420',
    },
    {
      text: '"Well, one thing\'s for sure, gentlemen: the US of A still makes the best damn sampos on the market, and I mean nobody is gonna change that!"',
      author: 'Tom, #422',
    },
    {
      text: '"Well, sounds like a nice place, I wish we could see it."',
      author: 'Mike, #810',
    },
    {
      text: '"Well, the good news is that we won\'t make a big explosion when we hit the earth..."',
      author: 'Tom, #201',
    },
    {
      text: '"Well, the movie lost me. It lost me and now it\'s trotting off without me."',
      author: 'Tom, #913',
    },
    {
      text: '"Well, there\'s blankets and canned goods in the broom closet. We\'ll make out." "Oh, wowwww..."',
      author: 'Scientist/Joel. #311',
    },
    {
      text: '"Well, there\'s no emotional attachment to me. I can die."',
      author: 'Crow, #405',
    },
    {
      text: '"Well, they cast me...I guess they expect me to do something."',
      author: 'Crow, #904',
    },
    {
      text: '"Well, this film wastes no precious screen time with a plot!"',
      author: 'Crow, #619',
    },
    {
      text: '"Well, this just nipped my habit of watching movies with silver guys jumping out of trucks right in the bud!"',
      author: 'Tom, #705',
    },
    {
      text: '"Well, time for our daily empty threat session."',
      author: 'Crow, #816',
    },
    {
      text: '"Well, uh, not much traffic today; you\'ll have a clear shot to your stifling work place. Later on driving to the bar to avoid your family should be nooo problem."',
      author: 'Tom, #819',
    },
    {
      text: '"Well, Valerie Bertinelli hasn\'t left the Bronx!"',
      author: 'Crow, #705',
    },
    {
      text: '"Well, world, get used to being run by Donald Pleasance."',
      author: 'Mike, #903',
    },
    {
      text: '"Well, your ample ones, my invention exchange this week dynamically blends martial arts with snacking: it\'s called Snaction and it makes snacking fun."',
      author: 'Joel, #422',
    },
    {
      text: '"Well, you\'re rich and white, I don\'t see a problem..."',
      author: 'Tom, #512',
    },
    {
      text: "\"Well. This isn't good, not at all. I mean, I've seen good before, and it didn't look anything like this.\"",
      author: 'Joel. #113',
    },
    {
      text: "\"We're being audited by the Fraternal Order of Mad Science, you know, one of those 'Are you really mad enough?' sort of things.\"",
      author: 'Frank, #512',
    },
    {
      text: '"We\'re born, and then we die, and there\'s lots of padding in between."',
      author: 'Joel. #209',
    },
    {
      text: '"We\'re getting a lot of mileage out of that lemur bit!"',
      author: 'Crow. #210',
    },
    {
      text: '"We\'re going to have leadership the way my old man told me! You, put a handkerchief on your head! You, spot out imaginary elves! You, rock on the porch all night!"',
      author: 'Joel, #424',
    },
    {
      text: '"WE\'RE STILL FIGHTING BRAVELY FOR OUR VAGUE GOAL!"',
      author: 'Crow, #703',
    },
    {
      text: '"We\'ve been married for twenty years, at least let me get to second base."',
      author: 'Tom, #603',
    },
    {
      text: '"We\'ve got to get organized! We should not be losing to *grasshoppers*, people!"',
      author: 'Mike, #517',
    },
    {
      text: '"We\'ve landed. You\'ll just have to take my word for it."',
      author: 'Lomas/Tom Servo (as Lomas). #321',
    },
    {
      text: '"We\'ve soldered tiny electrodes to all the speech and motor sensor of Bobo\'s brain; it will either kill him or allow us to control his every motion!" "Oh, and it\'s portable! All controlled by this universal remote we got at Target for nine bucks."',
      author: '#1007',
    },
    {
      text: '"We\'ve whipped the problem of skin friction on the warhead..." "Uh, are we talking about the same thing here, buddy?"',
      author: '#205',
    },
    {
      text: '"What a day. I invented Gainesburgers and I didn\'t even mean to!"',
      author: 'Tom, #421',
    },
    {
      text: '"What a desolate, forsaken place!" "What a stilted, pretentious line!"',
      author: 'Nora/ Crow. #210',
    },
    {
      text: '"What about starting the car, have you thought about it? My hip hurts. Why?"',
      author: 'Joel, #404',
    },
    {
      text: '"What about the plot, Joel? Am I the only one who cares?"',
      author: 'Tom, #206',
    },
    {
      text: '"What are the specs on this thing, sir?" "Those are bugs. They\'ll wash right off."',
      author: 'Dan/Crow (as Joe), #310',
    },
    {
      text: '"What did you do today? Well, we watched a stupid movie..."',
      author: 'Crow. #107',
    },
    {
      text: '"What do you want from us?? We\'re *evil*! EVIL!!"',
      author: 'Dr. F and Frank. #301',
    },
    {
      text: "\"What does it mean I am 'stacked'?\" \"Uh, 'stacked' means you're really smart.\"",
      author: 'Marta/Crow, #106',
    },
    { text: '"What fresh hell is this?"', author: 'Crow. #505' },
    {
      text: '"What happened to my uncle?" "What happened to your pants?"',
      author: 'Johnny/Joel, #306',
    },
    {
      text: '"What happens now? / Mostly you die."',
      author: 'Paul Carlsen/Crow, #1007',
    },
    {
      text: '"What I\'m gonna do, see, is kill *you*, and strap you around *my* body for armor..."',
      author: 'Tom, #509',
    },
    {
      text: '"What is that thing there? / Well, whatever it is it wasn\'t invented yet."',
      author: 'Tom/Crow, #511',
    },
    {
      text: '"What is this obsession with not showing us who\'s getting into what truck?!"',
      author: 'Mike, #910',
    },
    {
      text: '"What kind of lure do you use to catch cocaine? A spoon?"',
      author: 'Crow, #622',
    },
    {
      text: '"What kind of lure were you using there, Mike, a grenade?" "Well, sometimes when you bring \'em through high orbit they\'ll freeze and go through explosive decompression." "Oh, that\'ll wreck your catch."',
      author: 'Crow/Mike/Tom, #1005',
    },
    {
      text: '"What kind of universe is this where they alter your clothes while you sleep??"',
      author: 'Joel. #107',
    },
    {
      text: '"What sins could a duck commit in a single lifetime?"',
      author: 'Crow, #523',
    },
    { text: '"What the hell does that mean. Over."', author: 'Mike, #608' },
    { text: '"What times are it?"', author: 'Crow, #819' },
    {
      text: '"What was I doing? I wasn\'t drinking there for a second."',
      author: 'Mike, #810',
    },
    {
      text: '"What was that? / Oh, I have small dogs in my hair. Sorry."',
      author: 'Natalie/Crow, #904',
    },
    {
      text: '"What ya whispering for, kid? It\'s no secret you\'re not much of a fighter." "But what about that guy I groined in the knee?"',
      author: 'Bix/Tom, #509',
    },
    {
      text: '"What, are you stupid?!" "Uh, assume I am ..."',
      author: 'Eddie/Crow, #821',
    },
    { text: '"What, do you think soup is a biped?"', author: 'Crow. #313' },
    {
      text: '"What, no headbutt this morning? Honeymoon over?"',
      author: 'Crow, #321',
    },
    {
      text: '"What? What was that? Was that a plot point?"',
      author: 'Crow. #416',
    },
    {
      text: '"What? You like that, huh? You like it when I touch your penthouse?"',
      author: 'Tom, #705',
    },
    {
      text: '"What?! The clock\'s running backwards! / That means lunch won\'t be til yesterday!"',
      author: 'Hokey Japanese Reporter/Tom, #819',
    },
    {
      text: '"Whatever can I do to make it up to you?" "Uh... paint my house?"',
      author: 'Maya/Crow, #508',
    },
    { text: '"Whatever happened to empiricism?"', author: 'Frank, #415' },
    {
      text: '"Whatever happened to my pet Vietnamese potbelly pig?"',
      author: 'Crow, #610',
    },
    {
      text: '"Whatever you do, don\'t hit the inflatable reindeer! Please!"',
      author: 'Tom, #304',
    },
    {
      text: '"What\'ll we do during that time? / Well, there\'s always liquor."',
      author: 'Captain Thorne Sherman/Joel, #407',
    },
    {
      text: '"What\'s going on here? Am I making up my own movies?" "Well, could you make up a better one than this?!"',
      author: 'Aram Fingal/Tom, #822',
    },
    {
      text: '"What\'s that? The Pocket Guide to Plutonium Consultants?"',
      author: 'Tom, #309',
    },
    {
      text: '"What\'s the narrator talking about?" "He\'s way off script." "He\'s a renegade narrator on the loose!" "Boy, let me tell you he\'s nuts. But, he never plays by anybody\'s rules!" "But he gets results! "',
      author: '#606',
    },
    {
      text: '"What\'s your band\'s name again?" "Sex Factory." "D\'AGH!"',
      author: 'Crow/Younger Mike, #821',
    },
    {
      text: '"When I touch you..." "I think about myself.  No no wait..."',
      author: 'Quentiss/Crow, #806',
    },
    {
      text: '"When she thinks of the mass media, she touches herself!"',
      author: 'Tom, #321',
    },
    {
      text: '"When the movie starts showing you parts of itself you know you\'re in trouble."',
      author: 'Mike, #706',
    },
    {
      text: '"When threatened she gains the strength of a thousand trollops."',
      author: 'Tom, #907',
    },
    {
      text: '"When we return to our planet, the high court may well sentence you to TORTCHA!!"',
      author: 'Teener Captain. #404',
    },
    {
      text: '"When you first saw this you laughed so hard you spilled your bong water; didn\'t you, Mike?"',
      author: 'Crow, #706',
    },
    {
      text: '"When you get close to an accent, let us know."',
      author: 'Crow. #518',
    },
    {
      text: '"When you want the flavor of bacon in a dip."',
      author: 'Crow. #903',
    },
    {
      text: '"Where does Coily fit into God\'s plan for us?"',
      author: 'Crow, #1011',
    },
    {
      text: '"Where does the pillow end and his face begin?"',
      author: 'Tom, #619',
    },
    {
      text: '"Where I come from you wash in a sink and you dry with a cloth. Now, try it before you leave. / Yeah, well, where you come from beer is an entrée."',
      author: 'Mitchell/Joel, #512',
    },
    {
      text: '"Where\'d you get all the toothpicks? / What? Are you kidding? We\'re on a space ship; this place is crawling with toothpicks."',
      author: 'Tom/Joel, #512',
    },
    {
      text: '"Where\'s the pride in killing helpless women and children? You call that heroism?" "I call that fun. Don\'t you have any hobbies?"',
      author: 'Joe/Crow, #310',
    },
    { text: '"Where\'s your upper lip?"', author: 'Crow, #610' },
    {
      text: '"Whether you like it or not, it looks like you just signed on." "Welcome to Herbalife."',
      author: 'Joe/Crow, #310',
    },
    {
      text: '"Who are we?" "BATS!" "What do we want?" "INSECTS!" "When do we want \'em?" "NOW!" "Squeak...squeak..."',
      author: '#703',
    },
    { text: '"Who are you people and where\'s my soup?"', author: 'Tom, #804' },
    {
      text: '"WHO ARE YOU?! WHERE ARE WE?! COULD WE PLEASE GET A FRAME OF REFERENCE OR SOMETHING PLEASE?!"',
      author: 'Joel. #208',
    },
    {
      text: '"Who buys your talent?" "I HAD A STORY IN HIGHLIGHTS!"',
      author: 'Lt. Lewis/Tom, #603',
    },
    {
      text: '"Who could put a value on human life? I mean, who can decide who lives and who dies?" "I do, remember? I called dibs."',
      author: 'Joel/Crow, #401',
    },
    {
      text: '"WHO IS MERRITT STOOOOOOOOOOOOOOOOOOOOOOOOOOOOOONE!!!"',
      author: 'Frank, #419',
    },
    { text: '"Who says a coma can\'t be delicious?"', author: 'Frank. #322' },
    {
      text: '"Whoa! That\'s a lot of slang for one sentence."',
      author: 'Tom, #207',
    },
    { text: '"Whoa, he\'s got a scorched face policy."', author: 'Mike, #612' },
    { text: '"Whoa, it\'s like Lady Roadhouse."', author: 'Joel, #511' },
    {
      text: '"Whoa... Did I toss a throne last night?"',
      author: 'Crow (as Hercules). #412',
    },
    {
      text: '"Whoopi Goldberg told me you smell like apples, true?" "What?! That does it, Mike! I\'m getting the clown hammer!"',
      author: 'Mike/Crow, #904',
    },
    {
      text: '"Who\'s this personal injury lawyer who keeps showing up?"',
      author: 'Crow, #705',
    },
    {
      text: '"Why are all these alien transvestites attacking Earth, anyway?"',
      author: 'Tom, #310',
    },
    {
      text: '"Why didn\'t I think of that before? It\'s so simple! Reverse the access code! He probably thought I\'d never try something so simple." "You know, if this works, I am going to spank you, movie!"',
      author: 'Aram Fingal/Crow, #822',
    },
    {
      text: '"Why do people even go to movies that don\'t feature underwear?"',
      author: 'Crow, #1002',
    },
    {
      text: '"Why does he have to kill them to prove his point? Can\'t he just show them a pie chart or something?"',
      author: 'Tom, #103',
    },
    {
      text: '"Why doesn\'t the movie just cover him in cat vomit?"',
      author: 'Tom, #821',
    },
    {
      text: '"Why those rebels! They\'re going to park in a ninety minute zone and at a slight angle!"',
      author: 'Crow, #812',
    },
    {
      text: '"Why would anyone want to *do* this with Mitchell, Joel??"',
      author: 'Crow. #512',
    },
    {
      text: '"Why, that\'s Caroline! / And her pneumatic drapery!"',
      author: 'Pepe/Crow, #306',
    },
    {
      text: '"Why\'d a goofy old apocalypse hafta happen right when my acne was starting to clear up?"',
      author: 'Joel, #108',
    },
    {
      text: '"Winky, you\'re not a swinger, and you look like Hardware Hank."',
      author: 'Tom, #413',
    },
    {
      text: '"With forced perspective, this Precious Moments figurine looms as large as the Statue of LIberty." "You know, he has a point, Crow." "No, no, no. He has no point; don\'t encourage him."',
      author: 'Mike/Tom/Crow, #1004',
    },
    {
      text: '"Women are torn apart like fresh bread..."',
      author: 'Joel, #421',
    },
    {
      text: '"Wool Over His Eyes Man! / Easily Bamboozled Man! / Three Steps Behind Man!"',
      author: 'Mike/Crow/Tom, #903',
    },
    { text: '"Word on the street is, you\'re a jerk."', author: 'Crow, #512' },
    {
      text: '"World\'s greatest ride within a ... fairly narrow universe."',
      author: 'Crow, #812',
    },
    {
      text: '"Would somebody please milk grandpa? He\'s in a lot of pain!"',
      author: 'Joel. #308',
    },
    {
      text: '"Would this be a bad time to bring up Snausages?"',
      author: 'Joel (as Lassie). #510',
    },
    {
      text: '"Would you guys knock it off? I can\'t concentrate on my own lame wisecracks."',
      author: 'Crow. #210',
    },
    {
      text: '"Would you mind cleaning up the examining room? / I was getting into my ape role and I threw some crap around."',
      author: 'Roddy McDowell/Mike, #706',
    },
    {
      text: '"Would you mind having some sort of expression while I\'m talking to you? It would make me feel better."',
      author: 'Mike, #906',
    },
    {
      text: '"Would you quit being evil over my shoulder?!"',
      author: 'Crow, #403',
    },
    {
      text: '"WOULD YOU SHUT THE BLOODY ORGAN GRINDER UP!!"',
      author: 'Crow. #521',
    },
    {
      text: '"Wow! I never noticed, Mike, but your upper lip is huge!" "Yeah! You look like a bowling ball with a slit in it!" "You could ski down your nasal labial trough!"',
      author: 'Crow/Tom/Crow, #811',
    },
    {
      text: '"Wow! If a fellow were to happen to be a human duplicator, he could really have a ball up here!"',
      author: 'Joel, #420',
    },
    {
      text: '"WOW! Look at that! What a great name: \'Heather Killingbeck\'!"',
      author: 'Mike, #821',
    },
    {
      text: '"Wow, he must be in God Mode with unlimited ammo!"',
      author: 'Crow, #705',
    },
    {
      text: '"Wow, if there was ignorant talk radio in the seventies, I sure would agree with it."',
      author: 'Mike, #706',
    },
    {
      text: '"Wow, look at her go. I didn\'t realize cigarettes had so many vitamins."',
      author: 'Mike, #905',
    },
    {
      text: '"Wow, this guy trained under Bruce Lee ... bowitz. Heh heh."',
      author: 'Crow, #819',
    },
    {
      text: '"Wow, Tokyo Disneyland sucks. There\'s Nothing World, and Parking World, and, uh, Wet World ..."',
      author: 'Crow, #316',
    },
    {
      text: '"Wow, you could fit a whole lotta turkeys in this place."',
      author: 'Tom, #814',
    },
    {
      text: '"Wow... Do you realize how many TV series would be wiped out if this room blew up?"',
      author: 'Tom, #305',
    },
    {
      text: '"Wow...I think I saw the sizzler there, Hank."',
      author: 'Mike, #812',
    },
    {
      text: '"Xanadu, stately home of Charles Foster Manson."',
      author: 'Mike. #815',
    },
    {
      text: '"YAY! We did it!" "Yay! ...Well, we didn\'t actually do it." "...Well, it was done by someone!" "YAY!"',
      author: 'Crow/Mike/Crow/All, #1013',
    },
    {
      text: '"Yeah, everybody gets a day off at the end of the world. Didn\'t you know that? It\'s a free day."',
      author: 'Joel. #311',
    },
    {
      text: '"Yeah, I\'m gonna do it. I\'m gonna start a band called Air Supply!"',
      author: 'Mike, #811',
    },
    {
      text: '"Yeah, just remember, Mike: If push came to shove, I could take ya!"',
      author: 'Gypsy. #522',
    },
    {
      text: '"Yeah, kids are always mutilating people."',
      author: 'Crow. #421',
    },
    {
      text: "\"Yeah, 'the wife' is going to be awful glad to see 'the me.' / We're going to have 'the sex.'\"",
      author: 'Tom/Crow, #612',
    },
    {
      text: '"Yeah, this is why Seven Elevens never allow more than three Aztecs at one time."',
      author: 'Crow, #903',
    },
    {
      text: '"Yeah, this was back when undergarments had no erotic appeal."',
      author: 'Tom, #616',
    },
    {
      text: '"Yeah, when you major in Boggy Creek studies you can write your own ticket on Wall Street after you graduate."',
      author: 'Mike, #1006',
    },
    {
      text: '"Yeah, you can usually find a blonde hair in a field of wheat... at night... in a fog..."',
      author: 'Tom, #108',
    },
    {
      text: '"Yeah...that was Interrogation in D...all right..."',
      author: 'Mike, #603',
    },
    {
      text: "\"Yeahhh, wha' do we got? 'Nother citizen who isn't ready for some FOOTBALL!!!\"",
      author: 'Mike, #706',
    },
    {
      text: '"Yep: bein\' Ben Murphy has its advantages. Like, being Ben Murphy."',
      author: 'Crow, #814',
    },
    {
      text: '"Yes sir, we also have the cardigan in ochre."',
      author: 'Crow, #815',
    },
    {
      text: '"Yes! Children\'s windows of perception are open for only a second only to take in the horror that is the circus."',
      author: 'Tom, #422',
    },
    {
      text: '"Yes!! It worked! I\'m gonna score on an interplanetary scale!"',
      author: 'Crow, #305',
    },
    { text: '"Yes, Buns of Steel of the Old West."', author: 'Crow, #511' },
    {
      text: '"Yes, but do you not agree that the whole concept of a shampoo AND conditioner in one is absurd?!"',
      author: 'Crow, #614',
    },
    {
      text: '"Yes, every exciting Angstrom of their chase will be shown."',
      author: 'Mike, #1008',
    },
    {
      text: '"Yes, I will take money from my dad\'s wallet and send it to Soupy Sales."',
      author: 'Tom, #906',
    },
    {
      text: '"Yes, in the glorious days before environmental impact statements. / Building a better, snowless arctic!"',
      author: 'Mike/Crow, #804',
    },
    {
      text: '"Yes, Joel, ..Insert perfunctory acknowledgment here."',
      author: 'Dr. Forrester, #502',
    },
    {
      text: '"Yes, no matter what the culture, folk dancing is stupid."',
      author: 'Mike, #621',
    },
    {
      text: '"Yes, once again the pursuing vehicle is driven by STUPID people."',
      author: 'Tom, #322',
    },
    {
      text: '"Yes, this act embodies the sadness that is \'circus.\'" "That is too dark. Now stop it."',
      author: 'Tom/Joel, #422',
    },
    {
      text: '"Yes, yes, yes, yes, well, first of all, let me once again state that, if we are going to have a democracy, we must have a mulching attachment!"',
      author: 'Tom, #614',
    },
    {
      text: '"You and your eighties!" "Your precious eighties!" "You know, it would have continued to be the seventies if it weren\'t for you."',
      author: 'Crow/Tom, #820',
    },
    {
      text: '"You are going back farther... going back... / back, back, OH he snagged it on the warning track!"',
      author: 'Hypnotist/Tom, #806',
    },
    {
      text: '"You are the one in my dreams of blood! / But then, everyone\'s in my dreams of blood, so it\'s not that big a deal..."',
      author: 'Maala/Crow, #802',
    },
    {
      text: '"You can have it for... / ...35 uglies, I MEAN dollars!!"',
      author: 'Shopkeeper/Mike, #702',
    },
    {
      text: '"You can kill me, but it\'s over my dead body!"',
      author: 'Crow, #306',
    },
    {
      text: '"You can see why the British invasion was so *easy*!"',
      author: 'Mike. #606',
    },
    { text: '"You can\'t be in our club, Satan!"', author: 'Crow, #521' },
    {
      text: '"You can\'t hold a man, or convict him, because he buys a new pair of shoes. That\'s the whole basis of our judicial system!"',
      author: 'Lt. Lewis/Crow #603',
    },
    {
      text: '"You can\'t swing a dead cat without hitting one of his boring speeches!"',
      author: 'Crow. #201',
    },
    { text: '"You Castleton snob!"', author: 'Crow, #821' },
    {
      text: '"You cookin\' dinner?" "Are you offering dinner?" "No, I\'m saying, \'MAKE ME DINNER!\'"',
      author: 'Nick/Lisa/Crow, #821',
    },
    {
      text: '"You didn\'t learn a thing last night, did you." "Well, I *did*, but I don\'t think we can use it to rob a bank..."',
      author: 'Linda/Joel, #207',
    },
    {
      text: '"You don\'t even wear underpants!" "Oh, don\'t you worry about that, I\'ll take care of that!"',
      author: 'Tom/Crow, #515',
    },
    {
      text: '"You don\'t have to be a pig for the rest of your life, Mitchell." "Well, I kinda *do*..."',
      author: 'John Saxon/Crow, #512',
    },
    {
      text: '"You don\'t necessarily have to get swallowed up in a black hole..." "...but it helps!"',
      author: 'Ken/Tom, #318',
    },
    { text: '"You drive like a Kennedy, you idiot!"', author: 'Eddie, #821' },
    {
      text: '"You ever seen me look so happy?" "Yeah, but you didn\'t know I was watching you."',
      author: 'Luke/Joel, #202',
    },
    {
      text: '"You get used to lizards here; they\'re quite common. That\'s why tribes in this area have so many...legends about them. / [under breath] Ask me to tell one, ask me to tell one, ask me to tell one..."',
      author: 'Johnny Longbow/Crow, #1007',
    },
    {
      text: '"You guys should try pulling pranks that don\'t involve lying about the dead." "Oh, right, Mike. You come up with one then!"',
      author: 'Mike/Crow, #912',
    },
    {
      text: '"You guys, you know, I normally like a movie with silver guys jumpin\' out of trucks, but, I gotta say, I\'m a bit disappointed with this one."',
      author: 'Mike, #705',
    },
    {
      text: '"You have my word of honor that the story I have been telling is the truth... Okay, mixed with a little fiction... Alright, I\'m lying my butt off..."',
      author: 'Tom, #102',
    },
    {
      text: '"You have the right to remain mellow. If we rap with you, you have the right to have an attorney present."',
      author: 'Crow, #814',
    },
    {
      text: '"You haven\'t heard the latest: the first cosmic exploration rocket will be launched from this base." "The dining room?"',
      author: 'Japanese Woman/Crow, #816',
    },
    { text: '"You iridescent, iconic, idiotic imp!"', author: 'Crow, #821' },
    {
      text: '"You just don\'t care about my lunch, at all, do you? You know it\'s there and you deliberately sit on it!"',
      author: 'Crow, #515',
    },
    {
      text: '"You keep meat in this." "Uh, your mouth?"',
      author: 'Crow/Tom, #201',
    },
    {
      text: '"You know a movie is bad bad BAD if it makes The Monkees look good!"',
      author: 'Crow. #515',
    },
    {
      text: "\"You know Mike's dangerous enough just wielding that big, chunky body of his around, but give him a knife and it's 'Goodnight, Irene!'\"",
      author: 'Tom, #814',
    },
    {
      text: '"You know the big twist in this movie is that the shrews are good and they\'re just trying to do an intervention on the humans."',
      author: 'Joel, #407',
    },
    {
      text: '"You know the consequences of a body moving with unchecked velocity in free space!!" "... Uh... We\'re our own grandparents?"',
      author: "Hugh O'Brien/Crow, #201",
    },
    {
      text: '"You know things are bad when you keep a liquor cabinet in your bedroom."',
      author: 'Joel, #106',
    },
    {
      text: '"You know what burns my butt? A flame about this high."',
      author: 'Joel, #308',
    },
    {
      text: '"You know what I\'ve been thinking about, Mario? ...Ann ...and that Captain ... you and me." "We will need a bigger room then."',
      author: 'Jerry Farrell/Crow, #407',
    },
    {
      text: '"You know what this is, don\'t you? It\'s fear of a short red planet is what it is!!"',
      author: 'Tom, #505',
    },
    {
      text: '"You know what would have saved this movie? Surf music! Man, surf music makes anything good!"',
      author: 'Crow, #822',
    },
    {
      text: '"You know you want me, baby!"',
      author: "Crow's Catch Phrase, #822",
    },
    {
      text: '"You know, a federal indictment may prove difficult to overcome in the student elections."',
      author: 'Tom, #610',
    },
    {
      text: '"You know, a movie is bad, bad, bad if it makes The Monkees look good!"',
      author: 'Crow, #515',
    },
    {
      text: '"You know, a year after they restore the Bronx it\'s gonna be Karate schools anyway."',
      author: 'Mike, #705',
    },
    {
      text: '"You know, all this time we could have been watching an apple brown."',
      author: 'Crow, #912',
    },
    {
      text: '"You know, as destroyed the Bronx is, there\'s still a Kinko\'s on every corner."',
      author: 'Crow, #705',
    },
    {
      text: '"You know, aside from the fact that I\'ll never again experience joy in my life, I don\'t think Red Zone Cuba had any kind of negative affect on me."',
      author: 'Crow, #619',
    },
    {
      text: '"You know, at some point the world has got to stop letting idiots like this almost take it over."',
      author: 'Tom, #903',
    },
    {
      text: '"You know, Captain?  Every year of my life I grow more and more convinced that the wisest and best is to fix our attention on the good and the beautiful, if you\'ll just take the time to look at it."',
      author: 'Makonnen, #902',
    },
    {
      text: '"You know, Coleman Francis would make a really lumpy mattress. Not that I ..."',
      author: 'Crow, #619',
    },
    {
      text: '"You know, elf tastes just like chicken!"',
      author: 'Tom Servo, #321',
    },
    {
      text: '"You know, even my mom would be bored at this."',
      author: 'Joel, #421',
    },
    {
      text: '"You know, even though they tried to eat our brains, you gotta admit it\'s kinda fun here!"',
      author: 'Joel, #312',
    },
    {
      text: '"You know, every frame of this movie looks like someone\'s last known photograph."',
      author: 'Joel, #424',
    },
    {
      text: '"You know, guys, I know this makes it all look pretty bad, but there are a lot of good things that come from Wisconsin like fireworks, and cheese, and uh ... fireworks ..."',
      author: 'Mike, #810',
    },
    {
      text: '"You know, guys, if this was what being beat was all about I\'d rather be a shallow, posed boomer. Like you." "Hey!"',
      author: 'Tom/Joel, #419',
    },
    {
      text: '"You know, his work is uncluttered by talent." "He sold seven thousand paintings to Best Westerns across the nation."',
      author: 'Crow/Tom, #805',
    },
    {
      text: '"You know, I get the feeling Hercules is going to learn an important lesson and kick some serious butt."',
      author: 'Joel. #502',
    },
    {
      text: '"You know, I have almost no respect for pumas now. I now know that if I ever run into a puma I can just push it the hell over."',
      author: 'Crow, #903',
    },
    {
      text: '"You know, I like the idea of two women dressed like cats and fighting, but, I don\'t know, the execution ..."',
      author: 'Mike, #616',
    },
    {
      text: '"You know, I saw the sequel to this: TISCWSLABMUZ 2."',
      author: 'Crow, #812',
    },
    {
      text: '"You know, if Raul Julia was Scott Leduc, he\'d be on the floor by now."',
      author: 'Mike. #822',
    },
    {
      text: '"You know, if Satan thinks you\'ve gone too far, you\'ve gone too far."',
      author: 'Mike, #806',
    },
    {
      text: '"You know, if this guy could juggle he\'d rule England."',
      author: 'Crow, #818',
    },
    {
      text: '"You know, if we pretend we know what\'s going on this is actually kind of exciting."',
      author: 'Crow, #820',
    },
    {
      text: '"You know, I\'m gonna go get a big, stinky, spiral cut ham and staple it to my top lip! Thank you, Mike!"',
      author: 'Tom, #811',
    },
    {
      text: '"You know, in those days you were required to smoke."',
      author: 'Joel, #401',
    },
    {
      text: '"You know, it is statistically proven that 78% of white men sing like this!"',
      author: 'Tom, #820',
    },
    {
      text: '"You know, it\'s about this time in any killing spree that you really ought to turn the gun on yourself."',
      author: 'Tom, #512',
    },
    {
      text: '"You know, it\'s economical not to have a story line, \'cause then you can just film people saying things."',
      author: 'Crow, #904',
    },
    {
      text: '"You know, Joe Don Baker would be perfect for Elvis: The Dying Days."',
      author: 'Crow, #512',
    },
    {
      text: '"You know, just because you can edit doesn\'t mean you should."',
      author: 'Tom, #911',
    },
    {
      text: '"You know, maybe if the Amish used buttons they wouldn\'t be so irrational."',
      author: 'Crow, #908',
    },
    {
      text: '"You know, maybe they shouldn\'t have set their phasers to *miss*."',
      author: 'Mike. #820',
    },
    {
      text: '"You know, Mike, this film was run through a highly technical process called tension extraction before it was released to the theaters."',
      author: 'Crow, #706',
    },
    {
      text: '"You know, Mike...it\'s hard not to make a Dukes of Hazzard comment." "Try, honeys."',
      author: 'Crow/Mike, #706',
    },
    {
      text: '"You know, my dear, in tough situations like this I\'ve found in the past that the best thing a patient can do is be comforted by me, Doctor Chad Feelgood."',
      author: 'Tom, #413',
    },
    {
      text: '"You know, nets are overrated weapons." "Yeah, throw a danish at him: that\'ll work better."',
      author: 'Crow/Mike, #1008',
    },
    {
      text: '"You know, not kneeing you in the groin is a constant struggle."',
      author: 'Mike, #603',
    },
    {
      text: '"You know, now I appreciate the quiet dignity of Pat Buttram\'s performance."',
      author: 'Mike, #622',
    },
    {
      text: '"You know, Peaches has a fresh, natural stupidness that isn\'t forced or contrived ..."',
      author: 'Crow, #616',
    },
    { text: '"You know, people were whiter back then."', author: 'Joel. #510' },
    {
      text: '"You know, say what you want, but Claude Akins has a nice rump."',
      author: 'Joel. #322',
    },
    {
      text: '"You know, she just has those glasses so she can pull \'em off dramatically."',
      author: 'Tom, #403',
    },
    {
      text: '"You know, that spider\'s really neat, but there\'s something really interesting on that wall over there..."',
      author: 'Joel. #313',
    },
    {
      text: '"You know, that\'s the thing about women: you pick them up and they instantly faint." "That\'s why they don\'t make very good professional wrestlers."',
      author: 'Crow/Mike, #1005',
    },
    {
      text: '"You know, the acoustics in that cotton field are fantastic."',
      author: 'Joel. #112',
    },
    {
      text: '"You know, the inability of the cops to turn right becomes their downfall." "And there it is."',
      author: 'Mike/Tom, #610',
    },
    {
      text: '"You know, the professor taught me everything I know. / It took him twenty seconds."',
      author: 'Leia Jansen/Mike, #820',
    },
    {
      text: '"You know, the Three Stooges, in their silliest moment, never stooped to this indignity."',
      author: 'Mike. #820',
    },
    {
      text: '"You know, there are certain flaws in this film."',
      author: 'Tom, #424',
    },
    {
      text: '"You know, they\'re only poor in money...and spirit...and dignity, and moral fiber, and hygiene."',
      author: 'Tom, #810',
    },
    {
      text: '"You know, this has action and nostalgia because we\'ve seen it before."',
      author: 'Crow, #1001',
    },
    {
      text: '"You know, this has the bacony stink of Canada all over it."',
      author: 'Mike, #910',
    },
    {
      text: '"You know, this Home Economics film really took a weird turn somewhere along the line..."',
      author: 'Tom, #317',
    },
    {
      text: '"You know, this is hell. Now, when we meet people, we\'ll be able to report what hell is like."',
      author: 'Tom, #907',
    },
    {
      text: '"You know, this movie *can* be used to induce vomiting."',
      author: 'Mike. #815',
    },
    {
      text: '"You know, this movie tested well with sexual predators."',
      author: 'Mike, #810',
    },
    {
      text: '"You know, uh, John Lennon said, sirs, instant karma\'s gonna get you, but now you can get Instant Karma!" "That\'s right, Joel! What goes around comes around, but now you don\'t have to wait for it!"',
      author: 'Joel/Tom, #502',
    },
    {
      text: '"You know, we haven\'t taken the time to enjoy the death of that devil vampire woman."',
      author: 'Mike, #705',
    },
    {
      text: '"You know, when I was a boy, we didn\'t *have* fast movies. Sometimes, we had to wait three or four *hours* for something to happen!"',
      author: 'Mike. #517',
    },
    {
      text: '"You know, with this digital technology the suckiness comes through with great clarity."',
      author: 'Mike, #822',
    },
    {
      text: '"You know, wouldn\'t it be cheaper and quicker to just bludgeon \'em right here?"',
      author: 'Crow, #816',
    },
    {
      text: '"You know, you shoot scuba diving in slow motion you could actually go back in time."',
      author: 'Tom, #911',
    },
    {
      text: '"You know, you\'d think if he was going to rule the world he\'d choose a better spot than a cave."',
      author: 'Joel. #107',
    },
    { text: '"You look at it. I\'m bitter."', author: '#208' },
    { text: '"You may get burnt on this ride."', author: 'Joel. #422' },
    {
      text: '"You mean to tell me that this Casey was pumping Buffalo about what happened in Europe?" "\'Pumping buffalo\'?!"',
      author: 'Robert Denby/Mike, #814',
    },
    { text: '"You need a flow chart for this movie."', author: 'Mike. #806' },
    {
      text: '"You said \'bowling ball\' earlier. What did that mean?"',
      author: 'Crow. #306',
    },
    {
      text: '"You see, a man of science is like a deep sea diver. He mustn\'t be afraid to walk down what\'s dark and frightening, in the hopes of scooping up a handful of truth." "I think I just got the bends from that analogy.',
      author: 'Dr. Wahrman/Mike, #906',
    },
    {
      text: '"You see, Mister Palermo has been a major source of embarrassment to the Italian government. / That\'s hard to do."',
      author: 'Bureaucrat/Mike, #1008',
    },
    {
      text: '"You see, when a spaceship loves a space station *very* much..."',
      author: 'Tom, #417',
    },
    {
      text: '"You see, when lackeys turn on their masters they become bulletproof."',
      author: 'Tom, #607',
    },
    {
      text: '"You seem to be excellently equipped." "Thank you! I didn\'t think you could tell through these trousers."',
      author: 'Prof. Fitzgerald/Tom, #103',
    },
    {
      text: '"You there! Discharge the caustic vapors!" "Yeah, look who\'s talkin\' ..."',
      author: 'Krankor/Mike, #816',
    },
    {
      text: '"You want an official statement? / I proclaim it LICK ME DAY!"',
      author: "Natalie's father / Mike, #1001",
    },
    { text: '"You! / Lick me!"', author: 'Wide Wangler/Crow, #705' },
    {
      text: '"You, the white male, are my personal oppressor."',
      author: 'Gypsy, #606',
    },
    {
      text: '"You. / Bite me! / You each and everyone one of you! / Bite me!"',
      author: 'Krankor/Tom/Krankor/Tom, #816',
    },
    {
      text: '"You\'ll eat at 6:30, and your dinner takes an hour and a half to cook. The Nazis will be on you by then."',
      author: 'Crow, #610',
    },
    {
      text: '"You\'ll never win. You\'ll always lose." "You\'re Harold Stassen."',
      author: 'Vorelli/Crow, #818',
    },
    { text: '"Young Republicans, they\'re so hip!"', author: 'Tom, #303' },
    {
      text: '"Your brother died a watery death. Well, gotta go!..."',
      author: 'Crow, #319',
    },
    { text: '"Your Department of Bureaus."', author: 'Tom, #819' },
    {
      text: '"Your jock strap will double as a flotation device."',
      author: 'Crow, #401',
    },
    {
      text: '"Your one mistake: you signed your test \'Mary Matthews.\'"',
      author: 'Mike, #515',
    },
    {
      text: '"Your reports of my death were greatly exaggerated, you crap heads!"',
      author: 'Pearl, #804',
    },
    { text: '"You\'re a disgrace to all Kens."', author: 'Crow. #310' },
    {
      text: '"You\'re all evil, and I hope you all have snacks!"',
      author: 'Tom, #805',
    },
    { text: '"You\'re kind of an idiot, aren\'t you?"', author: 'Mike, #908' },
    {
      text: '"You\'re really stupid if you get hit by a car after the apocalypse."',
      author: 'Crow, #403',
    },
    {
      text: '"You\'re so dumb you couldn\'t tell rabbit turds from Rice Krispies." "Snap crackle poop!"',
      author: 'Hick/Crow, #810',
    },
    { text: '"You\'ve got mail...pattern baldness!"', author: 'Tom. #821' },
    {
      text: '"You\'ve just been ordered executed. That\'ll take the smile off your face." "Not if you do it right!"',
      author: 'Guard/Crow, #310',
    },
    { text: '"Zero to sixty, in about an hour."', author: 'Tom, #603' },
    {
      text: '"Ziggy had Garfield neutered?! Now that\'s funny!"',
      author: 'J&tB, #424',
    },
    {
      text: '"We\'ve gotta find that rhythm combo. Sounds like the Mothers of Invention"',
      author: 'Crow #213',
    },
    {
      text: '"Played here by Jimmy Carl Black of the Mothers of Invention."',
      author: 'Tom #301',
    },
    { text: '"Ow, weasels ripped my flesh again!"', author: 'Mike #904' },
  ]
  return quotes[Math.floor(Math.random() * quotes.length)]
}
