/* eslint-disable no-magic-numbers */
/**
 * Caesar Cipher for email obsfuscation, only works for lowercase strings
 * @param {string} email my email address
 * @param {int}  key the number of chars to rotate
 * @returns {string} The decrypted text
 */
export function caesarCipher(str, key) {
  let decrypted = ''
  for (let i = 0; i < str.length; i += 1) {
    //let period and @ go through unscathed
    if (str[i] === '.' || str[i] === '@') {
      decrypted += str[i]
    } else {
      decrypted += String.fromCharCode(
        ((str.charCodeAt(i) + key - 97) % 26) + 97
      )
    }
  }
  return decrypted
}
