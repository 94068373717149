import React from 'react'
import ReactMarkdown from 'react-markdown'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { atomOneLight } from 'react-syntax-highlighter/dist/cjs/styles/hljs'
import remarkMath from 'remark-math'
import rehypeKatex from 'rehype-katex'
import { setTitle } from '../PageUtils'

const cc = require('../../../../images/cc.png')

/**
Use the linter in your IDE not your head.
 */
export default function ComplexityLinting() {
  setTitle('Nathan Gilbert | Lint for Complexity')

  const renderer = {
    code: ({ language, value }) => (
      <SyntaxHighlighter
        style={atomOneLight}
        language={language}
        customStyle={{
          background: 'var(--background)',
          color: 'var(--text-color-normal)',
        }}
        showLineNumbers
        wrapLines
      >
        {value}
      </SyntaxHighlighter>
    ),
  }

  const markdown = `
## Understanding & Linting for Cyclomatic Complexity

### Background
There are many ways to measure the complexity of a piece of Software; number of
lines, big O, and so on. [Cyclomatic
Complexity](http://www.literateprogramming.com/mccabe.pdf) attempts to do this
by enumerating all linearly idependent paths through a segment of code. Linearly
independent means that each path has at least one edge that is not in another
path (if viewing the code as a graph.)

McCabe defined CC using graph theory as

$$ M = E - N + 2P $$

Where $E$ = edges in the graph, $N$ = nodes in the graph and $P$ is the number
of connected components (think modules) of a program.

If a program had no control flow statements (if, switch, goto, etc) then the CC
would be 1. If a program has 1 _if_ statement, then its CC is 2.

A program that has 50 lines consisting of 25 consecutive "if/then" constructs
will have over 33 million ($2^{25}$) distinct paths through the program. There's
no way all those paths are getting tested lol.

Let's analyze the following snippet of Python code:
~~~python
def cc_example(b, c):
  a = 0
  if b > c:
    a = b
  else:
    a = c
  print(a, b, c)
~~~

What is the cyclomatic complexity of this method?
We can draw this graph of this method:

![cc](${cc})

The graph has seven nodes, seven edges, there its cyclomatic complexity is
$7 - 7 + 2 = 2$.

### Suggested Levels of CC

There doesn't seem to be an agreed upon amount of CC that is too much, though
generally the higher your code's CC the harder it is to maintain. Obviously this
is going to grow with the number of lines in your software.

According to a random
[StackExchanger](https://softwareengineering.stackexchange.com/questions/101830/what-does-the-cyclomatic-complexity-of-my-code-mean)
the following is rubric CC scores and their meaning.

- < 10 Easy to maintain
- 11-20 Harder to maintain
- 21+ Candidates for refactoring/redesign

Other [websites](https://www.aivosto.com/project/help/pm-complexity.html) have
slightly different tolerances but generally I like the StackExchange metric for
methods.

### Pros of using CC in your project

- Use it to help write code that is easier to read & reason about
- Easier to write unit tests (fewer paths that need to be explored)
- CC is easy to calculate with plugins to popular linters

### ESlint settings for this and other measures

If you're using Javascript or Typescript in your project, you've already got a
Cyclomatic Complexity checker at hand. You just need to configure it to run when
you're linting.

The following additions to your \`eslintrc\` file will activate the complexity
rules and several other related checks. The \`complexity\` rule below is what
measures specifically for Cyclomatic Complexity. Here, I have it set to raise a
linting error if the measure complexity of a method is above 5.

Many of the other rules are measuring things like clause depth and the number of
lines in a method.

~~~json
{
  "rules": {
    "complexity": ["error", 5],
    "array-callback-return": "error",
    "max-statements": ["error", 20],
    "max-statements-per-line": ["error", {
      "max": 1
    }],
    "max-nested-callbacks": ["error", 3],
    "max-depth": ["error", {
      "max": 3
    }],
    "max-lines": ["error", 200],
    "no-return-assign": "error",
    "no-param-reassign": "error",
  }
}
~~~
  `

  return (
    <div className="main-text">
      <ReactMarkdown
        renderers={renderer}
        remarkPlugins={[remarkMath]}
        rehypePlugins={[rehypeKatex]}
        linkTarget={'_blank'}
      >
        {markdown}
      </ReactMarkdown>
    </div>
  )
}
