import React from 'react'
import ReactMarkdown from 'react-markdown'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { github } from 'react-syntax-highlighter/dist/cjs/styles/hljs'
import { setTitle } from '../PageUtils'

/**
Markdown for vim settings blog
 */
export default function UsefulVimSettings() {
  setTitle('Nathan Gilbert | Vim config')

  const renderer = {
    code: ({ language, value }) => (
      <SyntaxHighlighter
        style={github}
        language={language}
        customStyle={{
          background: 'var(--background)',
          color: 'var(--text-color-normal)',
        }}
        showLineNumbers
        wrapLines
      >
        {value}
      </SyntaxHighlighter>
    ),
  }

  const vimSettings = `
## Vim Settings I use

~~~vim
set tabstop=8
set softtabstop=4               " makes space feel like tabs
set shiftwidth=4
set expandtab
set textwidth=80                " each line can only be 79 chars (+newline) long
set formatoptions=cqt           " automatically wrap long lines
set number                      " line numbers
set cursorline                  " highlight the current line
set t_Co=256                    " set terminal to 256 colors
set history=100                 " keep 100 lines of command line history
set ruler                       " show the cursor position all the time
set showcmd                     " display incomplete commands
set incsearch                   " do incremental searching
set autoread                    " automatically read a file changed externally
set hlsearch                    " highlights all search matches
set backspace=indent,eol,start  " allow backspacing in insert mode
set noerrorbells                " Attempts turn off errorbells.
set vb                          " turn on visual bell
set showmatch                   " match parenthesis
set encoding=UTF-8              " helps devicons
set backup                      " save backup files
set backupdir=~/.vim/backup//   " where to put backup files
set directory=~/.vim/swap//     " where to save swap files
set undodir=~/.vim/undo//       " where to undo files
~~~

`

  return (
    <div className="main-text">
      <ReactMarkdown renderers={renderer} linkTarget={'_blank'}>
        {vimSettings}
      </ReactMarkdown>
    </div>
  )
}
