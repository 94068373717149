import React from 'react'
import CookieConsent, { Cookies } from 'react-cookie-consent' // eslint-disable-line no-unused-vars

export default class ConsentCookies extends React.Component {
  render() {
    return (
      <CookieConsent
        location="bottom"
        buttonText="That's fine."
        cookieName="use-cookies"
        cookieValue="true"
        contentClasses="cookie-consent"
        style={{
          background: 'white',
          color: 'gray',
          width: '75%',
          left: '10%',
        }}
        buttonStyle={{
          border: 1,
          background: 'gray',
          color: 'black',
          fontSize: '20px',
        }}
        expires={150}
      >
        This website uses cookies to manage the color theme.
      </CookieConsent>
    )
  }
}
