// eslint-disable-next-line no-unused-vars
import React from 'react'
// eslint-disable-next-line no-unused-vars
import ReactMarkdown from 'react-markdown'
import { setTitle } from '../PageUtils'

/**
 * How to set up a raspberry pi
 */
export default function RaspianPiSetup() {
  const markdown = `
## Raspian Raspberry Pi Setup

- Load SDHC card into your machine and install the raspain image. Instructions
[here](https://www.raspberrypi.org/documentation/installation/installing-images/)
- Create a file called \`ssh\` in \`/Volumes/boot\` of that installation
- Disable wifi and bluetooth by adding the following two lines to \`/boot/config.txt\`
  - \`dtoverlay=disable-wifi\`
  - \`dtoverlay=disable-bt\`
- Insert the SDHC card and power on the PI.
- You can log in using the default user/password via SSH.
- Change the pi user password
  - \`passwd\`
- Change th pi user's locale and time zone
  - use \`raspi-config\`
- Update packages \`sudo apt update\`
- Install the latest fish shell, fzf
- To change the user shell, you have to edit \`/etc/passwd\` or use \`chsh -s /usr/bin/fish nathan\`

### Setting up BOINC Client

- Make sure there is a /var/lib/cc_configs.xml file
- Make sure it has <options><allow_remote_gui_rpc>1</allow_remote_gui_rpc></options>
- Make sure the remote_hosts.cfg file has the hosts you want inside it

## Networking Troubleshooting

- If you are running a headless Pi, but want to XDRP into it, you may end up
with both dhcpcd & wicd running. You may need to disable wicd with \`systemctl
disable wicd\` `

  setTitle('Nathan Gilbert | Setting up a Raspberry Pi as a server')

  return (
    <div className="main-text">
      <ReactMarkdown linkTarget={'_blank'}>{markdown}</ReactMarkdown>
    </div>
  )
}
