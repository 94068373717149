import Cookies from 'universal-cookie'
import checkCookieConsent from './CheckCookieConsent'

// eslint-disable-next-line no-unused-vars
const ThemeCookie = () => {
  const cookies = new Cookies()
  // eslint-disable-next-line multiline-ternary
  if (checkCookieConsent(cookies)) {
    // eslint-disable-next-line multiline-ternary
    const theme = cookies.get('data-theme')
      ? cookies.get('data-theme')
      : 'light'
    document.documentElement.setAttribute('data-theme', theme)
  }
  return null
}

export default ThemeCookie
