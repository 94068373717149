/* eslint-disable require-jsdoc */
import React from 'react'
import { Link, Route, Routes, useParams } from 'react-router-dom'
import LogicalFallacies from './thoughts/LogicalFallacies'
import MachineLearningLabs from './thoughts/MachineLearningLabs'
import RaspianPiSetup from './thoughts/RaspianPiSetup'
import GitTricks from './thoughts/GitTricks'
import QuoteBlog from './thoughts/QuoteBlog'
import UsefulVimSettings from './thoughts/UsefulVimSettings'
import ComplexityLinting from './thoughts/ComplexityLinting'
import DevLifeHacks from './thoughts/DevLifeHacks'
import ForestOfNames from './thoughts/ForetdeNoms'
import JeepXJAxleInfo from './thoughts/JeepXJAxleInfo'
import OpenBSD from './thoughts/OpenBSD'
import { setTitle } from './PageUtils'

/**
Debug function
*/
function Thought() {
  const { topicId } = useParams()
  return <h3>Requested thought ID: {topicId}</h3>
}

export default function Thoughts() {
  setTitle('Nathan Gilbert | Ideas and Opinions')

  return (
    <div className="container">
      <div className="main-text">
        <h1> Thoughts and other things I&apos;m trying to remember...</h1>
        <ul className="thoughts-menu">
          <li>
            <Link to={'thoughts'}>Index</Link>
          </li>
          <li>
            <Link to={'noms'}>Naming things is hard</Link>
          </li>
          <li>
            <Link to={'mll'}>Machine Learning Labs</Link>
          </li>
          <li>
            <Link to={'devlife'}>Dev Life Hacks</Link>
          </li>
          <li>
            <Link to={'complexlint'}>Complexity Linting</Link>
          </li>
          <li>
            <Link to={'raspian'}>Raspian Setup</Link>
          </li>
          <li>
            <Link to={'logfail'}>Logical Fallacies</Link>
          </li>
          <li>
            <Link to={'gittricks'}>Git Tricks</Link>
          </li>
          <li>
            <Link to={'vimrc'}>Vim Settings</Link>
          </li>
          <li>
            <Link to={'quoteblog'}>Quote Blog</Link>
          </li>
          <li>
            <Link to={'xj-axles'}>Jeep XJ Axle Information</Link>
          </li>
          <li>
            <Link to={'openbsd'}>OpenBSD Setup</Link>
          </li>
        </ul>
      </div>

      <Routes>
        <Route path={'thoughts'} />
        <Route path={'noms'} element={<ForestOfNames />} />
        <Route path={'complexlint'} element={<ComplexityLinting />} />
        <Route path={'mll'} element={<MachineLearningLabs />} />
        <Route path={'raspian'} element={<RaspianPiSetup />} />
        <Route path={'logfail'} element={<LogicalFallacies />} />
        <Route path={'gittricks'} element={<GitTricks />} />
        <Route path={'devlife'} element={<DevLifeHacks />} />
        <Route path={'vimrc'} element={<UsefulVimSettings />} />
        <Route path={'quoteblog'} element={<QuoteBlog />} />
        <Route path={'xj-axles'} element={<JeepXJAxleInfo />} />
        <Route path={'openbsd'} element={<OpenBSD />} />

        {/* this is a catch all and must come last */}
        <Route path={':topicId'} element={<Thought />} />
      </Routes>
    </div>
  )
}
