import React from 'react'
import ReactMarkdown from 'react-markdown'
import { setTitle } from '../PageUtils'

/**
 * Naming things is hard
 */
export default function ForestOfNames() {
  const markdown = `
## Naming things is hard

It's hard to be descriptive about the code you're writting. Maybe it's because you're still figuring out _what_ the purpose of the code you're writting is or you are not a domain-expert on the problem at hand.

Most of these examples are taken from _Clean Code_ by Robert Martin.

### Be Practical

- *Use names that convey intention*
  - \`int elapsedTimeInDays;\`
- *Use words that you can say*
  - Programming is communicating
- *Use searchable terms*
  - Hard to find a variable named \`a\` in a codebase.
- *Names can be vectors for feature-creep*
  - Too general of a class name, and suddenly you have a catch-all, deeply coupled class instead of targeted functionality

### Be Clear

- *Avoid misnomers (duh)*
  - Don't name some \`diamondList\` when the collection is a map.
- *Make things different enough to understand*
  - Don't name things just to satifsy a compiler
  - \`zork\` vs \`theZork\` isn't that meaningful
  - Codebase dependent
- *Don't create a code to write your code*
  - Use langauge from the domain of the program
  - In typed languages, it's not great to include the data type in the name. e.g. \`phoneString\`, what if the datatype changes?
  - It's still good practice to use naming conventions for interfaces or implementations.
  - Bob doesn't like member declarations, I don't mind them. Using underscores for private variables or unused variables too.
- *One word per concept*
  - Generally classes are nouns, functions are verbs
- *Add Context, at the right level, but not too much*
  - Very dependent on the codebase.
  - Examples from the book: \`firstName\`, \`lastName\`, but for what purpose?
  - Too much context: prefixing every class in the codebase

### Jokes aside

This is the hardest for me at times.

- *In (or Out) Jokes*
  - Not professional, confusing
  - Say what you mean, mean what you say
- *No Puns (same word for different purposes)*
  `

  setTitle('Nathan Gilbert | Naming things is hard')

  return (
    <div className="main-text">
      <ReactMarkdown linkTarget={'_blank'}>{markdown}</ReactMarkdown>
    </div>
  )
}
