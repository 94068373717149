import React from 'react'

const textElement = (
  <div className="container">
    <div className="main-text">
      <h1> Code </h1>
      <p>
        Check out my current projects on{' '}
        <a href="https://www.github.com/nathan-gilbert">Github</a>.
      </p>
    </div>
  </div>
)

export default class Code extends React.Component {
  componentDidMount() {
    document.title = 'Nathan Gilbert | Code that I have contributed to'
  }

  render() {
    return textElement
  }
}
