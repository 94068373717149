import React from 'react'

const under_const = require('../../../images/construct.gif')

const textElement = (
  <div className="container">
    <div className="main-text">
      <h1> Ectera </h1>
      <center>
        <p>
          <img src={under_const} alt="Under construction" />
        </p>
      </center>
    </div>
  </div>
)

export default class Etc extends React.Component {
  componentDidMount() {
    // eslint-disable-next-line operator-linebreak
    document.title =
      "Nathan Gilbert | Everything else that doesn't fit on another page"
  }

  render() {
    return textElement
  }
}
