import React from 'react'

const headerNameElement = (
  <div>
    <div className="header-name">Nathan Gilbert</div>
    <div className="header-title">Software, Music and the Outdoors</div>
  </div>
)

export default class HeaderName extends React.Component {
  render() {
    return headerNameElement
  }
}
