import React from 'react' // eslint-disable-line no-unused-vars
import {
  FloatingMenu, // eslint-disable-line no-unused-vars
  MainButton, // eslint-disable-line no-unused-vars
  ChildButton, // eslint-disable-line no-unused-vars
} from 'react-floating-button-menu'
import Cookies from 'universal-cookie'
import GetExpirationDate from '../utils/GetExpirationDate'

export default class ConfigButton extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      theme: 'light',
      oldTheme: 'light',
    }
    this.toggleDarkMode = this.toggleDarkMode.bind(this)
    this.toggleHighContrast = this.toggleHighContrast.bind(this)
    this.toggleOpen = this.toggleOpen.bind(this)
  }

  setCookie(themeName) {
    // # of days the cookie is valid
    const cookieExpiration = 365
    const cookies = new Cookies()
    cookies.set('data-theme', themeName, {
      path: '/',
      expires: GetExpirationDate(cookieExpiration),
    })
  }

  toggleOpen() {
    this.setState({ isOpen: !this.state.isOpen })
  }

  toggleDarkMode() {
    this.setState({ oldTheme: this.state.theme })
    if (this.state.theme === 'light' || this.state.theme === 'high-contrast') {
      document.documentElement.setAttribute('data-theme', 'dark')
      this.setState({ theme: 'dark' })
      this.setCookie('dark')
    } else {
      document.documentElement.setAttribute('data-theme', 'light')
      this.setState({ theme: 'light' })
      this.setCookie('light')
    }
  }

  toggleHighContrast() {
    this.setState({ oldTheme: this.state.theme })
    if (this.state.theme === 'light' || this.state.theme === 'dark') {
      document.documentElement.setAttribute('data-theme', 'high-contrast')
      this.setState({ theme: 'high-contrast' })
      this.setCookie('high-contrast')
    } else {
      document.documentElement.setAttribute('data-theme', 'light')
      this.setState({ theme: 'light' })
      this.setCookie('light')
    }
  }

  render() {
    return (
      <div className="config-button">
        <FloatingMenu
          slideSpeed={500}
          direction="up"
          spacing={8}
          isOpen={this.state.isOpen}
        >
          <MainButton
            iconResting={
              <i
                className="fas fa-cog"
                style={{ fontSize: '38px', color: 'var(--button-color)' }}
              />
            }
            iconActive={
              <i
                className="fas fa-cog"
                style={{ fontSize: '38px', color: 'var(--button-color)' }}
              />
            }
            backgroundcolor="var(--background)"
            onClick={this.toggleOpen}
            size={56}
          />
          <ChildButton
            icon={
              <i
                className="fas fa-moon"
                style={{ fontSize: '30px', color: 'var(--button-color)' }}
              />
            }
            backgroundcolor="var(--background)"
            onClick={this.toggleDarkMode}
            size={42}
          />
          <ChildButton
            icon={
              <i
                className="fas fa-adjust"
                style={{ fontSize: '30px', color: 'var(--button-color)' }}
              />
            }
            backgroundcolor="var(--background)"
            onClick={this.toggleHighContrast}
            size={42}
          />
        </FloatingMenu>
      </div>
    )
  }
}
