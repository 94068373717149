import React from 'react' // eslint-disable-line no-unused-vars

const not_found = require('../../../images/mst3k_not_found.jpg')

const NotFound = () => (
  <div className="container">
    <div className="not-found">
      <center>
        <h1> This page doesn&apos;t exist! </h1>
        <br />
        <img src={not_found} alt="Not found" />
      </center>
      <br />
    </div>
  </div>
)

export default NotFound
