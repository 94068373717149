import React from 'react'

const textElement = (
  <div className="container">
    <div className="main-text">
      <h1> Music </h1>
      <p>
        I have been in numerous bands of little note over the years.
        <br />
        One that was somewhat successful was named{' '}
        <a href="http://www.americanhollowband.com">American Hollow</a>.
        <br />I started this group with some friends in Kentucky and continued
        it for many years in Utah.
        <br />
        American Hollow had an independent record contract, toured the western
        US and released some records.
        <br />
        It was a fun time.
        <br />
        I&apos;m currently working on a new ensemble and releasing music
        independently as{' '}
        <a href="https://soundcloud.com/medium-cool">Medium Cool-Aid</a>.
        <br />I like to mix and edit podcasts as well under the same name, more
        info <a href="https://www.mediumcoolaid.com">here</a>.
      </p>
      <h3>Current and past projects:</h3>
      <ul>
        <li>
          <a
            href="https://www.mediumcoolaid.com"
            target="_blank"
            rel="noreferrer"
          >
            Medium Cool Productions
          </a>
          - My Podcast and Audio production project.
        </li>
        <li>
          <a
            href="https://www.mediumcool.band"
            target="_blank"
            rel="noreferrer"
          >
            Medium Cool
          </a>
          - My musical project named Medium Cool.
        </li>
        <li>
          <a
            href="https://www.americanhollowband.com"
            target="_blank"
            rel="noreferrer"
          >
            American Hollow
          </a>
          - A previous band.
        </li>
      </ul>
      <h3>Equipment</h3>
      On American Hollow&apos;s <i>Whisper Campaign</i>:
      <ul>
        <li>Basses (Left Handed): </li> 2007 Fender American Jazz, 1999
        Music-Man StingRay, 2006 Ibanez Soundgear 506L
        <li>
          Amplification: Gallien-Krueger RB1001, Gallien-Krueger Neo 4x10,
          Gallien-Krueger Neo 1x15
        </li>
        <li>
          Effects: Gallien-Krueger DieselDawg, DigiTech Bass Synth Wah, Boss
          CEB-1, Boss DD-5, Boss BF-3, Boss RC-2, Boss SYB-5, Boss AW-3
        </li>
        <li>Synthesizers: Korg MicroKorg, Korg X-50</li>
      </ul>
      On American Hollow&apos;s <i>Screaming into the Void</i>:
      <ul>
        <li>Basses (Left Handed): </li> 2007 Fender American Jazz, 2006 Ibanez
        Soundgear 506L, 2006 Music Man Bongo
        <li>
          Amplification: Acoustic B500H, Gallien-Krueger Neo 4x10,
          Gallien-Krueger Neo 1x15
        </li>
        <li>
          Effects: DigiTech Bass Synth Wah, Boss CEB-3, Electro-Harmonix Micro
          Bass, Electro-Harmonix Bass Big Muff, Synth, Guyatone VTX Vintage
          Tremolo, Boss PS-3, Boss RC-2
        </li>
        <li>Synthesizers: Korg MicroKorg, Korg X-50, Korg Wavestation SR</li>
      </ul>
      <h3>Collecting</h3>I collect vinyl records. I&apos;m always on the hunt
      for Progressive Rock, Psychedelic Rock, Jazz Rock, Folk Rock and the Metal
      variants of all these genres.
    </div>
  </div>
)

export default class Music extends React.Component {
  componentDidMount() {
    document.title = 'Nathan Gilbert | Music that I have contributed to.'
  }

  render() {
    return textElement
  }
}
