// eslint-disable-next-line no-unused-vars
import React from 'react'
// eslint-disable-next-line no-unused-vars
import ReactMarkdown from 'react-markdown'
import { setTitle } from '../PageUtils'

/**
Logical fallacies blog post
 */
export default function MachineLearningLabs() {
  const markdown = `
  ## Machine Learning Labs

  I've been working on some labs for the AI/ML Guild at Workfront. Here they are:

  - <https://github.com/nathan-gilbert/ai-ml-guild-lab1> - General beginner steps getting started with an ML project.
  - <https://github.com/nathan-gilbert/ai-ml-guild-lab2> - Perceptrons and linear binary classification. Multi-Layer Perceptrons and Neural Nets
  - <https://github.com/nathan-gilbert/ai-ml-guild-lab3> - Decision Trees are fun
  `

  setTitle('Nathan Gilbert | Machine Learning Labs')

  return (
    <div className="main-text">
      <ReactMarkdown linkTarget={'_blank'}>{markdown}</ReactMarkdown>
    </div>
  )
}
