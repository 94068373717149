import React from 'react' // eslint-disable-line no-unused-vars
import { Link } from 'react-router-dom' // eslint-disable-line no-unused-vars
import ContentBlock from './ContentBlock' // eslint-disable-line no-unused-vars

const me = require('../../../images/me-backpacking.jpg')
const bass = require('../../../images/bass-sharp-noir.jpg')
const desktop = require('../../../images/desktop-up.jpg')
const uintas = require('../../../images/yellowstone-uintas.jpg')
const thoughts = require('../../../images/thoughts.jpg')
const nethack = require('../../../images/nethack.jpg')

export default class ContentRow extends React.Component {
  render() {
    return (
      <div className="container">
        <div className="content-row">
          <Link to="code" className="links">
            <ContentBlock
              image={desktop}
              title={'Code'}
              summary={
                'Dev projects past and present and writings about coding'
              }
            />
          </Link>
          <Link to="outside" className="links">
            <ContentBlock
              image={uintas}
              title={'Outside'}
              summary={'Places I have hiked and beers I have drank'}
            />
          </Link>
          <Link to="me" className="links">
            <ContentBlock
              image={me}
              title={'Me'}
              summary={'My contact info some and back story'}
            />
          </Link>
        </div>
        <div className="content-row">
          <Link to="thoughts" className="links">
            <ContentBlock
              image={thoughts}
              title={'Thoughts'}
              summary={'My blog and thoughts on a variety of topics'}
            />
          </Link>
          <Link to="music" className="links">
            <ContentBlock
              image={bass}
              title={'Music'}
              summary={"Music I've made and liked"}
            />
          </Link>
          <Link to="etc" className="links">
            <ContentBlock
              image={nethack}
              title={'Etc'}
              summary={'Random Stuff'}
            />
          </Link>
        </div>
        <br />
        <br />
      </div>
    )
  }
}
