// eslint-disable-next-line no-unused-vars
import React from 'react'
// eslint-disable-next-line no-unused-vars
import ReactMarkdown from 'react-markdown'
import { setTitle } from '../PageUtils'

/**
Logical fallacies blog post
 */
export default function LogicalFallacies() {
  const markdown = `
  ## Logical Fallacies from Usenet

  Just some examples I wanted to keep around for posterity.

  ### Fallacies that result from errors in induction

  - _Overstatement_: An unqualified generalization. "My English teacher never
  gives A's.", "Almonds are good for the heart; everyone should eat 2 handfuls
  of almonds every day."
  - _Hasty Generalization_: Stating a conclusion based on too little evidence or
  based on ignoring some evidence. "Running must be bad for your heart. Look
  what happened to Florence Joiner." "My little Yamaha motorcycle never gave me
  problems. Yamaha motorcycles are the best."
  - _Stereotyping_: Giving the same characteristics to everyone in a group. "Used-car
  salesmen can't be trusted.", "Utah State graduates are all nerdy.", "Southern
  Utahns are all right-wing conservative fanatics who refuse to pay taxes and
  want to destroy the environment."
  - _Forced hypothesis_: Reaching a conclusion that is not supported by the
  evidence or a conclusion that is more complicated than necessary. "Jerry and
  Kim live next door to each other, so they must be really good friends."
  - _Non sequitur_: This refers specifically to conclusions which are not
  logically derived from the reasoning that precedes them. "Al Gore is a
  wonderful father, so he would have made a great president."
  - _Slippery slope_: An argument in which we assert that X should not happen
  because it will inevitably be followed by consequences Yand Z, which are
  terrible. "Utah should not legalize parimutuel betting on horse races because
  that will only lead to gambling on other sporting events and finally to casino
   gambling.", "We should never allow families to have doctors disconnect the
   respirators and feeding tubes of brain dead patients. If we do, pretty soon
   we will be asking doctors to kill people with Alzheimer's disease."
  - _False dilemma_: An argument asserting that only two (or a limited number
  of) options exist when there are actually more. "You either support the war
  in Iraq or you are a traitor to your country and an enemy to freedom."
  - _False analogy_: An argument based on a comparison of two things when the
  differences between the two are too great. "There are 10,000 deaths from
  alcohol poisoning to 1 from mad-dog bites in this country. In spite of this,
  we license liquor but shoot the dogs."
  - _Post hoc_: From the Latin "after this, therefore because of it," this means
   simply assigning a cause-effect relationship where none exists or where it is
    difficult to prove there is a cause-effect relation. "A black cat ran across
     my path on the way to work this morning, and sure enough, I got a flat tire
      on the way home."

  ### Fallacies that result from ignoring the issue:

  - _Begging the question_: To assume that part or all of your argument will be
   accepted as true without support. "The federal government should not
   subsidize development of alternative-fuel automobiles because gasoline is
   the cheapest and cleanest fuel.", "We should give every American the right to
   have assault weapons in his or her home because we know that a nation in
   which its citizens have assault weapons will be safer that one without.", "We
   know that our police are never involved in bribery, dishonesty, or abuse of
   the law because the police report issued in January of 2004 said so."
  - _Red herring_: To introduce an irrelevant side-issue and divert attention
  from the topic at hand. "Mary Gordon shouldn't be elected to the school board
  because she doesn't have any children in public schools."
  - _Straw man_: To accuse your opponents of holding erroneous or ridiculous
  view or attitudes and attacking those instead of attacking their arguments.
  "Environmentalists are trying to save the spotted owl because they want to run
  American loggers out of business."
  - _Ad hominem_: From the Latin meaning "to or towards the person". This is the
   strategy of attacking the proponent of an argument rather than the argument
   itself. (A special form of Ad Hominem arguments occur when someone attacks an
    argument by attacking the speaker before he or she speaks. It is called
    Poisoning the Well.)
  - _Argument ad Miseriocordiam_: "Argument to Pity": An emotional appeal to a
  logical issue. While pathos generally works to reinforce a reader's moral
  sense, if a writer relies on an appeal to emotion only to accept a conclusion,
   it is a fallacy. "I know my paper is late, but I have had the worst pimple on
   my nose for a week, so I was too miserable to bring it to class. Please don't
    mark it late." Or, "Janice, you have to marry me! If you don't, I will be
    the most miserable person alive. Who knows, I may even turn to drugs and
    alcohol."
  - _Common practice_: Sometimes called bandwagon fallacy. This is to argue that
   an action should be taken or an idea accepted because everyone is doing it.
   "Don't worry about using your older brother's research paper. It's only a
   general ed requirement, and half the kids in the class are doing the same
   thing.", "Dad, I can't live without a cell phone and a laptop. All the kids
   have them now."
  - _Argument ad populum_: from the Latin "to the people"; appealing to the
  beliefs of the multitudes. "Republican candidates are the choice of
  red-blooded Americans who believe in the traditional American values of
  liberty and prosperity and America first." (A form of this called snob appeal
   is often used in advertising: "Buick is the choice of intelligent discerning
   Americans.", Caring mothers choose Pampers.)
  - _Argumentum ad Baculum_: Appeal to Force, bullying, or the
  "Might-Makes-Right" Fallacy. This argument uses force, the threat of force, or
   some other unpleasant backlash to make the audience accept a conclusion.
   It commonly appears as a last resort when evidence or rational arguments fail
    to convince (Most of us have mothers and fathers who used it with us.)
  `

  setTitle('Nathan Gilbert | For cases where logic must do.')

  return (
    <div className="main-text">
      <ReactMarkdown>{markdown}</ReactMarkdown>
    </div>
  )
}
