import React from 'react' // eslint-disable-line no-unused-vars
import MenuSocialMedia from './MenuSocialMedia' // eslint-disable-line no-unused-vars
import randomQuote from './RandomQuote'

export default class MenuQuote extends React.Component {
  render() {
    const quote = randomQuote()
    return (
      <div className="menu-quote">
        <hr />
        <MenuSocialMedia />
        <hr />
        {quote.text}
        <br />
        <div className="menu-quote-author">{quote.author}</div>
      </div>
    )
  }
}
