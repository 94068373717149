import React from 'react' // eslint-disable-line no-unused-vars
import ReactDOM from 'react-dom' // eslint-disable-line no-unused-vars
import { BrowserRouter, Route, Routes } from 'react-router-dom' // eslint-disable-line no-unused-vars
import Code from './components/pages/Code'
import Me from './components/pages/Me'
import Thoughts from './components/pages/Thoughts'
import Etc from './components/pages/Etc'
import Music from './components/pages/Music'
import Outside from './components/pages/Outside'
import HomePage from './components/pages/HomePage'
import NotFound from './components/pages/NotFound'
import Footer from './components/ui/Footer' // eslint-disable-line no-unused-vars
import HeaderRow from './components/ui/HeaderRow' // eslint-disable-line no-unused-vars
import ThemeCookie from './components/utils/ThemeCookie' // eslint-disable-line no-unused-vars
import ConsentCookies from './components/utils/ConsentCookies' // eslint-disable-line no-unused-vars

require('../css/main.css')

// eslint-disable-next-line no-unused-vars
const MainRouting = () => (
  <Routes>
    <Route path="/" element={<HomePage />} />
    <Route path="code" element={<Code />} />
    <Route path="outside" element={<Outside />} />
    <Route path="me" element={<Me />} />
    <Route path="thoughts/*" element={<Thoughts />} />
    <Route path="music" element={<Music />} />
    <Route path="etc" element={<Etc />} />
    <Route element={<NotFound />} />
  </Routes>
)

// eslint-disable-next-line no-unused-vars
const App = () => (
  <React.Fragment>
    <ConsentCookies />
    <ThemeCookie />
    <HeaderRow />
    <MainRouting />
    <Footer />
  </React.Fragment>
)

export default ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('the-page')
)
