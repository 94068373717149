import React from 'react' // eslint-disable-line no-unused-vars

const Footer = () => (
  <div className="footer">
    <center>
      <h6>© {new Date().getFullYear()} Nathan Gilbert. All Rights Reserved.</h6>
    </center>
  </div>
)

export default Footer
