// eslint-disable-next-line no-unused-vars
import React from 'react'
// eslint-disable-next-line no-unused-vars
import ReactMarkdown from 'react-markdown'
// eslint-disable-next-line no-unused-vars
import QBQuote from '../../ui/thoughts/QBQuote'

import {
  quote as quote1,
  author as author1,
  title as title1,
  link as link1,
} from './quotes/cjohnson-war'
import {
  quote as quote2,
  author as author2,
  title as title2,
  link as link2,
} from './quotes/mcmansion'
import {
  quote as quote3,
  author as author3,
  title as title3,
  link as link3,
} from './quotes/ai-hotel-fire'
import {
  quote as quote4,
  author as author4,
  title as title4,
  link as link4,
} from './quotes/wiio-laws'
import { setTitle } from '../PageUtils'

/**
Quote Blog
 */
export default function QuoteBlog() {
  setTitle('Nathan Gilbert | Quotes and thoughts I want to keep around')

  return (
    <>
      <QBQuote
        quoteDate={'1/29/2022'}
        quoteTitle={title4}
        quoteText={quote4}
        quoteAuthor={author4}
        quoteLink={link4}
        useVR
      />
      <QBQuote
        quoteDate={'3/01/2021'}
        quoteTitle={title3}
        quoteText={quote3}
        quoteAuthor={author3}
        quoteLink={link3}
        useVR
      />
      <QBQuote
        quoteDate={'2/28/2021'}
        quoteTitle={title2}
        quoteText={quote2}
        quoteAuthor={author2}
        quoteLink={link2}
        useVR
      />
      <QBQuote
        quoteDate={'2/21/2021'}
        quoteTitle={title1}
        quoteText={quote1}
        quoteAuthor={author1}
        quoteLink={link1}
        useVR
      />
    </>
  )
}
