// eslint-disable-next-line no-unused-vars
import React from 'react'
import PropTypes from 'prop-types'

/**
 */
export default function QBQuote({
  quoteDate,
  quoteTitle,
  quoteText,
  quoteAuthor,
  quoteLink,
  useVR,
}) {
  return (
    <div className="quote-container">
      <div className="quote-date">{quoteDate}</div>
      <div className="quote-text">
        <div className="quote-title">{quoteTitle}</div>
        {useVR && (
          <div className="vr">
            <pre>{quoteText}</pre>
          </div>
        )}
        {!useVR && quoteText}
        <div className="quote-author">
          <a href={quoteLink} target="_blank" rel="noreferrer">
            —{quoteAuthor}
          </a>
        </div>
      </div>
    </div>
  )
}

QBQuote.propTypes = {
  quoteDate: PropTypes.string.isRequired,
  quoteTitle: PropTypes.string.isRequired,
  quoteText: PropTypes.string.isRequired,
  quoteAuthor: PropTypes.string.isRequired,
  quoteLink: PropTypes.string.isRequired,
  useVR: PropTypes.bool.isRequired,
}
