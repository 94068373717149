import React from 'react' // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom' // eslint-disable-line no-unused-vars
import MenuQuote from './MenuQuote' // eslint-disable-line no-unused-vars

export default class MenuRouter extends React.Component {
  render() {
    return (
      <div className="menu-router">
        <ul>
          <li>
            <NavLink to="/" className="links" onClick={this.props.menuCallback}>
              Home
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/code"
              className="links"
              onClick={this.props.menuCallback}
            >
              Code
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/outside"
              className="links"
              onClick={this.props.menuCallback}
            >
              Outside
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/me"
              className="links"
              onClick={this.props.menuCallback}
            >
              Me
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/thoughts"
              className="links"
              onClick={this.props.menuCallback}
            >
              Thoughts
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/music"
              className="links"
              onClick={this.props.menuCallback}
            >
              Music
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/etc"
              className="links"
              onClick={this.props.menuCallback}
            >
              Etc
            </NavLink>
          </li>
        </ul>
        <MenuQuote />
      </div>
    )
  }
}

MenuRouter.propTypes = {
  menuCallback: PropTypes.func.isRequired,
}
