import React from 'react'
import ReactMarkdown from 'react-markdown'
import { setTitle } from '../PageUtils'

/**
OpenBSD Setup Tricks
*/
export default function OpenBSD() {
  setTitle('Nathan Gilbert | OpenBSD Setup')

  const markdown = `
## OpenBSD

I recently bought an old Acer laptop and revived it using
[OpenBSD](https://www.openbsd.org/). It's a fun operating system to use on any
hardware. OpenBSD is touted at the most secure OS in the world.

Some good setup links:
  - <https://sohcahtoa.org.uk/openbsd.html>
  - <https://zakaria.org/posts/2021-01-04-xenodm.html>
  - <https://dataswamp.org/~solene/2021-08-05-openbsd-wifi-list.html>
  - <https://dataswamp.org/~solene/index-full.html>

### XDM Setup

The login screen is managed by XDM. This is the config I used to get a nice
blue background with the puffer fish logo.


\`/etc/X11/Xenodm/Xsetup_0\`

\`\`\`
#!/bin/sh
xrandr --output default --dpi 96

# requires pkg_add terminus-font
xset fp+ /usr/local/share/fonts/terminus

prefix="/usr/X11R6"
exec_prefix="\${prefix}"

BG_COLOR="#203051"
\${exec_prefix}/bin/xsetroot -solid $BG_COLOR

# Disable xconsole
# \${exec_prefix}/bin/xconsole -geometry 480x130-0-0 -daemon -notify -verbose -fn fixed -exitOnFail

# show the Sleep / Restart / Shutdown bar
# in case of sleep, pop xmessage again after waking up
(
while true; do
  xmessage -geometry +825-32 \
    -buttons "[ Sleep ]":20,"[ Restart ]":21,"[ Shutdown ]":22 ""
  ACTION=$?
  echo "Xmessage said: $ACTION"
  if   [ $ACTION -eq 20 ]; then /usr/sbin/zzz;
  elif [ $ACTION -eq 21 ]; then
    xsetroot -cursor_name watch
    /sbin/shutdown -r now
  elif [ $ACTION -eq 22 ]; then
    xsetroot -cursor_name watch
    /sbin/shutdown -p now
  else echo "Something bad happened to Xmessage.";
  fi
  # stop looping if xclock died (hopefully killed by GiveConsole)
  if [ -z "$(pgrep -U root xclock)" ]; then break; fi
done
) &

OS_NAME=$(uname -n)
OS_INFO=$(uname -smr)
xclock -digital -geometry -0+0 -strftime "$OS_NAME ($OS_INFO) | %a. %d %b. %Y  %H:%M " &
\`\`\`

#### Stop the xconsole from loading

\`/etc/X11/Xenodm/GiveConsole\`
\`\`\`
#!/bin/sh

prefix="/usr/X11R6"
exec_prefix="\${prefix}"

# stop the clock and xmessage after login
pkill xclock
pkill xmessage

chown $USER /dev/console
if [ -c /dev/dri/card0 ]; then
    chown $USER /dev/dri/card0
fi
if [ -c /dev/dri/renderD128 ]; then
    chown $USER /dev/dri/renderD128
fi
\${exec_prefix}/bin/sessreg -a -l $DISPLAY -u none $USER
\`\`\`
  `

  return (
    <div className="main-text">
      <ReactMarkdown linkTarget={'_blank'}>{markdown}</ReactMarkdown>
    </div>
  )
}
